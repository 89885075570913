// @flow
import { put, takeLatest, all, fork } from 'redux-saga/effects'
import { decamelizeKeys } from 'humps'

import * as actions from './actions'
import { requestActions } from 'state/request'
import { API_URL, GENERAL_PREFERENCE_ENDPOINT } from 'qap/constants'

import type { FetchUserPreferences, SaveUserPreferences } from './type'

export function * fetchGeneralPreferences (action: FetchUserPreferences) : Generator<any, any, any> {
  const url = `${API_URL}/${GENERAL_PREFERENCE_ENDPOINT}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: actions.FETCH_GENERAL_PREFERENCES_SUCCESS,
    failureAction: actions.FETCH_GENERAL_PREFERENCES_FAILURE
  })
}

export function * saveGeneralPreferences (action: SaveUserPreferences): Generator<any, any, any> {
  const { preferences } = action
  const url = `${API_URL}/${GENERAL_PREFERENCE_ENDPOINT}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'PUT',
    url,
    body: { data: decamelizeKeys({ preferences }) },
    successAction: actions.SAVE_GENERAL_PREFERENCES_SUCCESS,
    failureAction: actions.SAVE_GENERAL_PREFERENCES_FAILURE
  })
}

function * watchGeneralPreferencesFetch () : Generator<any, any, any> {
  yield takeLatest(actions.FETCH_GENERAL_PREFERENCES, fetchGeneralPreferences)
}

function * watchGeneralPreferencesSave () : Generator<any, any, any> {
  yield takeLatest(actions.SAVE_GENERAL_PREFERENCES, saveGeneralPreferences)
}

export default function * generalPreferenceSagas () : Generator<any, any, any> {
  yield all([
    fork(watchGeneralPreferencesFetch),
    fork(watchGeneralPreferencesSave)
  ])
}
