// @flow
import * as actions from './actions'
import { DEFAULT_USER_TIMEZONE } from 'qap/constants'

import type { State, Action } from './type'

export const initialState = {
  preferences: undefined,
  availableTimezones: undefined,
  fetched: false,
  saving: false,
  saved: false,
  selectedTimezone: undefined,
  currentTime: undefined,
  errors: undefined
}

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case actions.SET_TIMEZONE_PREFERENCE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          timezone: action.value
        },
        selectedTimezone: action.value,
        saved: false
      }
    case actions.FETCH_GENERAL_PREFERENCES_SUCCESS:
      return {
        ...state,
        ...action.data,
        fetched: true,
        saved: false,
        selectedTimezone: action.data.preferences.timezone || DEFAULT_USER_TIMEZONE
      }
    case actions.SAVE_GENERAL_PREFERENCES:
      return {
        ...state,
        saving: true,
        saved: false
      }
    case actions.SAVE_GENERAL_PREFERENCES_SUCCESS:
      return {
        ...state,
        ...action.data,
        saving: false,
        saved: true
      }
    case actions.SAVE_GENERAL_PREFERENCES_FAILURE:
      return {
        ...state,
        errors: action.errors,
        saving: false,
        saved: false
      }
    case actions.SET_TIME_DISPLAY_EXAMPLE:
      return {
        ...state,
        currentTime: Date.now(),
        saving: false,
        saved: false
      }
    default:
      return state
  }
}

export default reducer
