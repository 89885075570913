// @flow
import type { CustomFollowUpLabel } from 'types'

export const FETCH_FOLLOW_UP_LABELS = 'API_CUSTOM_FOLLOW_UP_LABELS_INDEX'
export const CREATE_FOLLOW_UP_LABEL = 'API_CUSTOM_FOLLOW_UP_LABELS_CREATE'
export const UPDATE_FOLLOW_UP_LABEL = 'API_CUSTOM_FOLLOW_UP_LABELS_UPDATE'
export const FETCH_TOP_ORGANISATIONS = 'FETCH_TOP_ORGANISATIONS'

export const fetchCustomFollowUpLabels = () => ({ type: FETCH_FOLLOW_UP_LABELS })

export const createCustomFollowUpLabel = (data: CustomFollowUpLabel) => ({
  type: CREATE_FOLLOW_UP_LABEL,
  data
})

export const updateCustomFollowUpLabel = (data: CustomFollowUpLabel) => ({
  type: UPDATE_FOLLOW_UP_LABEL,
  data
})

export const fetchTopOrganisations = (query: string) => ({
  type: FETCH_TOP_ORGANISATIONS,
  query
})
