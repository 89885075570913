// @flow
import type { ResultEntryError } from 'types'

export const LATE_SUBMISSION_ERROR_CODE = 'late_submission'
export const INVALID_NUMERIC_FORMAT_ERROR_CODE = 'invalid_numeric_format'
export const ERROR_CODES = [LATE_SUBMISSION_ERROR_CODE, INVALID_NUMERIC_FORMAT_ERROR_CODE]

export const findError = (errors: Array<ResultEntryError>): ?ResultEntryError => {
  return errors.find(({ code }) => ERROR_CODES.includes(code))
}
