// @flow
import type { Report } from 'types'
import type { ReportUserStatusUpdateProgress } from './types'

export const updateReport = (updatedReport: Report, reports: Report[]) => {
  if (!updatedReport || !reports) return reports
  const indexOfReport = reports.findIndex(report => report.id === updatedReport.id)

  if (indexOfReport !== -1) {
    reports.splice(indexOfReport, 1, updatedReport)
  }

  return [...reports]
}

export const reportUserStatusUpdateProgress = (reportId: string, status: boolean, reportUserStatusUpdateProgress: ReportUserStatusUpdateProgress = {}) => {
  if (!reportId) return reportUserStatusUpdateProgress

  return {
    ...reportUserStatusUpdateProgress,
    [reportId]: status
  }
}
