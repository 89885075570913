// @flow
import * as actions from './actions'
import { passwordResetActions } from 'state/passwordReset'
import { signupActions } from 'state/signup'
import { success, failure } from 'utilities/actions'

import type { User, Feature, Organisation, PortalSettings } from 'types'
import { INVALID_OTP_CODE } from 'qap/constants'

export type State = {
  user: ?User,
  token: string,
  expiry: string,
  permissions: string[],
  errors: Object[],
  features: Feature[],
  loginFailures: number,
  verificationFailures: number,
  canSwitchOrganisations: boolean,
  organisations: Organisation[],
  canViewAnalytics: boolean,
  submitting: boolean,
  portalSettings: ?PortalSettings
}

type Session = {
  user: User,
  token: string,
  expiry: string,
  permissions: string[],
  features: Feature[],
  organisations: Organisation[],
  canSwitchOrganisations: boolean,
  secureId?: string,
  canViewAnalytics: boolean
}

type JwtAuth = {
  secureId? :?string,
  redirect? :?string,
  notification? :?string
}

type Action = {
  type: string,
  user?: User,
  token?: string,
  expiry?: string,
  errors: Object[],
  meta?: {
    user?: User
  },
  data?: Session,
  permissions?: string[],
  features?: Feature[],
  organisations?: Organisation[],
  canSwitchOrganisations?: boolean,
  canViewAnalytics?: boolean,
  portalSettings?: PortalSettings
} & JwtAuth

export const initialState = {
  user: null,
  token: '',
  expiry: '',
  errors: [],
  loginFailures: 0,
  verificationFailures: 0,
  permissions: [],
  features: [],
  verifyingUser: false,
  verifiedUser: false,
  canSwitchOrganisations: false,
  organisations: [],
  canViewAnalytics: false,
  submitting: false,
  portalSettings: undefined
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }
  switch (action.type) {
    case success(actions.API_SESSION_FETCH):
    case success(actions.API_SESSION_PASSWORD_AUTH_CREATE): {
      const {
        user,
        token,
        expiry,
        permissions,
        features,
        canSwitchOrganisations,
        organisations,
        canViewAnalytics,
        portalSettings
      } = action

      return {
        ...initialState,
        user,
        token,
        expiry,
        permissions,
        features,
        canSwitchOrganisations,
        organisations,
        canViewAnalytics,
        portalSettings
      }
    }
    case success(actions.API_SESSION_JWT_AUTH_CREATE): {
      const { data: { secureId } = {}, redirect, notification } = action

      return {...state,
        jwt: {
          secureId,
          redirect,
          notification
        }
      }
    }
    case failure(actions.API_SESSION_PASSWORD_AUTH_CREATE): {
      const { errors } = action
      if (errors.some(err => err.code === INVALID_OTP_CODE)) {
        return {
          ...state,
          errors,
          verificationFailures: state.verificationFailures + 1
        }
      } else {
        return {
          ...state,
          errors,
          loginFailures: state.loginFailures + 1
        }
      }
    }
    case failure(actions.API_SESSION_ACCEPT_COOKIES_CREATE):
    case failure(actions.API_SESSION_FETCH):
    case failure(actions.API_SESSION_JWT_AUTH_CREATE): {
      const { errors } = action
      return { ...state, errors, submitting: false }
    }
    case success(actions.API_SESSION_ACCEPT_COOKIES_CREATE):
    case success(signupActions.API_SIGNUPS_CREATE):
    case success(passwordResetActions.API_PASSWORD_RESETS_RESETS_CREATE): {
      const { data = {} } = action
      const { user, token, expiry, permissions, features } = data
      return {
        ...state,
        user,
        token,
        expiry,
        permissions,
        features,
        submitting: false
      }
    }
    case actions.API_SESSION_ACCEPT_COOKIES_CREATE:
      return { ...state, submitting: true }
    case actions.INITIALIZE_LOGIN: {
      return { ...initialState }
    }
    default:
      return state
  }
}

export default reducer
