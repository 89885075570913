// @flow
import * as actions from './actions'
import { success, failure } from 'utilities/actions'

export type State = {
  data: Object,
  errors: Object[],
  meta: Object
}

type Action = {
  type: string,
  data?: Object,
  errors?: Object[],
  meta?: Object
}

const initialState = {
  data: {},
  errors: [],
  meta: {}
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }
  switch (action.type) {
    case success(actions.API_FEATURE_TOURS_INDEX):
    case success(actions.API_FEATURE_TOURS_UPDATE):
      return { ...state, data: action.data }
    case failure(actions.API_FEATURE_TOURS_INDEX):
    case failure(actions.API_FEATURE_TOURS_UPDATE):
      return { ...state, errors: action.errors }
    default:
      return state
  }
}

export default reducer
