// @flow
import type { UserPreferences } from './type'

export const FETCH_GENERAL_PREFERENCES = 'API_USER_PREFERENCES_SHOW'
export const FETCH_GENERAL_PREFERENCES_SUCCESS = 'API_USER_PREFERENCES_SHOW_SUCCESS'
export const FETCH_GENERAL_PREFERENCES_FAILURE = 'API_USER_PREFERENCES_SHOW_FAILURE'
export const SAVE_GENERAL_PREFERENCES = 'API_USER_PREFERENCES_UPDATE'
export const SAVE_GENERAL_PREFERENCES_SUCCESS = 'API_USER_PREFERENCES_UPDATE_SUCCESS'
export const SAVE_GENERAL_PREFERENCES_FAILURE = 'API_USER_PREFERENCES_UPDATE_FAILURE'
export const SET_TIMEZONE_PREFERENCE = 'SET_TIMEZONE_PREFERENCE'
export const SET_TIME_DISPLAY_EXAMPLE = 'SET_TIME_DISPLAY_EXAMPLE'

export const fetchGeneralPreferences = () => ({
  type: FETCH_GENERAL_PREFERENCES
})

export const saveGeneralPreferences = (preferences: UserPreferences) => ({
  type: SAVE_GENERAL_PREFERENCES,
  preferences
})

export const setPreferredTimezone = (value: string) => ({
  type: SET_TIMEZONE_PREFERENCE,
  value
})

export const setTimeExample = () => ({
  type: SET_TIME_DISPLAY_EXAMPLE
})
