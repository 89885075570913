// @flow
import { decamelize } from 'humps'
import { requestActions } from 'state/request'
import {
  API_URL,
  FEATURE_TOURS_ENDPOINT
} from 'qap/constants'
import { success, failure } from 'utilities/actions'

export const API_FEATURE_TOURS_INDEX = 'API_FEATURE_TOURS_INDEX'
export const API_FEATURE_TOURS_UPDATE = 'API_FEATURE_TOURS_UPDATE'

export const fetchFeatureTours = () => {
  return {
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url: `${API_URL}/${FEATURE_TOURS_ENDPOINT}`,
    successAction: success(API_FEATURE_TOURS_INDEX),
    failureAction: failure(API_FEATURE_TOURS_INDEX)
  }
}

export const updateFeatureTour = (id: string, data: { status: Object }) => {
  return {
    type: requestActions.AUTHED_REQUEST,
    method: 'PUT',
    url: `${API_URL}/${FEATURE_TOURS_ENDPOINT}/${decamelize(id)}`,
    successAction: success(API_FEATURE_TOURS_UPDATE),
    failureAction: failure(API_FEATURE_TOURS_UPDATE),
    body: { data }
  }
}
