// @flow
import React from 'react'
import { Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import QAPVars from 'qap/vars'
import DisplayStackTrace from './displayStackTrace'

import { Theme } from '@mui/material/styles'
import type { ErrorComponentInterface } from './types'

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: QAPVars.white,
    padding: theme.spacing(3),
    fontFamily: theme.typography.mediumFontFamily,
    textAlign: 'center'
  }
})

const ContentErrorBoundary = ({ classes, errors }: ErrorComponentInterface) => (
  <div className={classes.root}>
    <Typography variant='h1'>Something has gone wrong</Typography>
    The development team has been notified of the issue

    <DisplayStackTrace errors={errors} />
  </div>
)

export default withStyles(styles)(ContentErrorBoundary)
