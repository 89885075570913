// @flow
import { decamelize } from 'humps'

import type {
  TourStep
} from 'types'

export const stepsStatusHash = ({ newStatus, steps }:{ newStatus: string, steps: TourStep[] }) => {
  const output = {}

  steps.forEach(({ name, status }) => (output[decamelize(name)] = status || newStatus))

  return output
}
