// @flow
import {
  API_URL,
  API_ROOT
} from 'qap/constants'

import { requestActions } from 'state/request'

const resource = (strings: Array<string>) => {
  return strings.join('/')
}

export const FETCH_API_SPECIFICATION = 'FETCH_API_SPECIFICATION'
export const FETCH_API_SPECIFICATION_SUCCESS = 'FETCH_API_SPECIFICATION_SUCCESS'
export const FETCH_API_SPECIFICATION_FAILURE = 'FETCH_API_SPECIFICATION_FAILURE'

export const fetchApiSpecification = () => {
  return {
    type: requestActions.UNAUTHED_REQUEST,
    method: 'GET',
    url: resource([API_URL, API_ROOT]),
    successAction: FETCH_API_SPECIFICATION_SUCCESS,
    failureAction: FETCH_API_SPECIFICATION_FAILURE
  }
}
