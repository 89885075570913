// @flow
import React from 'react'
import { useMediaQuery, useTheme, Collapse, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import Alert from '@mui/material/Alert'
import classnames from 'classnames'

import { ENV_BANNER_ENABLED } from 'qap/constants'
import QAPVars from 'qap/vars'

type Props = {
  message: string,
  heading: string,
  notificationType: string,
  open: boolean,
  acknowledgeGlobalNotification: () => void,
  classes: Object
}

const styles = theme => ({
  root: {
    position: 'fixed',
    zIndex: theme.zIndex.tooltip + 1,
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  marginTop: {
    marginTop: theme.spacing(3.5)
  },
  h1: {
    color: QAPVars.white,
    marginBottom: theme.spacing(1)
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
    color: QAPVars.white,
  },
  message: {
    '& p': {
      marginTop: 0
    },
    '& p:last-child': {
      marginBottom: 0
    },
    '& a': {
      textDecoration: 'none'
    }
  },
  largeIcon: {
    fontSize: theme.spacing(10)
  },
  smallIcon: {
    fontSize: theme.spacing(6.75)
  },
  wrapperInner: {
    margin: 'auto',
    maxWidth: theme.spacing(125)
  },
  action: {
    alignSelf: 'flex-start'
  },
  error: {
    backgroundColor: QAPVars.sRed
  },
  info: {
    backgroundColor: QAPVars.hero
  },
  warning: {
    backgroundColor: QAPVars.warningOrange
  },
  success: {
    backgroundColor: QAPVars.sGreen
  }
})

const GlobalNotificationAlert = ({
  message,
  heading,
  open,
  notificationType,
  acknowledgeGlobalNotification,
  classes
}: Props) => {
  const theme = useTheme()
  const iconClass = useMediaQuery(theme.breakpoints.up('md')) ? 'largeIcon' : 'smallIcon'

  return (
    <div
      className={
        classnames(
          classes.root,
          { [classes.marginTop]: ENV_BANNER_ENABLED }
        )
      }
    >
      <Collapse
        in={open}
        classes={{ wrapperInner: classes.wrapperInner }}
        className={classes[notificationType]}
      >
        <Alert
          className={classes.alert}
          classes={{ icon: classes[iconClass], action: classes.action }}
          color='inherit'
          variant='filled'
          severity={notificationType}
          onClose={acknowledgeGlobalNotification}
          data-testid='GlobalNotificationAlert'
        >
          <Typography variant='h1' className={classes.h1}>
            {heading}
          </Typography>
          <Typography
            variant='body1'
            component='div'
            dangerouslySetInnerHTML={{ __html: message }}
            className={classes.message}
          />
        </Alert>
      </Collapse>
    </div>
  )
}

export default withStyles(styles)(GlobalNotificationAlert)
