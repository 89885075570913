// @flow
import { success, failure } from 'utilities/actions'
import * as actions from './actions'
import { subDays } from 'utilities/date'
import { TAB_OPTIONS } from 'components/pages/roundEnrolmentsOverviewPage/constants'
import { rewriteFilter } from './helpers'

import type { RoundCountItem } from 'components/pages/roundEnrolmentsOverviewPage/types'

export const ROUND_ENROLMENTS_OVERVIEW_TAB_STATES = {
  missed: TAB_OPTIONS.missed.state,
  late: TAB_OPTIONS.late.state
}

export type State = {
  data: Array<RoundCountItem>,
  isLoading: boolean,
  filter: {},
  errors: Object[],
  selectedTab: string
}

export type Action = Object & {
  type: string
}

export const initialFilterState = {
  filter: {
    status: ROUND_ENROLMENTS_OVERVIEW_TAB_STATES.missed,
    startDate: subDays({ days:90 })
  }
}

export const initialState = {
  data: [],
  isLoading: false,
  errors: [],
  selectedTab: ROUND_ENROLMENTS_OVERVIEW_TAB_STATES.missed[0],
  ...initialFilterState
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }

  switch (action.type) {
    case success(actions.API_ROUND_ENROLMENTS_COUNTS_INDEX):
      const { data } = action
      return {
        ...state,
        data,
        isLoading: false,
      }
    case failure(actions.API_ROUND_ENROLMENTS_COUNTS_INDEX):
      return {
        ...state,
        errors: action.errors,
        isLoading: false,
      }
    case actions.API_ROUND_ENROLMENTS_COUNTS_SET_ALL_FILTERS: {
      const { filter } = action
      const { status = [] } = filter
      const updatedFilter = rewriteFilter(filter)
      const updatedTab = status[0] || initialState.selectedTab

      return {
        ...state,
        filter: updatedFilter,
        selectedTab: updatedTab,
        isLoading: true,
      }
    }

    default:
      return state
  }
}

export default reducer
