// @flow
import React, { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import classnames from 'classnames'
import QAPVars from 'qap/vars'

import type { Node } from 'react'

import { setScrollPosition } from 'utilities/scrollToPosition'

type Props = {
  children: Node,
  to: string,
  className?: string,
  hideUnderline?: boolean,
  classes: Object,
}

export const styles = {
  root: {
    color: QAPVars.linkBlue,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  hideUnderline: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}

const Link = (props: Props, ref) => {
  const { classes, className, hideUnderline, ...rest } = props
  const classNames = classnames(className, hideUnderline && classes.hideUnderline, classes.root)

  return (
    <RouterLink ref={ref} className={classNames} onClick={() => setScrollPosition()} {...rest} />
  )
}

export default withStyles(styles)(forwardRef(Link))
