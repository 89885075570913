// @flow
import { success, failure } from 'utilities/actions'
import {
  all,
  call,
  cancel,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  // $FlowFixMe
  delay
} from 'redux-saga/effects'
import { decamelizeKeys } from 'utilities/humps'
import { stringify } from 'utilities/apiQueryString'
import * as actions from './actions'
import { requestActions } from 'state/request'
import { LATE_SUBMISSION_ERROR_CODE, findError } from './helper'
import { createQueue, enqueueJob } from 'state/queue'

import {
  API_URL,
  ROUND_ENROLMENTS_ENDPOINT,
  SURVEY_ROUND_SUBMISSIONS_ENDPOINT,
  UPDATE_RESULTS_DELAY
} from 'qap/constants'

import type {
  UpdateResultsAction,
  UnprocessedRequestAction
} from './types'

function * fetch (action) : Generator<any, any, any> {
  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url: `${API_URL}/${ROUND_ENROLMENTS_ENDPOINT}/${action.id}`,
    successAction: success(actions.API_ROUND_ENROLMENTS_SHOW),
    failureAction: failure(actions.API_ROUND_ENROLMENTS_SHOW)
  })
}

function * fetchSubmissions (action) : Generator<any, any, any> {
  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url: `${API_URL}/${ROUND_ENROLMENTS_ENDPOINT}/${action.id}/submissions`,
    successAction: success(actions.API_ROUND_ENROLMENTS_SUBMISSIONS_INDEX),
    failureAction: failure(actions.API_ROUND_ENROLMENTS_SUBMISSIONS_INDEX)
  })
}

function * fetchSubmission (action) : Generator<any, any, any> {
  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url: `${API_URL}/${SURVEY_ROUND_SUBMISSIONS_ENDPOINT}/${action.id}`,
    successAction: success(actions.API_SURVEY_ROUND_SUBMISSIONS_SHOW),
    failureAction: failure(actions.API_SURVEY_ROUND_SUBMISSIONS_SHOW)
  })
}

function * fetchRelatedPrograms (action) : Generator<any, any, any> {
  const queryString = stringify({
    filter: {
      program: action.programId,
      survey_number: action.surveyNumber
    }
  })

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url: `${API_URL}/${ROUND_ENROLMENTS_ENDPOINT}?${queryString}`,
    successAction: success(actions.API_ROUND_ENROLMENTS_RELATED_PROGRAMS),
    failureAction: failure(actions.API_ROUND_ENROLMENTS_RELATED_PROGRAMS)
  })
}

let requestsQueue = null
let scheduleUpdateResultsTask = null

export function * updateResults (payload: { id: string, results: Object }) : Generator<any, any, any> {
  const { id, results } = payload

  yield put({ type: actions.START_UPDATING_RESULTS })
  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'PUT',
    url: `${API_URL}/${ROUND_ENROLMENTS_ENDPOINT}/${id}/results`,
    body: { data: decamelizeKeys({ results }) },
    successAction: success(actions.API_ROUND_ENROLMENTS_RESULTS_UPDATE),
    failureAction: failure(actions.API_ROUND_ENROLMENTS_RESULTS_UPDATE)
  })

  // wait for request to resolve
  const response = yield take([
    success(actions.API_ROUND_ENROLMENTS_RESULTS_UPDATE),
    failure(actions.API_ROUND_ENROLMENTS_RESULTS_UPDATE)
  ])

  if (response.type === failure(actions.API_ROUND_ENROLMENTS_RESULTS_UPDATE)) {
    throw new Error(response.status)
  }

  yield clearUnsavedResults()
}

export function * scheduleUpdateResults (action: UpdateResultsAction) : Generator<any, any, any> {
  if (action.meta.delay) yield delay(UPDATE_RESULTS_DELAY)
  const { unsavedResults: results } = yield select(state => state.roundEnrolment)
  const hasUnsavedResults = Object.keys(results).length > 0

  if (hasUnsavedResults) {
    const payload = {
      handler: updateResults,
      payload: { id: action.id, results },
      errorHandler: restoreUnsavedResults
    }
    yield call(enqueueJob, requestsQueue, payload)
  }

  scheduleUpdateResultsTask = null
}

function * prepareResults (payload) : Generator<any, any, any> {
  const { id, results } = payload

  // Cancel outstanding update results task to prevent race condition between results preparation and update results
  if (scheduleUpdateResultsTask) yield cancel(scheduleUpdateResultsTask)

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'PUT',
    url: `${API_URL}/${ROUND_ENROLMENTS_ENDPOINT}/${id}/prepared_results`,
    body: { data: decamelizeKeys({ results }) },
    successAction: success(actions.API_ROUND_ENROLMENTS_PREPARE_RESULTS),
    failureAction: failure(actions.API_ROUND_ENROLMENTS_PREPARE_RESULTS)
  })

  // wait for request to resolve
  const response = yield take([
    success(actions.API_ROUND_ENROLMENTS_PREPARE_RESULTS),
    failure(actions.API_ROUND_ENROLMENTS_PREPARE_RESULTS)
  ])

  if (response.type === failure(actions.API_ROUND_ENROLMENTS_PREPARE_RESULTS)) {
    throw new Error(response.status)
  }

  yield clearUnsavedResults()
}

function * schedulePrepareResults (action) : Generator<any, any, any> {
  const { unsavedResults: results } = yield select(state => state.roundEnrolment)

  const payload = {
    handler: prepareResults,
    payload: { id: action.id, results },
    errorHandler: restoreUnsavedResults
  }
  yield call(enqueueJob, requestsQueue, payload)
}

export function * accumulateResults (action: UpdateResultsAction) : Generator<any, any, any> {
  const { resultId, values } = action
  yield put({
    type: actions.ACCUMULATE_UNSAVED_RESULTS,
    data: { resultId, values }
  })

  if (scheduleUpdateResultsTask) yield cancel(scheduleUpdateResultsTask)
  scheduleUpdateResultsTask = yield fork(scheduleUpdateResults, action)
}

function * clearUnsavedResults () : Generator<any, any, any> {
  const isQueueEmpty = requestsQueue && requestsQueue.buffer.isEmpty()
  const hasPendingTask = scheduleUpdateResultsTask && scheduleUpdateResultsTask.isRunning()
  // defer clearing unsaved results from store until all requests have been successfully processed
  if (!isQueueEmpty || hasPendingTask) return null

  yield put({
    type: actions.CLEAR_UNSAVED_RESULTS
  })
}

function * submitResults (action) : Generator<any, any, any> {
  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'POST',
    url: `${API_URL}/${ROUND_ENROLMENTS_ENDPOINT}/${action.id}/submission`,
    successAction: success(actions.API_ROUND_ENROLMENTS_RESULTS_SUBMIT),
    failureAction: failure(actions.API_ROUND_ENROLMENTS_RESULTS_SUBMIT)
  })
}

function * internalSubmission (action) : Generator<any, any, any> {
  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'POST',
    url: `${API_URL}/${ROUND_ENROLMENTS_ENDPOINT}/${action.id}/internal_submissions`,
    body: { data: decamelizeKeys(action.payload) },
    successAction: success(actions.API_ROUND_ENROLMENTS_INTERNAL_SUBMISSIONS_CREATE),
    failureAction: failure(actions.API_ROUND_ENROLMENTS_INTERNAL_SUBMISSIONS_CREATE)
  })
}

export function * restoreUnsavedResults (unprocessedRequests: UnprocessedRequestAction[]) : Generator<any, any, any> {
  yield put({
    type: actions.RESTORE_UNSAVED_RESULTS,
    unprocessedRequests
  })
}

function * updateConfig (action) : Generator<any, any, any> {
  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'PUT',
    url: `${API_URL}/${ROUND_ENROLMENTS_ENDPOINT}/${action.id}/preferences`,
    body: { data: decamelizeKeys(action.payload) },
    successAction: success(actions.API_ROUND_ENROLMENT_CONFIG_UPDATE),
    failureAction: failure(actions.API_ROUND_ENROLMENT_CONFIG_UPDATE)
  })
}

export function * handleError (action: Object) : Generator<any, any, any> {
  const { status, errors } = action
  const error = findError(errors)

  if (status !== '422' || !error) return

  const reloadPage = error.code === LATE_SUBMISSION_ERROR_CODE ? () => window.location.reload() : null

  yield put({
    type: requestActions.REQUEST_VALIDATION_ERROR,
    error: { ...error, status: error.code, message: error.detail, onClose: reloadPage }
  })
}

function * watchError () : Generator<any, any, any> {
  yield takeEvery([
    failure(actions.API_ROUND_ENROLMENTS_RESULTS_UPDATE),
    failure(actions.API_ROUND_ENROLMENTS_RESULTS_SUBMIT),
    failure(actions.API_ROUND_ENROLMENTS_PREPARE_RESULTS)
  ], handleError)
}

function * watchFetch () : Generator<any, any, any> {
  yield takeLatest([
    actions.API_ROUND_ENROLMENTS_SHOW
  ], fetch)
}

function * watchFetchSubmissions () : Generator<any, any, any> {
  yield takeLatest([
    actions.API_ROUND_ENROLMENTS_SUBMISSIONS_INDEX
  ], fetchSubmissions)
}

function * watchFetchSubmission () : Generator<any, any, any> {
  yield takeLatest([
    actions.API_SURVEY_ROUND_SUBMISSIONS_SHOW
  ], fetchSubmission)
}

function * watchFetchRelatedPrograms () : Generator<any, any, any> {
  yield takeLatest([
    actions.API_ROUND_ENROLMENTS_RELATED_PROGRAMS
  ], fetchRelatedPrograms)
}

export function * watchUpdateResults () : Generator<any, any, any> {
  yield takeEvery([
    actions.API_ROUND_ENROLMENTS_RESULTS_UPDATE
  ], accumulateResults)
}

function * watchPrepareResults () : Generator<any, any, any> {
  yield takeEvery([
    actions.API_ROUND_ENROLMENTS_PREPARE_RESULTS
  ], schedulePrepareResults)
}

function * watchSubmitResults () : Generator<any, any, any> {
  yield takeEvery([
    actions.API_ROUND_ENROLMENTS_RESULTS_SUBMIT
  ], submitResults)
}

function * watchInternalSubmission () : Generator<any, any, any> {
  yield takeEvery([
    actions.API_ROUND_ENROLMENTS_INTERNAL_SUBMISSIONS_CREATE
  ], internalSubmission)
}

function * watchUpdateConfig () : Generator<any, any, any> {
  yield takeEvery([
    actions.API_ROUND_ENROLMENT_CONFIG_UPDATE
  ], updateConfig)
}

function * initialiseQueue () : Generator<any, any, any> {
  requestsQueue = yield createQueue({ concurrent: 1 })
  yield fork(requestsQueue.workers)
}

export default function * roundEnrolmentSagas () : Generator<any, any, any> {
  yield all([
    fork(watchFetch),
    fork(watchFetchSubmissions),
    fork(watchFetchSubmission),
    fork(watchFetchRelatedPrograms),
    fork(watchError),
    fork(watchUpdateResults),
    fork(watchPrepareResults),
    fork(watchSubmitResults),
    fork(watchInternalSubmission),
    fork(watchUpdateConfig),
    fork(initialiseQueue)
  ])
}
