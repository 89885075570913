// @flow
import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { featureTourActions } from 'state/featureTours'
import Tour from 'components/molecules/tour'
import TourAlert from 'components/molecules/tourAlert'
import { setSteps, tourAlertViewed } from './helpers'

import type {
  TourStep
} from 'types'

type Props = {
  featureTours: Object,
  feature: string,
  steps: TourStep[],
  message?: string,
  showTourAlert?: boolean,
  updateFeatureTour: (id: string, data: Object) => void
}

const TourTemplate = (props: Props) => {
  const { feature = '', steps, message, showTourAlert, featureTours = {}, updateFeatureTour } = props
  const [run, setRun] = useState(false)

  const featureTour = featureTours[feature]

  const shouldRun = !showTourAlert || tourAlertViewed({ featureTour })

  useEffect(() => {
    setRun(shouldRun)
  }, [shouldRun])

  if (!featureTours) return null
  if (Object.keys(featureTours).length === 0) return null

  const stepsArr = setSteps({ featureTour: featureTours[feature], steps, run: shouldRun })
  const handleStart = () => setRun(true)

  return (
    <Fragment>
      {
        showTourAlert && !run &&
        <TourAlert
          feature={feature}
          featureTours={featureTours}
          message={message}
          onStart={handleStart}
          updateFeatureTour={updateFeatureTour}
          steps={stepsArr}
        />
      }
      <Tour
        feature={feature}
        featureTours={featureTours}
        run={run}
        steps={stepsArr}
        updateFeatureTour={updateFeatureTour}
      />
    </Fragment>
  )
}

const mapStateToProps = (state, props) => ({
  featureTours: state.featureTours.data
})

const mapDispatchToProps = {
  updateFeatureTour: featureTourActions.updateFeatureTour
}

export default connect(mapStateToProps, mapDispatchToProps)(TourTemplate)
