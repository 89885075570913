// @flow
import { success, failure } from 'utilities/actions'
import * as actions from './actions'
import { subDays } from 'utilities/date'
import { TAB_OPTIONS } from 'components/pages/reportsOverviewPage/constants'
import { getTabOption } from 'components/organisms/pageTabs/helpers'

import type { ReportCountItem } from 'components/pages/reportsOverviewPage/types'

export const REPORTS_OVERVIEW_TAB_STATES = {
  inProgress: TAB_OPTIONS.inProgress.state,
  notReviewed: TAB_OPTIONS.notReviewed.state
}

export type State = {
  data: Array<ReportCountItem>,
  isLoading: boolean,
  filter: {},
  errors: Object[],
  selectedTab: string,
  searchParams?: {
    [string]: string
  }
}

export type Action = Object & {
  type: string
}

export const initialFilterState = {
  filter: {
    reviewStatus: REPORTS_OVERVIEW_TAB_STATES.inProgress,
    startDate: subDays({ days:90 })
  }
}

export const initialState = {
  data: [],
  isLoading: false,
  errors: [],
  selectedTab: REPORTS_OVERVIEW_TAB_STATES.inProgress[0],
  ...initialFilterState
}

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case success(actions.API_REPORTS_COUNTS_INDEX):
      const { data } = action
      return {
        ...state,
        data,
        errors: [],
        isLoading: false
      }
    case failure(actions.API_REPORTS_COUNTS_INDEX):
      return {
        ...state,
        errors: action.errors,
        isLoading: false
      }
    case actions.API_REPORTS_COUNTS_SET_ALL_FILTERS:
      const { filter } = action
      const { reviewStatus = [] } = filter
      const updatedTab = reviewStatus[0] || initialState.selectedTab
      // get pre-configured url params for the selected tab
      const { searchParams } = getTabOption({
        tabOptions: TAB_OPTIONS,
        selectedTab: updatedTab
      })

      return {
        ...state,
        filter,
        searchParams,
        selectedTab: updatedTab,
        isLoading: true
      }
    default:
      return state
  }
}

export default reducer

