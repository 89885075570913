// @flow
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'

import { disableGoogleAnalytics } from 'utilities/googleAnalytics'

const GoogleAnalyticsGuard = () => {
  const { acceptingCookies } = useSelector(state => state.auth.user) || {}

  return (
    <Fragment>
      { (acceptingCookies === false) && disableGoogleAnalytics() }
    </Fragment>
  )
}

export default GoogleAnalyticsGuard
