// @flow
import { call, put, takeEvery, all, fork, select } from 'redux-saga/effects'
// $FlowFixMe
import { resetLoading, showLoading } from 'react-redux-loading-bar'

import * as actions from './actions'
import { expireSession } from '../auth/actions'
import { fetch } from 'api'
import { camelizeKeys } from 'humps'

function * sendRequest (needsAuth, action) : Generator<any, any, any> {
  const { method, url, successAction, failureAction, body, redirect, notification, endpointType, skipGlobalErrorHandling } = action
  try {
    if (!action.hideLoading) {
      const isLoadingBarShowing = yield select(state =>
        state.loadingBar.default ? state.loadingBar.default !== 0 : false)
      if (!isLoadingBarShowing) {
        yield put(showLoading())
      }
    }

    let options = { body, method }
    if (needsAuth) {
      const token = yield select(state => state.auth.token)
      options = { ...options, token }
    }

    const response = yield call(fetch, url, options)
    const { ok, body: result } = response

    if (!ok || result.errors) {
      yield put({ type: actions.REQUEST_FAIL, failureAction, url, response, skipGlobalErrorHandling })
    } else {
      yield put({ type: successAction, ...camelizeKeys(result), redirect, notification, endpointType })
    }
  } catch (e) {
    yield put({ type: failureAction, errors: [camelizeKeys(e)] })
    if (!skipGlobalErrorHandling) {
      yield put({ type: actions.ADD_REQUEST_ERROR, error: { url, status: 'failed_to_fetch' } })
    }
  } finally {
    yield put({ type: actions.REQUEST_DONE })
  }
}

function * requestDone (action) : Generator<any, any, any> {
  const inFlightRequestsNum = yield select(state => state.request.inFlight)

  if (inFlightRequestsNum < 1) {
    yield put(resetLoading())
  }
}

function * requestFail (action: Object): Generator<any, any, any> {
  const {
    url,
    failureAction,
    skipGlobalErrorHandling,
    response: { status, statusText, body }
  } = action
  const errors = camelizeKeys(body.errors || [])

  if (errors.find(e => e.source === 'token' && e.code === 'invalid')) {
    yield put(expireSession())
  } else {
    yield put({ type: failureAction, status, errors })
    if (!skipGlobalErrorHandling) {
      yield put({ type: actions.ADD_REQUEST_ERROR, error: { url, status, statusText, errors } })
    }
  }
}

function * watchAuthRequests () : Generator<any, any, any> {
  yield takeEvery(actions.AUTHED_REQUEST, sendRequest, true)
}

function * watchUnauthRequests () : Generator<any, any, any> {
  yield takeEvery(actions.UNAUTHED_REQUEST, sendRequest, false)
}

function * watchRequestDone () : Generator<any, any, any> {
  yield takeEvery(actions.REQUEST_DONE, requestDone)
}

function * watchRequestFail () : Generator<any, any, any> {
  yield takeEvery(actions.REQUEST_FAIL, requestFail)
}

export default function * requestSagas () : Generator<any, any, any> {
  yield all([
    fork(watchAuthRequests),
    fork(watchUnauthRequests),
    fork(watchRequestDone),
    fork(watchRequestFail)
  ])
}
