// @flow
import reducer from './reducer'

import * as dashboardActions from './actions'
import dashboardSagas from './sagas'

export {
  dashboardActions,
  dashboardSagas
}

export default reducer
