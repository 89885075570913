// @flow
import * as actions from './actions'
import { success, failure } from 'utilities/actions'

import type { Organisation } from 'types'

export type State = {
  data: Organisation[],
  errors: Object[],
}

type Action = {
  type: string,
  data?: Organisation[],
  errors?: Object[]
}

export const initialState = {
  data: [],
  errors: []
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }
  switch (action.type) {
    case success(actions.API_ORGANISATIONS_INDEX):
      return { ...state, data: action.data }
    case failure(actions.API_ORGANISATIONS_INDEX):
      return { ...state, errors: action.errors }
    default:
      return state
  }
}

export default reducer
