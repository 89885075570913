// @flow
import * as actions from './actions'
import { success, failure, reset } from 'utilities/actions'

export type State = {
  data: Object,
  errors: Object[],
  meta: Object,
  message?: string | null,
  redirect?: ?string
}

type Action = {
  type: string,
  data?: Object,
  errors?: Object[],
  meta?: Object,
  message?: string | null,
  redirect?: ?string
}

const initialState = {
  data: {},
  errors: [],
  meta: {}
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }
  switch (action.type) {
    case success(actions.API_PASSWORD_RESET_REQUESTS_SHOW):
      return { ...state, data: action.data, meta: action.meta }
    case success(actions.API_PASSWORD_RESET_REQUESTS_CREATE):
      return { ...state, data: action.data, message: null }
    case success(actions.API_PASSWORD_RESETS_RESETS_CREATE):
      return { ...state, data: action.data }
    case failure(actions.API_PASSWORD_RESET_REQUESTS_CREATE):
    case failure(actions.API_PASSWORD_RESET_REQUESTS_SHOW):
    case failure(actions.API_PASSWORD_RESETS_RESETS_CREATE):
      return { ...state, errors: action.errors }
    case reset(actions.API_PASSWORD_RESETS_RESETS_CREATE):
      return { ...state, ...initialState }
    default:
      return state
  }
}

export default reducer
