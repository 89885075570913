// @flow
import { buffers, eventChannel, END } from 'redux-saga'

export default function createUploadChannel (url: string, file: window.File) {
  return eventChannel(emitter => {
    const xhr = new window.XMLHttpRequest()
    const onProgress = (e: window.ProgressEvent) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total
        emitter({ progress })
      }
    }
    const onFailure = (e: window.ProgressEvent) => {
      emitter({ error: new Error('Upload failed') })
      emitter(END)
    }
    xhr.upload.addEventListener('progress', onProgress)
    xhr.upload.addEventListener('error', onFailure)
    xhr.upload.addEventListener('abort', onFailure)
    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr
      if (readyState === 4) {
        if (status === 200) {
          emitter({ success: true })
          emitter(END)
        } else {
          onFailure(null)
        }
      }
    }
    xhr.open('PUT', url, true)
    xhr.send(file)
    return () => {
      xhr.upload.removeEventListener('progress', onProgress)
      xhr.upload.removeEventListener('error', onFailure)
      xhr.upload.removeEventListener('abort', onFailure)
      xhr.onreadystatechange = null
      xhr.abort()
    }
  }, buffers.sliding(2))
}
