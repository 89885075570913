// @flow
import type {
  TourStep
} from 'types'

export const setSteps = ({ featureTour, steps, run }:{ featureTour: ?string|Object, steps: TourStep[], run: ?boolean }) => {
  steps.forEach(step => {
    step['status'] = setStepStatus({ featureTour, step })
    step['disableBeacon'] = !run
  })

  return steps
}

const setStepStatus = ({ featureTour, step }:{ featureTour: ?string|Object, step: TourStep }) => {
  if (typeof featureTour === 'string') return featureTour === 'completed' ? 'viewed' : featureTour
  if (!featureTour) return ''

  return featureTour[step['name']]
}

export const tourAlertViewed = ({ featureTour }:{ featureTour: ?string|Object }) => {
  if (!featureTour) return false

  if (typeof featureTour === 'string') return true

  if (typeof featureTour === 'object') return Object.values(featureTour).some(value => value)
}
