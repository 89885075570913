// @flow
import React, {Component} from 'react'
import classnames from 'classnames'
import {NavLink} from 'react-router-dom'
import withStyles from '@mui/styles/withStyles'
import {Drawer, Hidden, IconButton, ListItemIcon, ListItemText, MenuItem, Tooltip} from '@mui/material'
import AnalyticsIcon from '@mui/icons-material/Timeline'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import DvrIcon from '@mui/icons-material/Dvr'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import InvoicesIcon from '@mui/icons-material/ReceiptOutlined'
import AddCommentIcon from '@mui/icons-material/AddComment'
import HomeIcon from '@mui/icons-material/Home'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import CardMembershipIcon from '@mui/icons-material/CardMembership'
import TodayIcon from '@mui/icons-material/Today'
import PlaylistAddCheck from "@mui/icons-material/PlaylistAddCheck"
import CorporationIcon from 'components/atoms/corporationIcon'
import ExternalLink from "components/atoms/externalLink"
import {IsPermitted} from 'components/molecules/isPermitted'
import Permission from 'components/molecules/permission'
import {
  ANALYTICS_PAGE_URL,
  CERTIFICATES_PAGE_URL,
  CLASSIC_PORTAL_ACCESS,
  DASHBOARD_PAGE_URL,
  DATA_ANALYSIS_ASSESSMENT_CRITERIA_URL,
  DATA_EXTRACT_PAGE_URL,
  ENROLMENTS_TAB_PAGE_URL,
  ENV_BANNER_ENABLED,
  EXPERIMENTAL_FEATURE_ACCESS,
  INVOICES_TAB_PAGE_URL,
  REPORTS_PAGE_URL,
  REQUESTS_TAB_PAGE_URL,
  ROUND_ENROLMENTS_PAGE_URL
} from 'qap/constants'
import {API_ROUND_ENROLMENTS_INDEX} from 'state/roundEnrolments/actions'
import {API_REPORTS_INDEX} from 'state/reports/actions'
import {FETCH_CALENDAR_PREFERENCE} from 'state/calendarPreference/actions'
import {API_SALESFORCE_PORTAL_JWT_INDEX} from 'state/salesforcePortalTabs/actions'
import QAPVars from 'qap/vars'
import {AuthContext} from 'contexts/auth'
import {stringifyCalendarLink} from './helpers'

type Props = {
  openSidebar: boolean,
  onDrawerToggle: () => void,
  classes: Object,
}

const styles = theme => ({
  menuItem: {
    marginTop: 8,
    paddingTop: 8,
    paddingBottom: 8,
    '&:hover': {
      backgroundColor: QAPVars.white20,
    },
  },
  menuItemTextWrap:{
    alignItems: 'start',
    textWrap:'wrap',
  },
  selected: {
    backgroundColor: QAPVars.sBLight,
  },
  drawerPaper: {
    width: QAPVars.sideNavBarWidth,
    backgroundColor: QAPVars.sBlue,
    top: ENV_BANNER_ENABLED ? QAPVars.envBannerHeight : 0,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    padding: '0 8px',
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('lg')]: {
      backgroundColor: QAPVars.white,
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
    },
  },
  corporationIcon: {
    height: '3.4em',
    paddingLeft: '16px',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  listIcon: {
    color: QAPVars.white70,
    width: theme.spacing(3),
  },
  chevron: {
    [theme.breakpoints.up('lg')]: {
      color: QAPVars.white70,
    },
  },
  primary: {
    paddingLeft: theme.spacing(1),
    color: QAPVars.white,
    fontSize: '0.9375rem',
    textTransform: 'capitalize',
  },
  drawerOpen: {
    [theme.breakpoints.up('md')]: {
      width: QAPVars.sideNavBarWidth,
      zIndex: theme.zIndex.appBar + 1,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  drawerClose: {
    [theme.breakpoints.up('md')]: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: QAPVars.sideBarNavCollapsedWidth,
    },
  },
})

class SideBar extends Component<Props> {
  static contextType = AuthContext

  drawer = ({ collapsed }) => {
    const { classes, openSidebar, onDrawerToggle } = this.props
    const { isFeatureActive, canViewAnalytics } = this.context

    const dashboardPageEnabled = isFeatureActive('dashboard')
    const salesforcePortalIntegrationEnabled = isFeatureActive('salesforce_portal_integration')
    const handleMenuClick = () => {
      if (collapsed && openSidebar) {
        onDrawerToggle()
      }
    }

    return (
      <>
        <div className={classes.drawerHeader}>
          <CorporationIcon className={classes.corporationIcon} />
          <Tooltip title={openSidebar ? 'Collapse the sidebar' : ''}>
            <IconButton color='secondary' onClick={onDrawerToggle} size='large'>
              <ChevronLeftIcon className={classes.chevron} />
            </IconButton>
          </Tooltip>
        </div>

        {dashboardPageEnabled && (
          <Permission include={EXPERIMENTAL_FEATURE_ACCESS.viewDashboard}>
            <Tooltip title={openSidebar ? '' : 'Dashboard'}>
              <MenuItem
                component={NavLink}
                to={DASHBOARD_PAGE_URL}
                activeClassName={classes.selected}
                className={classes.menuItem}
                onClick={handleMenuClick}
              >
                <ListItemIcon>
                  <HomeIcon className={classes.listIcon} />
                </ListItemIcon>
                <ListItemText primary='Dashboard' classes={{ primary: classes.primary }} />
              </MenuItem>
            </Tooltip>
          </Permission>
        )}

        <IsPermitted action={API_REPORTS_INDEX}>
          <Tooltip title={openSidebar ? '' : 'Reports'}>
            <MenuItem
              component={NavLink}
              to={REPORTS_PAGE_URL}
              activeClassName={classes.selected}
              className={classes.menuItem}
              onClick={handleMenuClick}
            >
              <ListItemIcon>
                <MenuBookIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText primary='Reports' classes={{ primary: classes.primary }} />
            </MenuItem>
          </Tooltip>
        </IsPermitted>

        <IsPermitted action={API_ROUND_ENROLMENTS_INDEX}>
          <Tooltip title={openSidebar ? '' : 'Result Entry'}>
            <MenuItem
              component={NavLink}
              to={ROUND_ENROLMENTS_PAGE_URL}
              activeClassName={classes.selected}
              className={classes.menuItem}
              onClick={handleMenuClick}
            >
              <ListItemIcon>
                <DvrIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText primary='Result Entry' classes={{ primary: classes.primary }} />
            </MenuItem>
          </Tooltip>
        </IsPermitted>

        {salesforcePortalIntegrationEnabled && (
          <>
            <Permission include={CLASSIC_PORTAL_ACCESS.viewRequests}>
              <IsPermitted action={API_SALESFORCE_PORTAL_JWT_INDEX}>
                <Tooltip title={openSidebar ? '' : 'Requests'}>
                  <MenuItem
                    component={NavLink}
                    to={REQUESTS_TAB_PAGE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.menuItem}
                  >
                    <ListItemIcon>
                      <AddCommentIcon className={classes.listIcon} />
                    </ListItemIcon>
                    <ListItemText primary='Requests' classes={{ primary: classes.primary }} />
                  </MenuItem>
                </Tooltip>
              </IsPermitted>
            </Permission>

            <Permission include={CLASSIC_PORTAL_ACCESS.viewEnrolments}>
              <IsPermitted action={API_SALESFORCE_PORTAL_JWT_INDEX}>
                <Tooltip title={openSidebar ? '' : 'Enrolments'}>
                  <MenuItem
                    component={NavLink}
                    to={ENROLMENTS_TAB_PAGE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.menuItem}
                  >
                    <ListItemIcon>
                      <AddShoppingCartIcon className={classes.listIcon} />
                    </ListItemIcon>
                    <ListItemText primary='Enrolments' classes={{ primary: classes.primary }} />
                  </MenuItem>
                </Tooltip>
              </IsPermitted>
            </Permission>
            <Permission include={CLASSIC_PORTAL_ACCESS.viewInvoices}>
              <IsPermitted action={API_SALESFORCE_PORTAL_JWT_INDEX}>
                <Tooltip title={openSidebar ? '' : 'Invoices'}>
                  <MenuItem
                    component={NavLink}
                    to={INVOICES_TAB_PAGE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.menuItem}
                  >
                    <ListItemIcon>
                      <InvoicesIcon className={classes.listIcon} />
                    </ListItemIcon>
                    <ListItemText primary='Invoices' classes={{ primary: classes.primary }} />
                  </MenuItem>
                </Tooltip>
              </IsPermitted>
            </Permission>
          </>
        )}

        <IsPermitted action={API_REPORTS_INDEX}>
          <Tooltip title={openSidebar ? '' : 'Certificates'}>
            <MenuItem
              component={NavLink}
              to={CERTIFICATES_PAGE_URL}
              activeClassName={classes.selected}
              className={classes.menuItem}
              onClick={handleMenuClick}
            >
              <ListItemIcon>
                <CardMembershipIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText primary='Certificates' classes={{ primary: classes.primary }} />
            </MenuItem>
          </Tooltip>
        </IsPermitted>

        <IsPermitted action={FETCH_CALENDAR_PREFERENCE}>
          <Tooltip title={openSidebar ? '' : 'Program Calendar'}>
            <MenuItem
              component={NavLink}
              to={stringifyCalendarLink()}
              activeClassName={classes.selected}
              className={classes.menuItem}
              onClick={handleMenuClick}
            >
              <ListItemIcon>
                <TodayIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText primary='Program Calendar' classes={{ primary: classes.primary }} />
            </MenuItem>
          </Tooltip>
        </IsPermitted>

        {canViewAnalytics && (
          <>
            <Tooltip title={openSidebar ? '' : 'Analytics'}>
              <MenuItem
                component={NavLink}
                to={ANALYTICS_PAGE_URL}
                activeClassName={classes.selected}
                className={classes.menuItem}
                onClick={handleMenuClick}
              >
                <ListItemIcon>
                  <AnalyticsIcon className={classes.listIcon} />
                </ListItemIcon>
                <ListItemText primary='Analytics' classes={{ primary: classes.primary }} />
              </MenuItem>
            </Tooltip>
            <Tooltip title={openSidebar ? '' : 'Data Extract'}>
              <MenuItem
                component={NavLink}
                to={DATA_EXTRACT_PAGE_URL}
                activeClassName={classes.selected}
                className={classes.menuItem}
                onClick={handleMenuClick}
              >
                <ListItemIcon>
                  <DescriptionOutlinedIcon className={classes.listIcon} />
                </ListItemIcon>
                <ListItemText primary='Data Extract' classes={{ primary: classes.primary }} />
              </MenuItem>
            </Tooltip>
          </>
        )}

        <Tooltip title={openSidebar ? '' : 'Data Analysis & Assessment Criteria'}>
          <MenuItem
            component={ExternalLink}
            to={DATA_ANALYSIS_ASSESSMENT_CRITERIA_URL}
            className={classnames(classes.menuItem, {[classes.menuItemTextWrap]: Boolean(openSidebar)})}
            onClick={handleMenuClick}
          >
            <ListItemIcon>
              <PlaylistAddCheck className={classes.listIcon} />
            </ListItemIcon>
            <ListItemText
              primary='Data Analysis & Assessment Criteria'
              classes={{ primary: classes.primary }}
            />
          </MenuItem>
        </Tooltip>
      </>
    )
  }

  render() {
    const { classes, openSidebar, onDrawerToggle } = this.props

    return (
      <>
        <Hidden lgUp>
          <Drawer
            variant='temporary'
            open={openSidebar}
            onClose={onDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {this.drawer({ collapsed: true })}
          </Drawer>
        </Hidden>
        <Hidden lgDown implementation='css'>
          <Drawer
            variant='permanent'
            className={classnames({
              [classes.drawerOpen]: openSidebar,
              [classes.drawerClose]: !openSidebar,
            })}
            classes={{
              paper: classnames(classes.drawerPaper, {
                [classes.drawerOpen]: openSidebar,
                [classes.drawerClose]: !openSidebar,
              }),
            }}
          >
            {this.drawer({ collapsed: false })}
          </Drawer>
        </Hidden>
      </>
    )
  }
}

export default withStyles(styles)(SideBar)
