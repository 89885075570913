// @flow
import React from 'react'
import TourTemplate from '../index'

const feature = 'userPreference'

const steps = [
  {
    name: 'notificationConfig',
    target: '#notification-config',
    title: 'Configure Email Notification Preferences',
    content: "You can set the frequency at which you receive email notifications by clicking the 'Notification Preferences' link in the menu.",
    placement: 'bottom'
  }
]

const PreferenceTour = () => (
  <TourTemplate
    steps={steps}
    feature={feature}
  />
)

export default PreferenceTour
