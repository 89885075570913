// @flow
import { ROUND_ENROLMENTS_OVERVIEW_TAB_STATES } from './reducer'

export const rewriteFilter = (originalFilter: { [string]: string }) => {
  if(JSON.stringify(originalFilter.status) !== JSON.stringify(ROUND_ENROLMENTS_OVERVIEW_TAB_STATES.late)) {
    return originalFilter
  }

  const newFilter = {...originalFilter}

  delete newFilter.status
  newFilter.lateSubmission = true

  return newFilter
}
