// @flow
import * as actions from './actions'
import { success } from 'utilities/actions'

import type { GlobalNotification } from 'types'

export type State = {
  data?: GlobalNotification
}

type Action = {
  type: string,
  globalNotification?: GlobalNotification
}

export const initialState = {}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }

  switch (action.type) {
    case success(actions.API_GLOBAL_NOTIFICATION_INDEX):
      const { globalNotification } = action
      return { ...state, data: globalNotification }
    default:
      return state
  }
}

export default reducer
