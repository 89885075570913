// @flow
import { success, failure } from 'utilities/actions'
import { put, takeLatest, all, fork, select } from 'redux-saga/effects'
import * as actions from './actions'

import { API_URL, RESULT_ENTRY_STATS } from 'qap/constants'
import { requestActions } from 'state/request'

import { buildPath } from 'utilities/apiQueryString'

export function * fetch (action: { type: string }) : Generator<any, any, any> {
  const { filter } = yield select(state => state.roundEnrolmentsOverview)
  const url = buildPath({
    path: `${API_URL}/${RESULT_ENTRY_STATS}`,
    queryParams: { filter }
  })

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: success(actions.API_ROUND_ENROLMENTS_COUNTS_INDEX),
    failureAction: failure(actions.API_ROUND_ENROLMENTS_COUNTS_INDEX)
  })
}

function * watchFetch () : Generator<any, any, any> {
  yield takeLatest([
    actions.API_ROUND_ENROLMENTS_COUNTS_SET_ALL_FILTERS
  ], fetch)
}

export default function * roundEnrolmentsOverviewSagas () : Generator<any, any, any> {
  yield all([
    fork(watchFetch)
  ])
}
