// @flow
export const FETCH_CALENDAR_PREFERENCE = 'API_CALENDAR_PREFERENCES_SHOW'
export const FETCH_CALENDAR_PREFERENCE_SUCCESS = 'API_CALENDAR_PREFERENCES_SHOW_SUCCESS'
export const FETCH_CALENDAR_PREFERENCE_FAILURE = 'API_CALENDAR_PREFERENCES_SHOW_FAILURE'
export const UPDATE_CALENDAR_PREFERENCE = 'UPDATE_CALENDAR_PREFERENCE'
export const SAVE_CALENDAR_PREFERENCE = 'API_CALENDAR_PREFERENCES_UPDATE'
export const SAVE_CALENDAR_PREFERENCE_SUCCESS = 'API_CALENDAR_PREFERENCES_UPDATE_SUCCESS'
export const SAVE_CALENDAR_PREFERENCE_FAILURE = 'API_CALENDAR_PREFERENCES_UPDATE_FAILURE'
export const SET_CALENDAR_DIALOG_STATUS = 'SET_CALENDAR_DIALOG_STATUS'
export const SET_CSV_EXPORT_DIALOG_STATUS = 'SET_CSV_EXPORT_DIALOG_STATUS'

export const fetchCalendarPreferences = () => ({
  type: FETCH_CALENDAR_PREFERENCE
})

export const updateCalendarPreferences = () => ({
  type: UPDATE_CALENDAR_PREFERENCE
})

export const saveCalendarPreferences = (preferences: Array<string>) => ({
  type: SAVE_CALENDAR_PREFERENCE,
  preferences
})

export const setCalendarDialogStatus = (value: boolean) => ({
  type: SET_CALENDAR_DIALOG_STATUS,
  value
})

export const setCsvExportDialogStatus = (value: boolean) => ({
  type: SET_CSV_EXPORT_DIALOG_STATUS,
  value
})
