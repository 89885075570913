// @flow
import type { InternalSubmissionPayload } from 'types'

export const CLEAR_ROUND_ENROLMENT_DATA = 'CLEAR_ROUND_ENROLMENT_DATA'
export const ACCUMULATE_UNSAVED_RESULTS = 'ACCUMULATE_UNSAVED_RESULTS'
export const RESTORE_UNSAVED_RESULTS = 'RESTORE_UNSAVED_RESULTS'
export const START_UPDATING_RESULTS = 'START_UPDATING_RESULTS'
export const API_ROUND_ENROLMENTS_SHOW = 'API_ROUND_ENROLMENTS_SHOW'
export const API_ROUND_ENROLMENTS_RESULTS_UPDATE = 'API_ROUND_ENROLMENTS_RESULTS_UPDATE'
export const API_ROUND_ENROLMENTS_PREPARE_RESULTS = 'API_ROUND_ENROLMENTS_PREPARE_RESULTS'
export const API_ROUND_ENROLMENTS_RESULTS_SUBMIT = 'API_ROUND_ENROLMENTS_RESULTS_SUBMIT'
export const CLEAR_UNSAVED_RESULTS = 'CLEAR_UNSAVED_RESULTS'
export const API_ROUND_ENROLMENT_CONFIG_UPDATE = 'API_ROUND_ENROLMENT_CONFIG_UPDATE'
export const API_ROUND_ENROLMENTS_INTERNAL_SUBMISSIONS_CREATE = 'API_ROUND_ENROLMENTS_INTERNAL_SUBMISSIONS_CREATE'
export const API_ROUND_ENROLMENTS_SUBMISSIONS_INDEX = 'API_ROUND_ENROLMENTS_SUBMISSIONS_INDEX'
export const API_SURVEY_ROUND_SUBMISSIONS_SHOW = 'API_SURVEY_ROUND_SUBMISSIONS_SHOW'
export const API_ROUND_ENROLMENTS_RELATED_PROGRAMS = 'API_ROUND_ENROLMENTS_RELATED_PROGRAMS'

export const clear = () => ({
  type: CLEAR_ROUND_ENROLMENT_DATA
})

export const fetch = (id: number) => ({
  type: API_ROUND_ENROLMENTS_SHOW,
  id
})

export const fetchSubmissions = (id: number) => ({
  type: API_ROUND_ENROLMENTS_SUBMISSIONS_INDEX,
  id
})

export const fetchSubmission = (id: number) => ({
  type: API_SURVEY_ROUND_SUBMISSIONS_SHOW,
  id
})

export const updateResults = (id: string, resultId?: string, values: Object, meta?: Object = {}) => ({
  type: API_ROUND_ENROLMENTS_RESULTS_UPDATE,
  id,
  resultId,
  values,
  meta
})

export const prepareResults = (id: string) => ({
  type: API_ROUND_ENROLMENTS_PREPARE_RESULTS,
  id
})

export const submitResults = (id: string) => ({
  type: API_ROUND_ENROLMENTS_RESULTS_SUBMIT,
  id
})

export const internalSubmission = (id: string, payload: InternalSubmissionPayload) => {
  return ({
    type: API_ROUND_ENROLMENTS_INTERNAL_SUBMISSIONS_CREATE,
    id,
    payload
  })
}

export const updateConfig = (id: string, payload: Array<any>) => ({
  type: API_ROUND_ENROLMENT_CONFIG_UPDATE,
  id,
  payload
})

export const fetchRelatedPrograms = (programId: string, surveyNumber: string) => ({
  type: API_ROUND_ENROLMENTS_RELATED_PROGRAMS,
  programId,
  surveyNumber
})
