// @flow

// Retry if lazy loading fails due to poor internet connection
// https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
export const retry = (fn: Function, retriesLeft: number = 3, interval: number = 500) => (
  new Promise<any>((resolve, reject) =>
    fn()
      .then(resolve)
      .catch((error) =>
        setTimeout(() => {
          // reject when maximum tries have been exceeded
          if (retriesLeft === 1) reject(error)

          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      )
  )
)
