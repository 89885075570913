// @flow
import * as actions from './actions'
import { success, failure } from 'utilities/actions'

export type State = {
  data: {
    notice?: string
  },
  submitting: boolean,
  errors: Object[]
}

type Action = {
  type: string,
  data?: Object,
  errors?: Object[]
}

const initialState = {
  data: {
    notice: ''
  },
  submitting: false,
  errors: []
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }
  const { data, errors } = action
  switch (action.type) {
    case success(actions.API_DATA_EXTRACT_REQUEST_CREATE):
      return { ...state, data, submitting: false }
    case failure(actions.API_DATA_EXTRACT_REQUEST_CREATE):
      return { ...state, errors, submitting: false }
    case actions.API_DATA_EXTRACT_REQUEST_CREATE:
      return { ...state, submitting: true }
    case actions.CLEAR_NOTICE:
      return { ...initialState }
    default:
      return state
  }
}

export default reducer
