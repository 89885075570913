// @flow
import reducer from './reducer'

import * as generalPreferenceActions from './actions'
import generalPreferenceSagas from './sagas'

export {
  generalPreferenceActions,
  generalPreferenceSagas
}

export default reducer
