import qs from 'qs'
import { camelizeKeys, decamelizeKeys } from 'humps'

const commaJoinValues = (object) => {
  const result = {}
  Object.keys(object).forEach(key => {
    const value = object[key]
    result[key] = Array.isArray(value) ? value.join(',') : value
  })
  return result
}

const commaSplitValues = (object) => {
  const result = {}
  Object.keys(object).forEach(key => {
    result[key] = object[key].split(',')
  })
  return result
}

const numericiseValues = (object) => {
  const result = {}
  Object.keys(object).forEach(key => {
    result[key] = parseInt(object[key], 10)
  })
  return result
}

export const stringify = (query) => {
  query = decamelizeKeys(query || {})
  return qs.stringify({
    ...query,
    filter: query.filter && commaJoinValues(query.filter)
  })
}

export const parse = (queryString) => {
  const query = camelizeKeys(qs.parse(queryString))
  if (query.filter) {
    query.filter = commaSplitValues(query.filter)
  }
  if (query.page) {
    query.page = numericiseValues(query.page)
  }
  return query
}

export const setParams = (params: ?Object) => qs.stringify(
  params,
  { addQueryPrefix: true }
)

export const buildPath = ({ path, queryParams }: { path: string, queryParams: { [string]: Object } }) => {
  if(!path) {
    return ''
  }

  return queryParams ? `${path}?${stringify(queryParams)}` : path
}
