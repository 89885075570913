// @flow
import { put, takeLatest, all, fork } from 'redux-saga/effects'
import { decamelizeKeys } from 'humps'

import * as actions from './actions'
import { requestActions } from 'state/request'
import { API_URL, EMAIL_NOTIFICATION_PREFERENCE_ENDPOINT } from 'qap/constants'

import type { FetchNotificationPreference, SaveNotificationPreferences } from './type'

export function * fetchNotificationPreferences (action: FetchNotificationPreference) : Generator<any, any, any> {
  const url = `${API_URL}/${EMAIL_NOTIFICATION_PREFERENCE_ENDPOINT}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: actions.FETCH_NOTIFICATION_PREFERENCE_SUCCESS,
    failureAction: actions.FETCH_NOTIFICATION_PREFERENCE_FAILURE
  })
}

export function * saveNotificationPreferences (action: SaveNotificationPreferences): Generator<any, any, any> {
  const { preferences } = action
  const url = `${API_URL}/${EMAIL_NOTIFICATION_PREFERENCE_ENDPOINT}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'PUT',
    url,
    body: { data: decamelizeKeys({ preferences }) },
    successAction: actions.SAVE_NOTIFICATION_PREFERENCE_SUCCESS,
    failureAction: actions.SAVE_NOTIFICATION_PREFERENCE_FAILURE
  })
}

function * watchFetchNotificationPreferences () : Generator<any, any, any> {
  yield takeLatest(actions.FETCH_NOTIFICATION_PREFERENCE, fetchNotificationPreferences)
}

function * watchNotificationPreferencesSave () : Generator<any, any, any> {
  yield takeLatest(actions.SAVE_NOTIFICATION_PREFERENCE, saveNotificationPreferences)
}

export default function * notificationPreferenceSagas () : Generator<any, any, any> {
  yield all([
    fork(watchFetchNotificationPreferences),
    fork(watchNotificationPreferencesSave)
  ])
}
