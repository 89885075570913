// @flow
import { put, takeLatest, all, fork } from 'redux-saga/effects'

import * as actions from './actions'
import * as APIActions from '../api/actions'
import { featureTourActions } from 'state/featureTours'
import { passwordResetActions } from 'state/passwordReset'
import { signupActions } from 'state/signup'
import history from 'state/history'
import { setParams } from 'utilities/apiQueryString'
import { requestActions } from 'state/request'
import { success, failure } from 'utilities/actions'
import getRedirect from 'utilities/getRedirect'

import {
  API_URL,
  LOGIN_URL,
  LANDING_PAGE_URL,
  ACCEPT_COOKIES_ENDPOINT
} from 'qap/constants'

function * expireSession (action) : Generator<any, any, any> {
  const { location } = action

  yield put(actions.logoutUser())
  yield window.history.pushState({
    state: {
      redirect: getRedirect(location || window.location),
      message: 'Your session has expired. Please login again.'
    }
  }, 'Login', LOGIN_URL)

  // Hard refresh to potentially to avoid having a stale version of the app
  yield window.location = LOGIN_URL
}

function * fetchApiSpecification (action) : Generator<any, any, any> {
  yield put(APIActions.fetchApiSpecification())
}

function * onAuthenticated (action) : Generator<any, any, any> {
  yield fetchApiSpecification(action)
  yield put(featureTourActions.fetchFeatureTours())
}

function * onJwtAuthenticated (action) : Generator<any, any, any> {
  const { data: { secureId } = {}, redirect, notification } = action

  if (secureId) {
    history.replace(`/create-account/${secureId}${setParams({ redirect, notification })}`)
  } else {
    yield put({
      ...action,
      type: success(actions.API_SESSION_PASSWORD_AUTH_CREATE)
    })

    history.replace(redirect || LANDING_PAGE_URL)
  }
}

function * watchAuthSuccess () : Generator<any, any, any> {
  yield takeLatest([
    success(actions.API_SESSION_PASSWORD_AUTH_CREATE),
    success(signupActions.API_SIGNUPS_CREATE),
    success(passwordResetActions.API_PASSWORD_RESETS_RESETS_CREATE)
  ], onAuthenticated)
  yield takeLatest([
    success(actions.API_SESSION_JWT_AUTH_CREATE)
  ], onJwtAuthenticated)
  yield takeLatest([
    actions.AUTH_EXPIRE_SESSION
  ], expireSession)
}

function * acceptCookies (action) : Generator<any, any, any> {
  const { acceptingCookies } = action

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'POST',
    url: `${API_URL}${ACCEPT_COOKIES_ENDPOINT}`,
    body: { data: { accepting_cookies: acceptingCookies } },
    successAction: success(actions.API_SESSION_ACCEPT_COOKIES_CREATE),
    failureAction: failure(actions.API_SESSION_ACCEPT_COOKIES_CREATE)
  })
}

export default function * authSagas () : Generator<any, any, any> {
  yield all([
    fork(watchAuthSuccess),
    takeLatest(actions.API_SESSION_ACCEPT_COOKIES_CREATE, acceptCookies)
  ])
}
