// @flow
import * as actions from './actions'
import { success, failure } from 'utilities/actions'

export type State = {
  loginUrl: string,
  errors: Object[],
  meta: Object
}

type Action = {
  type: string,
  data?: Object,
  errors?: Object[],
  meta?: Object
}

const initialState = {
  loginUrl: '',
  errors: [],
  meta: {}
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }
  switch (action.type) {
    case success(actions.API_SALESFORCE_PORTAL_JWT_INDEX):
      return { ...state, loginUrl: action.data }
    case failure(actions.API_SALESFORCE_PORTAL_JWT_INDEX):
      return {
        ...state,
        errors: [
          {title: 'Invalid client'}
        ],
      }
    default:
      return state
  }
}

export default reducer
