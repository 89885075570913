// @flow
import React from 'react'
import { IconButton, Typography } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import CloseIcon from '@mui/icons-material/Close'

import QAPVars from 'qap/vars'
import type { TooltipRenderProps } from 'react-joyride'

type Props = {
  classes: Object
} & TooltipRenderProps

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    fontFamily: theme.typography.fontFamily,
    flexDirection: 'column',
    backgroundColor: QAPVars.white,
    borderRadius: 0,
    boxSizing: 'border-box',
    width: 380,
    maxWidth: '100%',
    padding: '16px 24px'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row'
  },
  footerStep: {
    flexGrow: 1,
    fontSize: 14,
    color: QAPVars.hintGray
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  nextButton: {
    cursor: 'pointer',
    fontSize: 14,
    textTransform: 'uppercase',
    color: QAPVars.pRed
  },
  backButton: {
    cursor: 'pointer',
    fontSize: 14,
    textTransform: 'uppercase',
    color: QAPVars.hintGray,
    paddingRight: 16
  },
  title: {
    display: 'flex',
    paddingBottom: 16
  },
  titleText: {
    fontSize: 16,
    color: QAPVars.sBlue
  },
  content: {
    paddingBottom: 24
  }
})

const TooltipTitle = ({ title, classes }) => (
  <div className={classes.title}>
    <Typography variant='body1' className={classes.titleText}>
      {title}
    </Typography>
  </div>
)

const TooltipContent = ({ content, classes }) => (
  <div className={classes.content}>
    {content}
  </div>
)

const TooltipBackButton = (props: Props) => (
  props.index > 0
    ? <div
      onClick={props.backProps.onClick}
      className={props.classes.backButton}>
        Back
    </div>
    : null
)

const TooltipNextButton = (props: Props) => (
  <div
    onClick={props.primaryProps.onClick}
    className={props.classes.nextButton}
  >
    {props.isLastStep ? 'Done' : 'Next'}
  </div>
)

const TooltipFooter = (props: Props) => {
  return (
    <div className={props.classes.footer}>
      <div className={props.classes.footerStep}>
        {props.index + 1}/{props.size}
      </div>
      <TooltipBackButton {...props} />
      <TooltipNextButton {...props} />
    </div>
  )
}

const TourTooltip = (props: Props) => {
  const { classes, tooltipProps, skipProps, step } = props

  return (
    <div className={classes.root} {...tooltipProps}>
      <IconButton onClick={skipProps.onClick} className={classes.closeButton} size="large">
        <CloseIcon />
      </IconButton>
      {step.title && <TooltipTitle {...step} classes={classes} />}
      {step.content && <TooltipContent {...step} classes={classes} />}
      <TooltipFooter {...props} />
    </div>
  )
}

export default withStyles(styles)(TourTooltip)
