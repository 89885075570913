// @flow
// https://github.com/d3/d3-scale-chromatic#schemePaired
import { schemePaired } from 'd3-scale-chromatic'

// eslint-disable-next-line
export default {
  result: {
    primary: schemePaired[1],
    secondary: schemePaired[0]
  },
  grayscale: {
    primary: '#bdbdbd',
    secondary: '#cacaca'
  },
  method: {
    primary: schemePaired[3],
    secondary: schemePaired[2]
  },
  participantResult: {
    primary: schemePaired[5],
    secondary: schemePaired[4],
    unselected: '#2D373E'
  },
  filtered: {
    primary: schemePaired[9],
    secondary: schemePaired[8]
  }
}
