// @flow
export const UPLOAD_REQUEST = 'UPLOAD_REQUEST'
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS'
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS'
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE'

export const uploadRequest = (url: string, file: window.File) => ({
  type: UPLOAD_REQUEST,
  url,
  file
})

export const uploadProgress = (url: string, progress: number) => ({
  type: UPLOAD_PROGRESS,
  url,
  progress
})

export const uploadSuccess = (url: string) => ({
  type: UPLOAD_SUCCESS,
  url
})

export const uploadFailure = (url: string, error: Error) => ({
  type: UPLOAD_FAILURE,
  url,
  error
})
