// @flow
import {
  DOC_TYPES,
  CERTIFICATES_ENDPOINT,
  CERTIFICATES_PAGE_URL,
  CERTIFICATES_NAV_PATH,
  REPORTS_ENDPOINT,
  REPORTS_NAV_PATH,
  REPORTS_PAGE_URL,
  REPORT_DOWNLOAD_ENDPOINT,
  CERTIFICATE_DOWNLOAD_ENDPOINT,
  API_URL
} from 'qap/constants'

export const getEndpointByDocType = (docType?: string) => (
  docType === DOC_TYPES.certificate ? CERTIFICATES_ENDPOINT : REPORTS_ENDPOINT
)

export const getReportNavPath = (docType: string) => (
  docType === DOC_TYPES.certificate ? CERTIFICATES_NAV_PATH : REPORTS_NAV_PATH
)

export const getDocPageUrl = (docType: string = DOC_TYPES.report) => (
  docType === DOC_TYPES.certificate ? CERTIFICATES_PAGE_URL : REPORTS_PAGE_URL
)

export const getDocCsvUrlByDocType = (docType: string) => {
  let path = REPORT_DOWNLOAD_ENDPOINT

  if (docType === DOC_TYPES.certificate) {
    path = CERTIFICATE_DOWNLOAD_ENDPOINT
  }

  return `${API_URL}/${path}`
}

export const isCertificate = (docType: string) => (
  docType === DOC_TYPES.certificate
)

export const isReport = (docType: string) => (
  !isCertificate(docType)
)
