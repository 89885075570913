// @flow
import { put, takeLatest } from 'redux-saga/effects'

import * as actions from './actions'
import { API_URL, ORGANISATIONS_ENDPOINT } from 'qap/constants'
import { success, failure } from 'utilities/actions'
import { requestActions } from 'state/request'

function * fetchMemberships (action) : Generator<any, any, any> {
  const url = `${API_URL}/${ORGANISATIONS_ENDPOINT}/${action.organisationId}/memberships`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: success(actions.API_ORGANISATIONS_MEMBERSHIPS_INDEX),
    failureAction: failure(actions.API_ORGANISATIONS_MEMBERSHIPS_INDEX)
  })
}

export default function * membershipsSagas () : Generator<any, any, any> {
  yield takeLatest(actions.API_ORGANISATIONS_MEMBERSHIPS_INDEX, fetchMemberships)
}
