// @flow
import { put, takeLatest } from 'redux-saga/effects'

import * as actions from './actions'
import { API_URL, REPORTS_ENDPOINT } from 'qap/constants'
import { success, failure } from 'utilities/actions'
import { requestActions } from 'state/request'

function * fetchReportAnalyticsDetails (action) : Generator<any, any, any> {
  const url = `${API_URL}/${REPORTS_ENDPOINT}/${action.reportId}/analytics_details`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: success(actions.API_REPORTS_ANALYTICS_DETAILS_INDEX),
    failureAction: failure(actions.API_REPORTS_ANALYTICS_DETAILS_INDEX)
  })
}

export default function * reportAnalyticsDetailsSagas () : Generator<any, any, any> {
  yield takeLatest(actions.API_REPORTS_ANALYTICS_DETAILS_INDEX, fetchReportAnalyticsDetails)
}
