// @flow
export const API_ROUND_ENROLMENTS_INDEX = 'API_ROUND_ENROLMENTS_INDEX'
export const API_ROUND_ENROLMENTS_SET_ALL_FILTERS = 'API_ROUND_ENROLMENTS_SET_ALL_FILTERS'

export const API_ROUND_ENROLMENTS_FACETS_INDEX = 'API_ROUND_ENROLMENTS_FACETS_INDEX'
export const API_ROUND_ENROLMENTS_FACETS_INDEX_SUCCESS = 'API_ROUND_ENROLMENTS_FACETS_INDEX_SUCCESS'
export const API_ROUND_ENROLMENTS_FACETS_INDEX_FAILURE = 'API_ROUND_ENROLMENTS_FACETS_INDEX_FAILURE'

export const fetch = () => ({
  type: API_ROUND_ENROLMENTS_INDEX
})

export const setAllFilters = (filters: Object) => ({
  type: API_ROUND_ENROLMENTS_SET_ALL_FILTERS,
  ...filters
})

export const fetchRoundEnrolmentFacets = () => ({
  type: API_ROUND_ENROLMENTS_FACETS_INDEX
})
