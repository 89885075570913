// @flow
import reducer from './reducer'
import type { State } from './reducer'

import * as globalNotificationActions from './actions'
import globalNotificationSagas from './sagas'

export {
  globalNotificationActions,
  globalNotificationSagas
}

export type GlobalNotificationState = State

export default reducer
