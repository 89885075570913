// @flow
import React from 'react'
import { Typography } from '@mui/material'
import { Alert, AlertTitle } from '@mui/material'

type Props = {
  classes?: Object,
  title?: string,
  text?: string,
  severity?: string,
}

const InPageAlert = ({ classes = {}, title = 'Errors', text = '', severity = 'error', ...others }: Props) => {
  return (
    <Alert severity={severity} className={classes.alertRoot} {...others}>
      <AlertTitle>
        <Typography className={classes.alertTitleText}>{title}</Typography>
      </AlertTitle>
      <Typography>{text}</Typography>
    </Alert>
  )
}

export default InPageAlert
