import React from 'react'

import QAPVars from 'qap/vars'

const poweredBy = (
  <React.Fragment>
    <path d='M0.7,0.8 L4.5,0.8 C6.8,0.8 8.4,2 8.4,4 C8.4,6.2 6.5,7.3 4.3,7.3 L1.5,7.3 L1.5,11.2 L0.7,11.2 L0.7,0.8 Z M4.4,6.6 C6.4,6.6 7.7,5.6 7.7,4 C7.7,2.3 6.4,1.5 4.5,1.5 L1.5,1.5 L1.5,6.6 L4.4,6.6 Z' />
    <path d='M10,7.5 C10,5.3 11.7,3.5 13.9,3.5 C16.2,3.5 17.8,5.3 17.8,7.5 C17.8,9.6 16.1,11.5 13.9,11.5 C11.6,11.4 10,9.6 10,7.5 Z M17.1,7.5 C17.1,5.6 15.7,4.2 14,4.2 C12.2,4.2 10.9,5.7 10.9,7.5 C10.9,9.3 12.3,10.8 14,10.8 C15.7,10.7 17.1,9.3 17.1,7.5 Z' />
    <polygon points='19.1 3.6 19.9 3.6 22.2 10.3 24.5 3.5 25.1 3.5 27.4 10.3 29.7 3.6 30.5 3.6 27.7 11.3 27.1 11.3 24.8 4.7 22.5 11.3 21.9 11.3' />
    <path d='M31.8,7.4 C31.8,5.2 33.4,3.4 35.4,3.4 C37.5,3.4 38.9,5.1 38.9,7.4 C38.9,7.5 38.9,7.6 38.9,7.7 L32.6,7.7 C32.7,9.6 34.1,10.7 35.6,10.7 C36.8,10.7 37.6,10.2 38.2,9.5 L38.7,10 C37.9,10.8 37,11.4 35.5,11.4 C33.5,11.4 31.8,9.8 31.8,7.4 Z M38.1,7.1 C38,5.6 37.1,4.1 35.4,4.1 C33.9,4.1 32.7,5.4 32.6,7.1 L38.1,7.1 Z' />
    <path d='M41,3.6 L41.7,3.6 L41.7,5.8 C42.3,4.4 43.6,3.4 45.1,3.5 L45.1,4.3 L45,4.3 C43.2,4.3 41.7,5.6 41.7,8.1 L41.7,11.2 L41,11.2 L41,3.6 Z' />
    <path d='M46.1,7.4 C46.1,5.2 47.7,3.4 49.7,3.4 C51.8,3.4 53.2,5.1 53.2,7.4 C53.2,7.5 53.2,7.6 53.2,7.7 L46.9,7.7 C47,9.6 48.4,10.7 49.9,10.7 C51.1,10.7 51.9,10.2 52.5,9.5 L53,10 C52.2,10.8 51.3,11.4 49.8,11.4 C47.9,11.4 46.1,9.8 46.1,7.4 Z M52.5,7.1 C52.4,5.6 51.5,4.1 49.8,4.1 C48.3,4.1 47.1,5.4 47,7.1 L52.5,7.1 Z' />
    <path d='M54.9,7.5 C54.9,5 56.8,3.5 58.6,3.5 C60.1,3.5 61.1,4.4 61.7,5.3 L61.7,0.4 L62.4,0.4 L62.4,11.3 L61.7,11.3 L61.7,9.6 C61,10.6 60.1,11.5 58.6,11.5 C56.8,11.4 54.9,9.9 54.9,7.5 Z M61.8,7.4 C61.8,5.4 60.3,4.1 58.7,4.1 C57,4.1 55.7,5.3 55.7,7.4 C55.7,9.4 57.1,10.7 58.7,10.7 C60.3,10.7 61.8,9.4 61.8,7.4 Z' />
    <path d='M70.6,9.6 L70.6,11.3 L69.9,11.3 L69.9,0.4 L70.6,0.4 L70.6,5.4 C71.3,4.4 72.2,3.5 73.7,3.5 C75.5,3.5 77.4,5 77.4,7.5 C77.4,10 75.5,11.5 73.7,11.5 C72.2,11.4 71.2,10.6 70.6,9.6 Z M76.6,7.5 C76.6,5.5 75.2,4.2 73.6,4.2 C72,4.2 70.5,5.5 70.5,7.5 C70.5,9.5 72,10.8 73.6,10.8 C75.3,10.7 76.6,9.5 76.6,7.5 Z' />
    <path d='M85.3,3.6 L86.1,3.6 L82.8,11.5 C82.1,13.1 81.3,13.7 80.2,13.7 C79.6,13.7 79.2,13.6 78.7,13.4 L79,12.8 C79.4,13 79.7,13.1 80.3,13.1 C81.1,13.1 81.6,12.6 82.2,11.3 L78.5,3.7 L79.4,3.7 L82.6,10.5 L85.3,3.6 Z' />
  </React.Fragment>
)

const RCPAQAP = (
  <React.Fragment>
    <path d='M7,20.9 C7,23 7.9,23.6 9.8,23.6 L10.6,23.6 L10.6,24.3 L0.6,24.3 L0.6,23.6 L1.2,23.6 C3.3,23.6 4,23 4,20.7 L4,5.8 C4,3.6 3.4,3 1.3,3 L0.5,3 L0.5,2.3 C1.4,2.3 3.5,2.2 5,2.1 C6.5,2 7.2,2 9.5,2 C16.1,2 19,4.7 19,8.3 C19,11 17.2,13.2 14.3,14 C15.6,15 16.7,16 18.3,17.7 L21.1,20.5 C23.2,22.6 25.4,23.9 28.1,24.1 L28.1,24.7 C21.9,25 20.3,23.6 17.1,20.5 L14.8,18.2 C13.2,16.6 12,15.6 11.1,15 C10.2,15 9.6,15.1 8.7,15.1 C7.9,15.1 7.5,15.1 6.9,15 L7,20.9 Z M7,14.3 C7.9,14.4 8.4,14.4 9.2,14.4 C13.1,14.4 15.7,12.7 15.7,8.5 C15.7,5.2 14.2,2.6 8.8,2.6 C8.1,2.6 7.7,2.6 7,2.7 C7,2.7 7,14.3 7,14.3 Z' fill={QAPVars.rcpaRed} />
    <path d='M47.3,8 C46.1,4.7 43.1,2.5 39.2,2.5 C33.3,2.5 29.6,6.8 29.6,12.7 C29.6,19.1 34.2,23.8 39.6,23.8 C42.4,23.8 45.8,22.8 48.2,18.6 L48.9,18.6 C48.4,20.2 47.5,22.4 46.6,23.8 C44.3,23.5 42.8,24.8 38.6,24.8 C31.4,24.8 26.2,20 26.2,13.5 C26.2,6.6 31.5,1.7 38.9,1.7 C43.4,1.7 45.3,3.1 46.4,3.1 C46.8,3.1 47,3 47,2.9 L47.5,2.9 L47.8,8 L47.3,8 Z' fill={QAPVars.rcpaRed} />
    <path d='M57,20.8 C57,22.8 57.6,23.5 59.8,23.5 L61,23.5 L61,24.2 L51.2,24.2 L51.2,23.5 L51.7,23.5 C53.5,23.5 54.1,23.1 54.1,21.4 L54.1,4.6 C54.1,3.3 53.7,2.7 52.4,2.7 L50.6,2.7 L50.6,2 C51.6,2 53.3,1.9 55.1,1.8 C56.8,1.7 58.5,1.7 59.5,1.7 C66.9,1.7 68.6,5.2 68.6,8 C68.6,12.1 65.3,14.8 60,14.8 C58.9,14.8 58,14.7 57,14.5 L57,20.8 Z M57,14 C57.5,14.1 58.2,14.3 58.9,14.3 C62.8,14.3 65.2,11.7 65.2,8.1 C65.2,5.3 64.1,2.6 59.4,2.6 C58.6,2.6 57.8,2.6 57,2.7 L57,14 Z' fill={QAPVars.rcpaRed} />
    <path d='M73.8,14.5 L71.7,20.1 C71.5,20.8 71,21.8 71,22.3 C71,23.3 71.9,23.6 73.9,23.6 L74.5,23.6 L74.5,24.3 L66.3,24.3 L66.3,23.6 C68.6,23.6 69,23.2 70.1,20.1 L76.6,2.5 L76.3,1.7 L79.4,1.7 L86.8,21.4 C87.5,23.3 88.3,23.6 90,23.6 L90,24.3 L80.2,24.3 L80.2,23.6 L81.1,23.6 C83.1,23.6 83.9,23.4 83.9,22.4 C83.9,21.8 83.7,21.3 83.5,20.9 L81.1,14.4 L73.8,14.4 L73.8,14.5 Z M77.4,4.6 L74.4,12.9 L80.5,12.9 L77.4,4.6 Z' fill={QAPVars.rcpaRed} />
    <path d='M113.3,22.8 L110.8,20.2 C112.4,18 113.3,15.4 113.3,12.6 C113.3,5.4 108.4,0.4 101.4,0.4 C98,0.4 95,1.6 92.8,3.9 C90.6,6.1 89.4,9.3 89.4,12.7 C89.4,19.9 94.3,24.9 101.3,24.9 C103.7,24.9 106,24.2 107.9,23 L108.6,23.7 C109.6,24.8 110.4,25 111,25 C112.2,25 113.1,24.2 113.3,23.6 C113.3,23.3 113.4,23 113.3,22.8 Z M101.9,13.6 L101.6,13.8 C101.3,14.1 101.1,14.4 101.1,14.9 C101.1,15.5 101.3,16.1 101.8,16.6 L105.1,20 C104,20.6 102.7,20.9 101.3,20.9 C96.8,20.9 93.7,17.5 93.7,12.5 C93.7,7.6 96.8,4.2 101.3,4.2 C105.7,4.2 108.9,7.7 108.9,12.6 C108.9,14.4 108.5,16 107.8,17.3 L104.5,13.9 C104,13.4 103.5,13.1 103,13.1 C102.6,13.1 102.2,13.3 101.9,13.6 Z' fill={QAPVars.pRed} />
    <path d='M137.4,22.9 L129.2,1.9 C128.8,0.9 128,0.5 126.4,0.5 C124.8,0.5 123.9,0.9 123.6,1.9 L115.5,22.9 C115.5,23 115.4,23.2 115.6,23.5 C115.9,24 116.9,24.5 117.8,24.5 C118.6,24.5 119.6,24.2 120.1,22.6 L122,17.6 L130.7,17.6 L132.6,22.6 C133.2,24.2 134.3,24.5 135.1,24.5 C136.1,24.5 137,23.9 137.3,23.5 C137.5,23.2 137.5,23.1 137.4,22.9 Z M129.5,14 L123.1,14 L126.3,5.6 L129.5,14 Z' fill={QAPVars.pRed} />
    <path d='M148.1,0.7 L142.2,0.7 C140.8,0.7 139.9,1.7 139.9,3.1 L139.9,19.3 C139.9,19.4 139.9,19.5 139.9,19.7 L139.9,23.7 C139.9,24.2 141.1,24.5 141.6,24.5 C142.8,24.5 144.3,23.9 144.3,21 L144.3,16.1 L148,16.1 C154.1,16.1 157.4,13.4 157.4,8.4 C157.3,3.5 153.9,0.7 148.1,0.7 Z M148,12.5 L144.2,12.5 L144.2,4.8 C144.2,4.7 144.2,4.6 144.2,4.5 L148,4.5 C151.3,4.5 152.9,5.8 152.9,8.5 C153,11.1 151.3,12.5 148,12.5 Z' fill={QAPVars.pRed} />
  </React.Fragment>
)

export const PoweredByRCPAQAPstacked = (props: { className?: string, reverse?: boolean }) => (
  <svg viewBox='0 0 158 47' className={props.className || ''}>
    {poweredBy}
    <g transform='translate(0, 22)' className={props.className || ''}>
      {RCPAQAP}
    </g>
  </svg >
)

export const PoweredByRCPAQAP = (props: { className?: string, reverse?: boolean }) => (
  <svg viewBox='0 0 255 27' className={props.className || ''}>
    <g transform='translate(0, 13)' >
      {poweredBy}
    </g>
    <g transform='translate(97, 0)' >
      {RCPAQAP}
    </g>
  </svg >
)