const warning = () => {
  const el = document.createElement('div')

  el.innerText = 'This is a locally saved version of the QAP website. Functionality may not work as expected'

  el.style.fontFamily = 'sans-serif'
  el.style.color = 'white'
  el.style.backgroundColor = 'red'
  el.style.padding = '1em'
  el.style.position = 'fixed'
  el.style.zIndex = '1000' // so sits on top of the rest of the app
  el.style.left = '0'
  el.style.right = '0'

  return el
}

export const injectWarning = () => {
  const rootElement = document && document.getElementById('app')

  rootElement && document.body.insertBefore(warning(), rootElement)
}
