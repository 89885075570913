// @flow
import * as Sentry from '@sentry/browser'
import { SENTRY_DSN } from 'qap/constants'

// Send the error stack to Sentry
// https://blog.sentry.io/2017/09/28/react-16-error-boundaries
export const noticeError = (
  error: any = new Error('Sentry - myQAP Front-end Portal'),
  extra: any = {},
) => {
  if (!SENTRY_DSN) return

  if (typeof error === 'string') Sentry.captureMessage(error)
  else if (error instanceof Error) Sentry.captureException(error, extra)
  else Sentry.captureException(new Error(error))
}

export default {
  init: () => Sentry.init({ dsn: SENTRY_DSN }),
  noticeError
}
