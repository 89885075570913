// @flow
import reducer from './reducer'
import type { State } from './reducer'

import * as programEventsActions from './actions'
import programEventsSagas from './sagas'

export {
  programEventsActions,
  programEventsSagas
}

export type ProgramEventsState = State

export default reducer
