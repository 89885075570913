// @flow
import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import CloseIcon from '@mui/icons-material/Close'
import classnames from 'classnames'

import { GlobalNotificationModalIcon } from './helpers'
import QAPVars from 'qap/vars'

type Props = {
  message: string,
  heading: string,
  notificationType: string,
  open: boolean,
  acknowledgeGlobalNotification: () => void,
  classes: Object
}

const styles = theme => ({
  whiteText: {
    color: QAPVars.white
  },
  error: {
    backgroundColor: QAPVars.sRed
  },
  info: {
    backgroundColor: QAPVars.hero
  },
  warning: {
    backgroundColor: QAPVars.warningOrange
  },
  success: {
    backgroundColor: QAPVars.sGreen
  },
  centeredFlex: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: theme.spacing(5)
  },
  justified: {
    justifyContent: 'space-between'
  },
  message: {
    '& p': {
      marginTop: 0
    },
    '& p:last-child': {
      marginBottom: 0
    },
    '& a': {
      textDecoration: 'none'
    }
  },
  h1: {
    color: QAPVars.white
  },
  paper: {
    marginLeft: 0,
    marginRight: 0
  }
})

const GlobalNotificationModal = ({
  message,
  heading,
  open,
  notificationType,
  acknowledgeGlobalNotification,
  classes
}: Props) => (
  <Dialog open={open} classes={{ paper: classes.paper }} data-testid='GlobalNotificationModal'>
    <DialogTitle
      className={
        classnames(classes.whiteText, classes[notificationType])
      }
    >
      <div className={classnames(classes.centeredFlex, classes.justified)}>
        <div className={classes.centeredFlex}>
          <GlobalNotificationModalIcon
            notificationType={notificationType}
            className={classnames(classes.icon)}
          />
          <Typography variant='h1' className={classes.h1}>
            {heading}
          </Typography>
        </div>
        <IconButton
          key='close'
          aria-label='Close'
          color='inherit'
          onClick={acknowledgeGlobalNotification}
          size="large">
          <CloseIcon />
        </IconButton>
      </div>

    </DialogTitle>
    <DialogContent className={classes[notificationType]}>
      <DialogContentText className={classes.whiteText} component='div'>
        <div
          dangerouslySetInnerHTML={{ __html: message }}
          className={classes.message}
        />
      </DialogContentText>
    </DialogContent>
  </Dialog>
)

export default withStyles(styles)(GlobalNotificationModal)
