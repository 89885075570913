import React from 'react'
import { Typography } from '@mui/material'

import withStyles from '@mui/styles/withStyles'

import { ENV_BANNER_ENABLED, ENV_BANNER } from 'qap/constants'
import QAPVars from 'qap/vars'

const styles = theme => ({
  root: {
    background: QAPVars.hero,
    textAlign: 'center',
    position: 'fixed',
    // height: 19,
    padding: '4px 0',
    top: 0,
    width: '100%',
    zIndex: theme.zIndex.appBar + 1,
    '@media print': {
      display: 'none'
    }
  },
  environment: {
    color: 'white',
    textTransform: 'uppercase'
  }
})

const EnvBanner = ({ classes }) => {
  if (!ENV_BANNER_ENABLED) return null
  return (
    <div className={classes.root}>
      <Typography variant='subtitle1' className={classes.environment}>{ ENV_BANNER }</Typography>
    </div>
  )
}

export default withStyles(styles)(EnvBanner)
