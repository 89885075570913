export const API_DATA_EXTRACT_REQUEST_CREATE = 'API_DATA_EXTRACT_REQUEST_CREATE'
export const CLEAR_NOTICE = 'CLEAR_NOTICE'

export const createDataExtractRequest = (
  programId: string,
  enrolmentYear: string,
  surveys?: string[],
  dataExtractFormat: string,
  additionalFormatOptions?: string[]
) => ({
  type: API_DATA_EXTRACT_REQUEST_CREATE,
  programId,
  enrolmentYear,
  surveys,
  dataExtractFormat,
  additionalFormatOptions
})

export const clearNotice = () => ({ type: CLEAR_NOTICE })
