// @flow
import { decamelizeKeys } from 'humps'
import { requestActions } from 'state/request'
import {
  API_URL,
  SIGNUPS_ENDPOINT,
  LANDING_PAGE_URL
} from 'qap/constants'
import { success, failure } from 'utilities/actions'

export const API_SIGNUPS_CREATE = 'API_SIGNUPS_CREATE'

type SignupData = {
  password: string,
  secureId: string
}

export const createSignup = (data: SignupData, redirect?: string) => {
  return {
    type: requestActions.UNAUTHED_REQUEST,
    method: 'POST',
    url: `${API_URL}/${SIGNUPS_ENDPOINT}`,
    successAction: success(API_SIGNUPS_CREATE),
    failureAction: failure(API_SIGNUPS_CREATE),
    body: { data: decamelizeKeys(data) },
    redirect: redirect ? `${LANDING_PAGE_URL}${redirect}` : null
  }
}
