// @flow
import React from 'react'
import { PoweredByRCPAQAP, PoweredByRCPAQAPstacked } from './poweredByRCPAQAP'

const light = '#00AEA9'
const dark = '#005289'
const other = '#008d96'
const white = '#fff'

export const myQAP = (props: { className?: string, reverse?: boolean }) => (
  <svg viewBox='0 0 691.7 190.7' className={props.className || ''}>
    <path fill={props.reverse ? white : dark} d='M118,119v32.4c0,4.7-3.8,8.6-8.6,8.6h0c-4.7,0-8.6-3.8-8.6-8.6v-31.2c0-7.9-4-12.6-11.2-12.6
    c-7.6,0-12.4,5.1-12.4,15v28.8c0,4.7-3.8,8.6-8.6,8.6h0c-4.7,0-8.6-3.8-8.6-8.6v-31.2c0-7.9-4-12.6-11.2-12.6
    c-7.3,0-12.5,5.1-12.5,15v28.8c0,4.7-3.8,8.6-8.6,8.6h0c-4.7,0-8.6-3.8-8.6-8.6v-50.9c0-3.9,3.2-7.1,7.1-7.1h3.1
    c3.9,0,7.1,3.2,7.1,7.1v0c3.7-5.6,10-8.9,18.5-8.9c8.3,0,14.4,3.5,18.1,9.6c4.1-6,10.8-9.6,19.8-9.6C108,91.5,118,102.3,118,119z'
    />
    <path fill={props.reverse ? white : dark} d='M177.3,93.4c5.9,0,10.1,5.9,8,11.5L165.3,160c-5.5,15.2-13.8,23.6-25.4,26c-5.1,1-9.8-2.9-9.8-8v-0.1
    c0-3.8,2.6-7,6.3-8c5.5-1.5,8.8-5.4,11.2-12.1l-22.3-52.6c-2.4-5.6,1.8-11.9,7.9-11.9h0c3.5,0,6.7,2.2,8,5.5l15.2,39.3l12.8-38.9
    C170.4,95.8,173.6,93.4,177.3,93.4L177.3,93.4z' />

    <path fill={props.reverse ? white : light} d='M294.9,155.9l-10.4-10.7c6.7-8.9,10.2-19.6,10.2-31.2c0-29.3-19.9-49.8-48.4-49.8c-13.8,0-26.2,5-35.1,14.1
    c-8.9,9.1-13.7,21.9-13.7,36c0,29.3,20,49.7,48.5,49.7c9.8,0,19.1-2.7,26.8-7.9l2.7,2.7c3.9,4.3,7.4,5.2,9.7,5.2
    c4.9,0,8.4-3.4,9.5-5.7C295,157.9,295.6,156.8,294.9,155.9z M248.5,118.3l-1,0.9c-1.3,1.1-2,2.6-2,4.4c-0.1,2.3,1,4.9,2.8,6.9
    l13.6,14c-4.5,2.4-9.8,3.7-15.5,3.7c-18.3,0-31-14-31-34.1c0-20,12.7-33.9,30.9-33.9c18,0,31,14.4,31,34.2
    c0,7.4-1.6,14-4.6,19.2l-13.3-13.7c-1.9-2.1-4.1-3.3-6.3-3.3C251.4,116.5,249.7,117.1,248.5,118.3z' />

    <path fill={props.reverse ? white : light} d='M390.3,156.5l-33.4-85.6c-1.6-4.1-4.8-5.7-11.5-5.7c-6.7,0-10,1.6-11.5,5.7l-33.2,85.7
    c-0.2,0.5-0.2,1.3,0.5,2.3c1.4,1.9,5.2,4,8.9,4c3.1,0,7.2-1.3,9.5-7.7l7.9-20.4h35.4l7.9,20.3c2.4,6.5,6.8,7.8,10.1,7.8
    c4.2,0,7.9-2.5,9.1-4.2C390.5,157.8,390.4,157,390.3,156.5z M357.9,120H332l13-34.1L357.9,120z' />

    <path fill={props.reverse ? white : light} d='M432.6,66.1h-24.1c-5.6,0-9.3,3.9-9.3,9.8v66c0,1.3,0,3.5,0,4.6v13c0,2.2,4.9,3.2,6.9,3.2
    c5,0,10.9-2.5,10.9-14.2v-19.8h15c25,0,38.2-10.9,38.2-31.4C470.2,77.5,456.5,66.1,432.6,66.1z M432.3,113.8H417V82.5
    c0-0.5,0-0.9-0.1-1.3h15.5c13.3,0,20.1,5.5,20.1,16.3S445.7,113.8,432.3,113.8z' />

    <linearGradient id='gradientFill' gradientUnits='userSpaceOnUse' x1='7142.6909' y1='53.2588' x2='7221.353' y2='131.0372' gradientTransform='matrix(-1 0 0 1 7777.2554 0)'>
      <stop offset='0' style={{ 'stopColor': light }} />
      <stop offset='1' style={{ 'stopColor': other }} />
    </linearGradient>

    <path fill={props.reverse ? white : 'url(#gradientFill)'} d='M540.1,64.9c8,0.9,14.3,6.5,21.5,9.7c5.1,2.2,10.4,3.4,15.8,2c4.5-1.1,8.5-3.6,12.5-5.8
    c1.4-0.8,2.9-1.6,4.4-2.4c2.9-2.2,6.5-3.5,10.2-3.5c0.9,0,1.8,0.1,2.7,0.2c0.7,0.1,1.4,0.2,2.1,0.3c4.2,1,7.8,3.8,10.1,7.4
    c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.2c0.2,0.4,0.5,0.7,0.7,1.1c0.1,0.2,0.2,0.4,0.3,0.6c0.2,0.3,0.3,0.6,0.4,1
    c0.1,0.2,0.1,0.3,0.2,0.5c0.6,1.6,1,3.3,1.1,5c0,0,0,0.1,0,0.1c0,0.2,0,0.5,0,0.7c0,0.2,0,0.4,0,0.7c0,1.3-0.1,2.6-0.4,3.9
    c-1.1,4.8-4.5,9.1-6.7,13.6c-3,6.1-5.3,12.7-4.3,19.6c0.5,3.9,2.1,7.5,4,10.9c1.9,3.3,3.9,6.5,5.4,9.8c1.7,4,2.6,8.1,1.1,12.9
    c-1.3,4-4.2,7.7-7.9,9.8c-11.1,6.3-27.2-2.9-25.8-16.1c0.9-8,6.5-14.3,9.7-21.5c2.2-5.1,3.4-10.4,2-15.8
    c-3.5-14.2-17.1-23.6-31.7-21.5c-3.9,0.5-7.5,2.1-10.9,4c-3.3,1.9-6.5,3.9-9.8,5.4c-4,1.7-8.1,2.6-12.9,1.1c-4-1.3-7.7-4.2-9.8-7.9
    C517.7,79.6,526.9,63.5,540.1,64.9z' />
    <circle fill={props.reverse ? white : dark} cx='539.1' cy='146.3' r='17.2' />
    <circle fill={props.reverse ? white : light} cx='605' cy='23.8' r='17.2' />
    <circle fill={props.reverse ? white : other} cx='657.9' cy='115' r='17.2' />
  </svg>
)

export {
  PoweredByRCPAQAP,
  PoweredByRCPAQAPstacked
}

export default myQAP
