// @flow
import * as actions from './actions'
import { success, failure } from 'utilities/actions'

export type State = {
  data: Object,
  errors: Object[]
}

type Action = {
  type: string,
  data?: Object,
  errors?: Object[]
}

const initialState = {
  data: {},
  errors: []
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }
  switch (action.type) {
    case success(actions.API_SIGNUPS_CREATE):
      return { ...state, data: action.data }
    case failure(actions.API_SIGNUPS_CREATE):
      return { ...state, errors: action.errors }
    default:
      return state
  }
}

export default reducer
