// @flow
import { put, takeLatest, all, fork, select } from 'redux-saga/effects'
import { decamelizeKeys } from 'humps'
import * as actions from './actions'

import {
  API_URL,
  REPORTS_ENDPOINT
} from 'qap/constants'
import { requestActions } from 'state/request'

import { stringify } from 'utilities/apiQueryString'
import { getEndpointByDocType } from 'utilities/state'

import type { ReportUserStatus } from './types'

function * fetchReports (action) : Generator<any, any, any> {
  const { options: { docType } = {} } = action
  const { filter, page, sort } = yield select(state => state.reports)

  const queryString = stringify({ filter, page, sort })
  const url = `${API_URL}/${getEndpointByDocType(docType)}?${queryString ? `&${queryString}` : ''}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: actions.API_REPORTS_INDEX_SUCCESS,
    failureAction: actions.API_REPORTS_INDEX_FAILURE
  })
}

function * fetchReportFacets (action) : Generator<any, any, any> {
  const { options: { docType } = {} } = action
  const { filter } = yield select(state => state.reports)

  const queryString = stringify({ filter })
  const url = `${API_URL}/${getEndpointByDocType(docType)}/facets?${queryString ? `&${queryString}` : ''}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: actions.API_REPORTS_FACETS_INDEX_SUCCESS,
    failureAction: actions.API_REPORTS_FACETS_INDEX_FAILURE
  })
}

function * fetchReportDetail (action) : Generator<any, any, any> {
  const url = `${API_URL}/${REPORTS_ENDPOINT}/${action.reportId}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    hideLoading: true,
    method: 'GET',
    url,
    successAction: actions.FETCH_REPORT_DETAIL_SUCCESS,
    failureAction: actions.FETCH_REPORT_DETAIL_FAILURE
  })
}

export function * updateUserStatus (action: { userStatus: ReportUserStatus }): Generator<any, any, any> {
  const { reportId, docType, ...userStatus } = action.userStatus
  const url = `${API_URL}/${getEndpointByDocType(docType)}/${reportId}/user_status`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'PUT',
    url,
    body: { data: decamelizeKeys({ ...userStatus }) },
    successAction: actions.UPDATE_REPORT_USER_STATUS_SUCCESS,
    failureAction: actions.UPDATE_REPORT_USER_STATUS_FAILURE
  })
}

function * watchFetchReports () : Generator<any, any, any> {
  yield takeLatest([
    actions.API_REPORTS_FACETS_INDEX,
    actions.API_REPORTS_SET_ALL_FILTERS
  ], fetchReportFacets)

  yield takeLatest([
    actions.API_REPORTS_INDEX,
    actions.API_REPORTS_SET_ALL_FILTERS
  ], fetchReports)

  yield takeLatest([
    actions.FETCH_REPORT_DETAIL
  ], fetchReportDetail)
}

function * watchUserStatusChange () : Generator<any, any, any> {
  yield takeLatest(actions.UPDATE_REPORT_USER_STATUS, updateUserStatus)
}

export default function * reportsSagas () : Generator<any, any, any> {
  yield all([
    fork(watchFetchReports),
    fork(watchUserStatusChange)
  ])
}
