// @flow
import reducer from './reducer'
import type { State } from './reducer'

import * as organisationsActions from './actions'
import organisationsSagas from './sagas'

export {
  organisationsActions,
  organisationsSagas
}

export type OrganisationsState = State

export default reducer
