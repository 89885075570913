import React from 'react'
import withStyles from '@mui/styles/withStyles'
import QAPVars from 'qap/vars'

import { Theme } from '@mui/material/styles'
import type { ErrorComponentInterface } from './types'

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    marginTop: theme.spacing(2),
    borderTop: `1px ${QAPVars.borderGray} solid`,
    color: QAPVars.hintGray,
    '& *': {
      fontSize: '12px'
    }
  },

  errors: {
    margin: theme.spacing(2)
  }
})

const DisplayStackTrace = ({ classes, errors }: ErrorComponentInterface) => {
  if (process.env.NODE_ENV === 'production') {
    return null
  }

  return (
    <details className={classes.root}>
      <summary>Error details</summary>
      <div className={classes.errors}>
        <pre>
          {errors.message}
          {errors.componentStack}
        </pre>
      </div>
    </details>
  )
}

export default withStyles(styles)(DisplayStackTrace)
