// @flow
import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'

import GlobalNotificationAlert from './globalNotificationAlert'
import GlobalNotificationModal from './globalNotificationModal'

import { globalNotificationActions } from 'state/globalNotification'

import type { GlobalNotification as Notification } from 'types'

type Props = {
  data?: Notification,
  fetchGlobalNotification: () => void
}

const GlobalNotification = ({ data, fetchGlobalNotification }: Props) => {
  const { id, notificationType, message, heading, location, updatedAt } = data || {}

  const globalNotificationAcknowledged = localStorage.getItem('globalNotificationAcknowledged') || '{}'
  const { acknowledgedAt } = JSON.parse(globalNotificationAcknowledged)

  const [open, setOpen] = useState(false)

  const acknowledgeGlobalNotification = () => {
    localStorage.setItem('globalNotificationAcknowledged', JSON.stringify({ id, acknowledgedAt: new Date() }))
    setOpen(false)
  }

  const shouldOpen = !acknowledgedAt || new Date(updatedAt) > new Date(acknowledgedAt)
  if (shouldOpen !== open) setOpen(shouldOpen)

  useEffect(() => {
    fetchGlobalNotification()
  }, [fetchGlobalNotification])

  if (!message || !heading || !location || !notificationType) return null

  const strippedMessage = message.replace(/style=["'].*?["']/g, '')

  return (
    <Fragment>
      {
        location === 'header' &&
        <GlobalNotificationAlert
          heading={heading}
          message={strippedMessage}
          notificationType={notificationType}
          open={open}
          acknowledgeGlobalNotification={acknowledgeGlobalNotification}
        />
      }
      {
        location === 'modal' &&
        <GlobalNotificationModal
          heading={heading}
          message={strippedMessage}
          notificationType={notificationType}
          open={open}
          acknowledgeGlobalNotification={acknowledgeGlobalNotification}
        />
      }
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  const { globalNotification: { data } = {} } = state

  return {
    data
  }
}

const mapDispatchToProps = {
  fetchGlobalNotification: globalNotificationActions.fetchGlobalNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalNotification)
