// @flow
export const setLocalStorageOrganisationIds = (organisationIds: ?string[]): void => {
  if (!organisationIds) return

  if (organisationIds.length > 0) {
    localStorage.setItem('organisation_ids', organisationIds.toString())
  } else {
    // Clearing the input will result in organisationIds being an empty array
    // Removing organisation_ids from localStorage will ensure that the api defaults
    // back to the user's default organisation scope
    localStorage.removeItem('organisation_ids')
  }
}
