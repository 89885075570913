// @flow
import * as actions from './actions'

export type State = {
  [string]: {
    file: window.File,
    progress?: number,
    error?: Object
  }
}

export const initialState = {}

const reducer = (state: State, action: Object) => {
  if (state === undefined) { state = initialState }
  switch (action.type) {
    case actions.UPLOAD_REQUEST:
      return {
        ...state,
        [action.url]: {
          file: action.file,
          progress: 0
        }
      }
    case actions.UPLOAD_PROGRESS:
      return {
        ...state,
        [action.url]: {
          ...state[action.url],
          progress: action.progress
        }
      }
    case actions.UPLOAD_FAILURE:
      return {
        ...state,
        [action.url]: {
          ...state[action.url],
          error: action.error
        }
      }
    case actions.UPLOAD_SUCCESS:
      return {
        ...state,
        [action.url]: {
          ...state[action.url],
          success: true
        }
      }
    default:
      return state
  }
}

export default reducer
