// @flow
import { failure, success } from '../../utilities/actions'
import * as actions from './actions'
import type { Action, State } from './type'

export const initialState = {
  errors: undefined,
  isLoading: undefined,
  preferences: undefined,
}

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case actions.FETCH_MFA_ENROLMENT:
      return {
        ...state,
        errors: undefined,
        isLoading: true,
      }
    case success(actions.FETCH_MFA_ENROLMENT):
      return {
        ...state,
        isLoading: false,
        preferences: action.data,
      }
    case failure(actions.FETCH_MFA_ENROLMENT):
      return {
        ...state,
        errors: action.errors,
        isLoading: false,
      }
    case actions.SAVE_MFA_ENROLMENT:
      return {
       ...state,
        errors: undefined,
        isLoading: true,
      }
    case success(actions.SAVE_MFA_ENROLMENT):
      return {
       ...state,
        isLoading: false,
        preferences: action.data,
      }
    case failure(actions.SAVE_MFA_ENROLMENT):
      return {
       ...state,
        errors: action.errors,
        isLoading: false,
      }
    case actions.SAVE_MFA_UNENROLMENT:
      return {
       ...state,
        errors: undefined,
        isLoading: true,
      }
    case success(actions.SAVE_MFA_UNENROLMENT):
      return {
       ...state,
        isLoading: false,
        preferences: action.data,
      }
    case failure(actions.SAVE_MFA_UNENROLMENT):
      return {
       ...state,
        errors: action.errors,
        isLoading: false,
      }
    default:
      return state
  }
}

export default reducer