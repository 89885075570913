// @flow
import React from 'react'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'

type ModalIconProps = {
  notificationType: string,
  className: string
}

export const GlobalNotificationModalIcon = ({ notificationType, className }: ModalIconProps) => {
  if (notificationType === 'error') return <ErrorOutlineIcon className={className} />
  if (notificationType === 'warning') return <ReportProblemOutlinedIcon className={className} />
  if (notificationType === 'info') return <InfoOutlinedIcon className={className} />
  if (notificationType === 'success') return <CheckCircleOutlinedIcon className={className} />

  return null
}
