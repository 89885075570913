// @flow
import { decamelizeKeys } from 'humps'
import { requestActions } from 'state/request'
import {
  API_URL,
  PASSWORD_RESET_REQUEST_ENDPOINT
} from 'qap/constants'
import { success, failure, reset } from 'utilities/actions'

export const API_PASSWORD_RESET_REQUESTS_CREATE = 'API_PASSWORD_RESET_REQUESTS_CREATE'
export const API_PASSWORD_RESET_REQUESTS_SHOW = 'API_PASSWORD_RESET_REQUESTS_SHOW'
export const API_PASSWORD_RESETS_RESETS_CREATE = 'API_PASSWORD_RESETS_RESETS_CREATE'

type PasswordResetRequestData = { recipientEmail: string }

export const createPasswordResetRequest = (data: PasswordResetRequestData) => {
  return {
    type: requestActions.UNAUTHED_REQUEST,
    method: 'POST',
    url: `${API_URL}/${String(PASSWORD_RESET_REQUEST_ENDPOINT)}`,
    successAction: success(API_PASSWORD_RESET_REQUESTS_CREATE),
    failureAction: failure(API_PASSWORD_RESET_REQUESTS_CREATE),
    body: { data: decamelizeKeys({ ...data }) }
  }
}

export const fetchAccessAccountRequest = (secureId: string) => {
  return {
    type: requestActions.UNAUTHED_REQUEST,
    method: 'GET',
    url: `${API_URL}/${String(PASSWORD_RESET_REQUEST_ENDPOINT)}/${secureId}`,
    successAction: success(API_PASSWORD_RESET_REQUESTS_SHOW),
    failureAction: failure(API_PASSWORD_RESET_REQUESTS_SHOW)
  }
}

type PasswordResetData = { password: string }

export const createPasswordReset = (secureId: string, data: PasswordResetData) => {
  return {
    type: requestActions.UNAUTHED_REQUEST,
    method: 'POST',
    url: `${API_URL}/${String(PASSWORD_RESET_REQUEST_ENDPOINT)}/${secureId}/reset`,
    successAction: success(API_PASSWORD_RESETS_RESETS_CREATE),
    failureAction: failure(API_PASSWORD_RESETS_RESETS_CREATE),
    body: { data: decamelizeKeys(data) }
  }
}

export const resetPasswordReset = () => ({
  type: reset(API_PASSWORD_RESETS_RESETS_CREATE)
})
