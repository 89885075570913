// @flow
import { decamelizeKeys as _decamelizeKeys } from 'humps'

const RESULT_ENTRY_CATEGORY_ID: RegExp = /^[A-Z0-9_.-]+(:+[A-Z0-9_.-]+)+$/

const WHITELIST: Array<RegExp> = [
  RESULT_ENTRY_CATEGORY_ID
]

const isWhitelisted = (key: string): boolean =>
  WHITELIST.some(whitelist => whitelist.test(key))

export const decamelizeKeys = (obj: Object, options?: Object): Object => {
  return _decamelizeKeys(obj, function (key, convert) {
    return isWhitelisted(key) ? key : convert(key, options)
  })
}
