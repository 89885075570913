// @flow
import React, {lazy, Suspense} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {CssBaseline, StyledEngineProvider, ThemeProvider} from '@mui/material'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import Feedback from 'components/atoms/feedback'

import Loading from 'components/atoms/loading'
import GoogleAnalyticsGuard from 'components/atoms/googleAnalyticsGuard'
import ScrollToTop from 'components/atoms/scrollToTop'
import ErrorDialog from 'components/atoms/errorDialog'
import PrivateRoute from 'components/molecules/privateRoute'
import ErrorBoundary from 'components/molecules/errorBoundary'
import GlobalErrorBoundary from './errorBoundary'
import {retry} from './helpers'
import theme from './theme'
import {
  ANALYTICS_PAGE_URL,
  CERTIFICATES_PAGE_URL,
  DASHBOARD_PAGE_URL,
  DATA_EXTRACT_PAGE_URL,
  ENROLMENTS_TAB_PAGE_URL,
  INVOICES_TAB_PAGE_URL,
  LANDING_PAGE_URL,
  LOGIN_URL,
  REPORTS_OVERVIEW_PAGE_URL,
  REPORTS_PAGE_URL,
  REQUESTS_TAB_PAGE_URL,
  ROUND_ENROLMENTS_OVERVIEW_PAGE_URL,
  ROUND_ENROLMENTS_PAGE_URL,
  SF_ENROLMENTS_TAB,
  SF_INVOICES_TAB,
  SF_REQUESTS_TAB,
  USER_PREFERENCE_URL
} from 'qap/constants'
import {CERTIFICATE_ACTION_OPTIONS, REPORT_ACTION_OPTIONS} from 'state/report/actions'

import HeaderSidebarLayout from 'components/templates/headerSidebarLayout'
import LoadingMessage from 'components/templates/loadingMessage'

const AnalyticsPage = lazy(() => retry(() => import('components/pages/analyticsPage')))
const DataExtractPage = lazy(() => retry(() => import('components/pages/dataExtractPage')))
const SetPasswordPage = lazy(() => retry(() => import('components/pages/setPasswordPage')))
const JwtAuthPage = lazy(() => retry(() => import('components/pages/jwtAuthPage')))
const LoginPage = lazy(() => retry(() => import('components/pages/loginPage')))
const FeedbackPage = lazy(() => retry(() => import('components/pages/feedbackPage')))
const ReportPage = lazy(() => retry(() => import('components/pages/reportPage')))
const FullScreenReportPage = lazy(() => retry(() => import('components/pages/fullScreenReportPage')))
const ReportsPage = lazy(() => retry(() => import('components/pages/reportsPage')))
const ReportsOverviewPage = lazy(() => retry(() => import('components/pages/reportsOverviewPage')))
const RoundEnrolmentsPage = lazy(() => retry(() => import('components/pages/roundEnrolmentsPage')))
const RoundEnrolmentsOverviewPage = lazy(() => retry(() => import('components/pages/roundEnrolmentsOverviewPage')))
const RoundEnrolmentPage = lazy(() => retry(() => import('components/pages/roundEnrolmentPage')))
const PasswordResetRequestPage = lazy(() => retry(() => import('components/pages/passwordResetRequestPage')))
const DashboardPage = lazy(() => retry(() => import('components/pages/dashboardPage')))
const LandingPage = lazy(() => retry(() => import('components/pages/landingPage')))
const UserPreferencePage = lazy(() => retry(() => import('components/pages/userPreferencePage')))
const NoMatch = lazy(() => retry(() => import('components/pages/noMatch')))
const ClassicPortalRedirect = lazy(() => import('components/organisms/classicPortalRedirect'))

export const App = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ErrorBoundary component={GlobalErrorBoundary}>
            <Loading />
            <GoogleAnalyticsGuard />
            <ErrorDialog />
            <ScrollToTop />
        <Suspense fallback={<LoadingMessage>Loading myQAP portal</LoadingMessage>}>
              <Switch>
                <Route path='/set-password/:secureId' render={(props) => <SetPasswordPage {...props} />} />
                <Route path='/createaccount/:secureId' render={(props) => <SetPasswordPage {...props} />} />
                <Route path='/create-account/:secureId' render={(props) => <SetPasswordPage {...props} />} />
                <Route path='/password-reset/:secureId' render={(props) => <SetPasswordPage {...props} />} />
                <Route path='/password-reset' render={(props) => <PasswordResetRequestPage {...props} />} />
                <Route path={LOGIN_URL} render={(props) => <LoginPage {...props} />} />
                <Route path='/feedback' render={(props) => <FeedbackPage {...props} />} />
                <Route exact path='/auth' render={(props) => <JwtAuthPage {...props} />} />
                <PrivateRoute>
                  <Switch>
                    <Route exact path={REPORTS_PAGE_URL + '/:reportId/full-screen'} render={(props) => <FullScreenReportPage {...props} />} />

                    <HeaderSidebarLayout>
                      <Suspense fallback={<LoadingMessage>Loading</LoadingMessage>}>
                        <Switch>
                          <Route path={ANALYTICS_PAGE_URL} render={(props) => <AnalyticsPage {...props} />} />
                          <Route path={DATA_EXTRACT_PAGE_URL} render={(props) => <DataExtractPage {...props} />} />

                          <Route exact path={CERTIFICATES_PAGE_URL + '/:reportId/:tab?'} render={(props) => <ReportPage options={CERTIFICATE_ACTION_OPTIONS} {...props} />} />
                          <Route path={CERTIFICATES_PAGE_URL} render={(props) => <ReportsPage options={CERTIFICATE_ACTION_OPTIONS} {...props} />} />

                          <Route exact path={REPORTS_PAGE_URL + '/:reportId/:tab?'} render={(props) => <ReportPage options={REPORT_ACTION_OPTIONS} {...props} />} />
                          <Route path={REPORTS_PAGE_URL} render={(props) => <ReportsPage options={REPORT_ACTION_OPTIONS} {...props} />} />
                          <Route path={REPORTS_OVERVIEW_PAGE_URL} render={(props) => <ReportsOverviewPage {...props} />} />

                          <Route path={ROUND_ENROLMENTS_PAGE_URL + '/calendar/:year?/:month?'} render={(props) => <RoundEnrolmentsPage {...props} />} />
                          <Redirect exact from={ROUND_ENROLMENTS_PAGE_URL + '/:id'} to={ROUND_ENROLMENTS_PAGE_URL + '/:id/instructions'} />
                          <Route path={ROUND_ENROLMENTS_PAGE_URL + '/:roundEnrolmentId/:tab/:resultTab?'} render={(props) => <RoundEnrolmentPage key={props.match.params.roundEnrolmentId} {...props} />} />
                          <Route path={ROUND_ENROLMENTS_PAGE_URL} render={(props) => <RoundEnrolmentsPage {...props} />} />
                          <Route path={ROUND_ENROLMENTS_OVERVIEW_PAGE_URL} render={(props) => <RoundEnrolmentsOverviewPage {...props} />} />
                          <Route exact path={LANDING_PAGE_URL} render={(props) => <LandingPage {...props} />} />
                          <Route path={ENROLMENTS_TAB_PAGE_URL} children={<ClassicPortalRedirect redirectUrl={SF_ENROLMENTS_TAB} />} />
                          <Route path={INVOICES_TAB_PAGE_URL} children={<ClassicPortalRedirect redirectUrl={SF_INVOICES_TAB} />} />
                          <Route path={REQUESTS_TAB_PAGE_URL} children={<ClassicPortalRedirect redirectUrl={SF_REQUESTS_TAB} />} />
                          <Route exact path={DASHBOARD_PAGE_URL} render={(props) => <DashboardPage {...props} />} />
                          <Route exact path={USER_PREFERENCE_URL + '/:tab?'} render={(props) => <UserPreferencePage {...props} />} />
                          <Route render={(props) => <NoMatch {...props} />} />
                        </Switch>
                      </Suspense>
                    </HeaderSidebarLayout>
                  </Switch>
                </PrivateRoute>
              </Switch>
            </Suspense>
          </ErrorBoundary>
          <Feedback />
        </LocalizationProvider>
      </CssBaseline>
    </ThemeProvider>
  </StyledEngineProvider>
)

export default App
