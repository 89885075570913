// @flow
import { put, takeLatest, all, fork } from 'redux-saga/effects'
import { decamelizeKeys } from 'humps'

import * as actions from './actions'
import { requestActions } from 'state/request'
import { API_URL, CALENDAR_PREFERENCE_ENDPOINT } from 'qap/constants'

import type { FetchCalendarPreference, SaveCalendarPreferences } from './type'

export function * fetchCalendarPreferences (action: FetchCalendarPreference) : Generator<any, any, any> {
  const url = `${API_URL}/${CALENDAR_PREFERENCE_ENDPOINT}/current_user_calendars`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: actions.FETCH_CALENDAR_PREFERENCE_SUCCESS,
    failureAction: actions.FETCH_CALENDAR_PREFERENCE_FAILURE
  })
}

export function * saveCalendarPreferences (action: SaveCalendarPreferences): Generator<any, any, any> {
  const { preferences } = action
  const url = `${API_URL}/${CALENDAR_PREFERENCE_ENDPOINT}/current_user_calendars`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'PUT',
    url,
    body: { data: decamelizeKeys({ preferences }) },
    successAction: actions.SAVE_CALENDAR_PREFERENCE_SUCCESS,
    failureAction: actions.SAVE_CALENDAR_PREFERENCE_FAILURE
  })
}

function * watchFetchCalendarPreferences () : Generator<any, any, any> {
  yield takeLatest(actions.FETCH_CALENDAR_PREFERENCE, fetchCalendarPreferences)
}

function * watchCalendarPreferencesSave () : Generator<any, any, any> {
  yield takeLatest(actions.SAVE_CALENDAR_PREFERENCE, saveCalendarPreferences)
}

export default function * calendarPreferenceSagas () : Generator<any, any, any> {
  yield all([
    fork(watchFetchCalendarPreferences),
    fork(watchCalendarPreferencesSave)
  ])
}
