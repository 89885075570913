// @flow
import reducer from './reducer'
import * as featureTourActions from './actions'
import type { State } from './reducer'

export {
  featureTourActions
}

export type FeatureToursState = State

export default reducer
