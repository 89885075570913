// @flow
import type { Authorization } from './types'

export const isOperationPermitted = (authorization: Authorization, operationId: string): boolean => {
  const userPermissions = authorization.permissions || []
  const operationPermissions = authorization.permissionsByOperationId[operationId.toLowerCase()]
  return operationPermissions ? operationPermissions.some(requiredPermission =>
    userPermissions.includes(requiredPermission)
  ) : false
}

type Action = {
  operationId?: string
}

type Actions = {
  [string]: Action
}

export const onlyPermissibleActions = (authorization: Authorization, actionsObject: Actions) => {
  let permissibleActions = {}
  Object.keys(actionsObject).forEach((actionKey: string) => {
    const action = actionsObject[actionKey]
    const operationId = action.operationId
    if (!operationId || isOperationPermitted(authorization, operationId)) {
      permissibleActions[actionKey] = action
    }
  })
  return permissibleActions
}

export const hasPermission = ({ permissions: userPermissions }: Authorization = {}, search: string): boolean => (userPermissions.includes(search))
