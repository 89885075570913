// @flow
import type { User } from 'types'

const hashCode = (str) => {
  let hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

export const getUserColor = (user: ?User) => {
  return `hsl(${hashCode(getUserInitials(user)) % 360}, 100%, 20%)`
}

export const getUserInitials = (user: ?User) => {
  let nameInitials = ''
  if (user) {
    const firstInitial = user.firstName ? user.firstName.charAt(0).toUpperCase() : ''
    const lastInitial = user.lastName ? user.lastName.charAt(0).toUpperCase() : ''
    nameInitials = `${firstInitial}${lastInitial}`
  }
  return nameInitials
}

export const getUserName = (user: ?User, showTag?: boolean) => {
  if (!user) return 'RCPAQAP'

  const { firstName, lastName, email, tags = [] } = user

  const name = [firstName, lastName].filter(Boolean).join(' ').trim() || email
  const tagStr = showTag && tags.length ? ` (${tags.map(({ name }) => name).join(', ')})` : ''

  return `${name}${tagStr}`
}

export const hasUser = (user: ?User, userList: ?Array<User>) => {
  if (user && userList) {
    return userList.map(iterm => iterm.id).includes(user.id)
  }

  return false
}
