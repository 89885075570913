// @flow
import type { Feature } from 'types'

type FeatureActiveProps = {
  features: Feature[],
  feature: string
}

export const isFeatureActive = ({ features = [], feature }: FeatureActiveProps): boolean => (
  (features.find(({ id }) => (id === feature)) || {}).active
)
