// @flow
import {
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  isValid,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from 'date-fns'
import { kebabCase, toLower } from 'lodash'

import type { DateRangeOption } from 'state/dashboard/types'
import type { CustomDateType, DateRangePickerOption, DateRangePickerOptions } from './types'

const buildOption = (labelText) => (
  { label: labelText, key: kebabCase(labelText.split(' (')[0]) }
)

export const customOption: DateRangePickerOption = { label: '', key: 'custom' }

export const getDateRangeOptions = (): DateRangePickerOptions => {
  const today = new Date()
  const aMonthBack = subMonths(today, 1)
  const aYearBack = subYears(today, 1)
  const options = [
    buildOption(`This month (${format(today, 'MMM')})`),
    buildOption(`Last month (${format(aMonthBack, 'MMM')})`),
    buildOption('Last 30 days (from yesterday)'),
    buildOption('Last 90 days (from yesterday)'),
    buildOption('Last 12 months'),
    buildOption(`This year (${format(today, 'yyyy')})`),
    buildOption(`Last year (${format(aYearBack, 'yyyy')})`),
    customOption,
  ]
  return options
}

export const getDateRangeOptionByKey = (key: string): DateRangePickerOption => (
  getDateRangeOptions().find(option => option.key === toLower(key))
)

const buildDateRangeObj = (startDate: Date, endDate: Date): { startDate: string, endDate: string } => (
  { startDate: startDate.toISOString(), endDate: endDate.toISOString() }
)

export const getThisMonthRange = (today: Date) => buildDateRangeObj(startOfMonth(today), endOfDay(today))
export const getLastMonthRange = (today: Date) => {
  const aMonthBack = subMonths(today, 1)
  return buildDateRangeObj(startOfMonth(aMonthBack), endOfMonth(aMonthBack))
}
export const getLast30DaysRange = (today: Date) => {
  const yesterday = subDays(today, 1)
  return buildDateRangeObj(subDays(startOfDay(today), 30), endOfDay(yesterday))
}
export const getLast90DaysRange = (today: Date) => {
  const yesterday = subDays(today, 1)
  return buildDateRangeObj(subDays(startOfDay(today), 90), endOfDay(yesterday))
}
export const getLast12MonthsRange = (today: Date) => {
  const aMonthBack = subMonths(today, 1)
  return buildDateRangeObj(startOfMonth(subMonths(today, 12)), endOfMonth(aMonthBack))
}
export const getThisYearRange = (today: Date) => buildDateRangeObj(startOfYear(today), endOfDay(today))
export const getLastYearRange = (today: Date) => {
  const aYearBack = subYears(today, 1)
  return buildDateRangeObj(startOfYear(aYearBack), endOfYear(aYearBack))
}
export const getCustomDateRange = ({ startDate, endDate }:{ startDate: string, endDate: string }) => {
  return buildDateRangeObj(
    startOfDay(new Date(startDate)),
    endOfDay(new Date(endDate))
  )
}

const getLabelFormattedDate = (dateTime: string): string => (
  format(new Date(dateTime), "d LLL yyyy")
)

export const customDateRangeLabel = (dates: { startDate: string, endDate: string }): string => {
  const startDate = getLabelFormattedDate(dates.startDate)
  const endDate = getLabelFormattedDate(dates.endDate)
  const startListReversed = startDate.split(' ').reverse()
  const endListReversed = endDate.split(' ').reverse()
  let matchingEntries = []
  for (const [index, item] of startListReversed.entries()) {
    if (endListReversed[index] != item) break
    matchingEntries.unshift(item)
  }
  const matchingPart = matchingEntries.join(' ').trim()
  return matchingPart === startDate ?
    matchingPart :
    `${startDate.replace(matchingPart, '').trim()} - ${endDate}`
}


export const getDateRangeFromOption = (dateRangeChoice: DateRangeOption, customDate?: CustomDateType = {}): DateRangeOption => {
  const today = new Date()
  switch (dateRangeChoice.key) {
    case 'this-month':
      return { ...dateRangeChoice, value: getThisMonthRange(today) }
    case 'last-month':
      return { ...dateRangeChoice, value: getLastMonthRange(today) }
    case 'last-30-days':
      return { ...dateRangeChoice, value: getLast30DaysRange(today) }
    case 'last-90-days':
      return { ...dateRangeChoice, value: getLast90DaysRange(today) }
    case 'last-12-months':
      return { ...dateRangeChoice, value: getLast12MonthsRange(today) }
    case 'this-year':
      return { ...dateRangeChoice, value: getThisYearRange(today) }
    case 'last-year':
      return { ...dateRangeChoice, value: getLastYearRange(today) }
    case 'custom':
      // modified just to fix the flow check
      const { startDate, endDate } = customDate
      if (startDate !== undefined && endDate !== undefined) {
        const dateRangeValue = getCustomDateRange({startDate, endDate})
        return { ...dateRangeChoice, label: customDateRangeLabel(dateRangeValue), value: dateRangeValue }
      } else {
        return dateRangeChoice
      }
    default:
      return { ...dateRangeChoice }
  }
}

const dateRangeObjValidator = (key, value): string | {[string]: string} | void => {
  if (['', 'label', 'key', 'value', 'startDate', 'endDate'].includes(key)) {
    if (key === 'startDate' || key === 'endDate') {
      if (!isValid(parseISO(value))) {
        throw new Error(`Invalid ${key}`)
      }
    }
    return value
  }
}

export const getPersistentDateRangeFor = (category: string): DateRangeOption | null  => {
  const persistentDateRange = localStorage.getItem(`${category}.dateRange`) || 'null'
  try {
    return JSON.parse(persistentDateRange, dateRangeObjValidator)
  } catch {
    return null
  }
}
