// @flow
import ReactGA from 'react-ga4'
import { ENV_GOOGLE_ANALYTICS_MEASUREMENT_ID } from 'qap/constants'

import type { GoogleAnalyticsEvent } from './types'

export const SEARCH_INPUT_ACTION = 'Search Input'
export const COLUMN_FILTER_ACTION = 'Column Filter'
export const CHECKBOX_FILTER_ACTION = 'Checkbox Filter'
export const ADVANCED_FILTER_ACTION = 'Advanced Filter'
export const CALENDAR_IMPORT_ACTION = 'Calendar - Import Web iCalendar'
export const CALENDAR_CSV_EXPORT_ACTION = 'Calendar - CSV Export'
export const DISPATCH_SCHEDULE_DOWNLOAD_ACTION = 'Calendar - Download Dispatch Schedule'
export const CLICK_LINK_ACTION = 'Click Link'
export const INIT_EVENT = {
  category: 'myQAP Portal',
  action: 'Unknown',
  label: 'Unknown'
}

const getMeasurementId = () => {
  if (process.env.NODE_ENV === 'test') {
    return 'GA-Test-ID'
  }

  return ENV_GOOGLE_ANALYTICS_MEASUREMENT_ID
}

export const initGoogleAnalytics = ({ history } : { history: Object }) => {
  const measurementId = getMeasurementId()

  if (!measurementId) return

  ReactGA.initialize(
    measurementId,
    { testMode: process.env.NODE_ENV === 'test' }
  )

  setPageTracking(history)
}

export const setPageTracking = (history?: Object) => {
  if (!history) return

  history.listen(location => {
    const currentURI = location.pathname + location.search

    ReactGA.send({ hitType: "pageview", page: currentURI, title: currentURI })
  })
}

export const triggerGoogleAnalyticsEvent = (event: GoogleAnalyticsEvent) => {
  if (!event) event = INIT_EVENT
  ReactGA.event(event)
}

export const disableGoogleAnalytics = () => (window[`ga-disable-${getMeasurementId()}`] = true)
