// @flow
import reducer from './reducer'
import type { State } from './reducer'

import * as signupActions from './actions'

export {
  signupActions
}

export type SignupState = State

export default reducer
