// @flow
import reducer from './reducer'
import type { State } from './reducer'

import * as uploadsActions from './actions'
import uploadsSagas from './sagas'

export {
  uploadsActions,
  uploadsSagas
}

export type UploadsState = State

export default reducer
