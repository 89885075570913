// @flow
import { snakeCase, toUpper } from 'lodash'

import { REPORT_REVIEW_CHART, RESULT_ENTRY_CHART } from 'components/organisms/dashboard/overviewCard/constants'
import { getInitialDateRangeFor } from './helpers'


const REPORT_REVIEW_CHART_DATA_LOAD = 'REPORT_REVIEW_CHART_DATA_LOAD'
const RESULT_ENTRY_CHART_DATA_LOAD = 'RESULT_ENTRY_CHART_DATA_LOAD'

const loadData = (category: string, dateRange: Object) => ({
  type: `${toUpper(snakeCase(category))}_DATA_LOAD`,
  dateRange
})

const resultEntryChartInitialDateRange = getInitialDateRangeFor(RESULT_ENTRY_CHART)
const reportReviewChartInitialDateRange = getInitialDateRangeFor(REPORT_REVIEW_CHART)

export {
  REPORT_REVIEW_CHART_DATA_LOAD,
  RESULT_ENTRY_CHART_DATA_LOAD,
  loadData,
  reportReviewChartInitialDateRange,
  resultEntryChartInitialDateRange
}

