// @flow
import { success, failure } from 'utilities/actions'
import { setIn } from 'utilities/resultEntry'

import * as actions from './actions'
import type {
  RoundEnrolment,
  RoundEnrolmentResults
} from 'types'

export type State = {
  data?: RoundEnrolment,
  errors?: Object[],
  submittingResults: boolean,
  updatingResults: boolean,
  unsavedResults: RoundEnrolmentResults,
  preparingResults: boolean,
  resultsPrepared: boolean,
  submittingConfig: boolean
}

export type Action = Object & {
  type: string
}

export const initialState = {
  submittingResults: false,
  updatingResults: false,
  unsavedResults: {},
  preparingResults: false,
  submittingConfig: false,
  resultsPrepared: false,
  fetchingSubmission: false
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }
  switch (action.type) {
    case actions.API_ROUND_ENROLMENTS_SHOW:
    case actions.CLEAR_ROUND_ENROLMENT_DATA:
      return { ...initialState }
    case actions.START_UPDATING_RESULTS:
      return { ...state, updatingResults: true }
    case actions.API_ROUND_ENROLMENTS_RESULTS_SUBMIT:
      return { ...state, submittingResults: true }
    case actions.API_ROUND_ENROLMENT_CONFIG_UPDATE:
      return { ...state, submittingConfig: true }
    case success(actions.API_ROUND_ENROLMENTS_SHOW):
      return { ...initialState, data: action.data }
    case success(actions.API_ROUND_ENROLMENT_CONFIG_UPDATE):
      return { ...state, submittingConfig: false, data: action.data }
    case success(actions.API_ROUND_ENROLMENTS_RESULTS_UPDATE):
      return { ...state, updatingResults: false, data: action.data }
    case success(actions.API_ROUND_ENROLMENTS_PREPARE_RESULTS):
      return { ...state, data: action.data, preparingResults: false, resultsPrepared: true }
    case success(actions.API_ROUND_ENROLMENTS_RESULTS_SUBMIT):
    case success(actions.API_ROUND_ENROLMENTS_INTERNAL_SUBMISSIONS_CREATE):
      return { ...state, ...initialState, data: action.data }
    case failure(actions.API_ROUND_ENROLMENTS_SHOW):
    case failure(actions.API_ROUND_ENROLMENT_CONFIG_UPDATE):
    case failure(actions.API_ROUND_ENROLMENTS_RESULTS_UPDATE):
    case failure(actions.API_ROUND_ENROLMENTS_PREPARE_RESULTS):
    case failure(actions.API_ROUND_ENROLMENTS_RESULTS_SUBMIT):
    case failure(actions.API_ROUND_ENROLMENTS_INTERNAL_SUBMISSIONS_CREATE):
      return { ...state, submittingResults: false, updatingResults: false, preparingResults: false, resultsPrepared: false, submittingConfig: false, errors: action.errors }
    case actions.ACCUMULATE_UNSAVED_RESULTS:
      const { resultId, values } = action.data

      if (resultId) {
        const unsavedResults = { ...(state.unsavedResults || {}), [resultId]: values }
        return { ...state, unsavedResults }
      }

      return state

    case actions.API_ROUND_ENROLMENTS_PREPARE_RESULTS:
      return { ...state, preparingResults: true, resultsPrepared: false }
    case actions.RESTORE_UNSAVED_RESULTS:
      const { unprocessedRequests = [] } = action
      let unprocessedResults = {}
      unprocessedRequests.forEach(({ payload: { results = {} } }) => (
        Object.keys(results).forEach(key => {
          unprocessedResults = setIn(unprocessedResults, key, results[key])
        })
      ))
      return { ...state, unsavedResults: unprocessedResults }
    case actions.CLEAR_UNSAVED_RESULTS:
      return { ...state, unsavedResults: {} }
    case success(actions.API_ROUND_ENROLMENTS_SUBMISSIONS_INDEX):
      return { ...state, submissions: action.data }
    case failure(actions.API_ROUND_ENROLMENTS_SUBMISSIONS_INDEX):
      return { ...state, submissions: [], errors: action.errors }
    case actions.API_SURVEY_ROUND_SUBMISSIONS_SHOW:
      return { ...state, fetchingSubmission: true }
    case success(actions.API_SURVEY_ROUND_SUBMISSIONS_SHOW):
      return { ...state, submission: action.data, fetchingSubmission: false }
    case failure(actions.API_SURVEY_ROUND_SUBMISSIONS_SHOW):
      return { ...state, errors: (action.errors.length ? action.errors : ['Failed to load']), fetchingSubmission: false }
    case success(actions.API_ROUND_ENROLMENTS_RELATED_PROGRAMS):
      return { ...state, relatedPrograms: action.data }
    default:
      return state
  }
}

export default reducer
