// @flow
import * as actions from './actions'

import type { CalendarPreference, Action } from './type'
import type { Program } from 'components/pages/analyticsPage/state/types'

export type State = {
  calendarPreferences: CalendarPreference,
  calendarUrl: string,
  programs: Program[],
  errors: Object[],
  saving: boolean,
  saved: boolean,
  unsaved: boolean,
  initialLoad: boolean,
  displayCalendarDialog: boolean,
  displayCsvExportDialog: boolean
}

export const initialState = {
  calendarPreferences: {},
  calendarUrl: '',
  programs: [],
  errors: [],
  saving: false,
  saved: false,
  unsaved: false,
  initialLoad: true,
  displayCalendarDialog: false,
  displayCsvExportDialog: false
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }
  switch (action.type) {
    case actions.FETCH_CALENDAR_PREFERENCE_SUCCESS:
      return {
        ...state,
        ...action.data,
        unsaved: false
      }
    case actions.SAVE_CALENDAR_PREFERENCE:
      return {
        ...state,
        saving: true,
        saved: false,
        unsaved: true,
        initialLoad: false
      }
    case actions.SAVE_CALENDAR_PREFERENCE_SUCCESS:
      return {
        ...state,
        ...action.data,
        saving: false,
        saved: true,
        unsaved: false,
        initialLoad: false,
        displayCalendarDialog: true
      }
    case actions.SAVE_CALENDAR_PREFERENCE_FAILURE:
      return {
        ...state,
        unsaved: true,
        errors: action.errors,
        initialLoad: false,
        displayCalendarDialog: false
      }
    case actions.UPDATE_CALENDAR_PREFERENCE:
      return {
        ...state,
        saved: false,
        unsaved: true,
        initialLoad: false
      }
    case actions.SET_CALENDAR_DIALOG_STATUS:
      return {
        ...state,
        displayCalendarDialog: action.value
      }
    case actions.SET_CSV_EXPORT_DIALOG_STATUS:
      return {
        ...state,
        displayCsvExportDialog: action.value
      }
    default:
      return state
  }
}

export default reducer
