// @flow
import React from 'react'
import withStyles from '@mui/styles/withStyles'
import QAPVars from 'qap/vars'

import { Theme } from '@mui/material/styles'
import type { ErrorComponentInterface } from './types'

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    backgroundColor: 'red' // make it stand out
  },
  link: {
    color: QAPVars.linkBlue,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
})

const DefaultErrorBoundary = ({ classes, errors }: ErrorComponentInterface) => (
  <div className={classes.root}>
    Something went wrong. Try <a href='.' className={classes.link}>refreshing the page</a>.
    Please contact RCPAQAP if this is still an issue.
  </div>
)

export default withStyles(styles)(DefaultErrorBoundary)
