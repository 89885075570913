// @flow
import React, { Fragment } from 'react'
import { Typography, Hidden, Button, Box } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import ToolTip from 'components/molecules/toolTip'

import type { Organisation } from 'types'

type Props = {
  classes: Object,
  toggleOrgSwitcher: () => void,
  selectedOrganisations: Organisation[]
}

const styles = theme => ({
  orgName: {
    maxWidth: theme.spacing(36),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  orgNameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  indent: {
    paddingLeft: theme.spacing(2.5)
  },
  extraOrganisations: {
    paddingLeft: theme.spacing(1)
  },
  noBackground: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
})

const SwitchOrganisationButton = (props: Props) => {
  const { selectedOrganisations = [], toggleOrgSwitcher, classes } = props

  const organisation = selectedOrganisations[0]
  if (!organisation) return null

  const extraOrganisationsCount = selectedOrganisations.length - 1

  return (
    <Hidden mdDown initialWidth='md'>
      <ToolTip
        title={
          <Fragment>
            <span>Selected Organisations:</span>
            <ul className={classes.indent}>
              {
                selectedOrganisations.map(({ name }, i) => <li key={i}>{name}</li>)
              }
            </ul>
          </Fragment>
        }
        placement='bottom'
      >
        <Box>
          <Button className={classes.noBackground} onClick={toggleOrgSwitcher}>
            <Typography variant='subtitle1' className={classes.orgNameContainer}>
              <div className={classes.orgName}>{organisation.name}</div>
              {
                selectedOrganisations.length > 1 &&
                <div className={classes.extraOrganisations}>(+{extraOrganisationsCount})</div>
              }
            </Typography>
          </Button>
        </Box>
      </ToolTip>
    </Hidden>
  )
}

export default withStyles(styles)(SwitchOrganisationButton)
