// @flow
import reducer from './reducer'

import * as notificationPreferenceActions from './actions'
import notificationPreferenceSagas from './sagas'

export {
  notificationPreferenceActions,
  notificationPreferenceSagas
}

export default reducer
