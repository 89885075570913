// @flow
import { success, failure } from 'utilities/actions'
import { put, takeLatest, all, fork, select } from 'redux-saga/effects'
import * as actions from './actions'

import { API_URL, REPORT_REVIEW_STATS } from 'qap/constants'
import { requestActions } from 'state/request'

import { buildPath } from 'utilities/apiQueryString'

export function * fetch (action: { type: string, searchParams: { [string]: string } }) : Generator<any, any, any> {
  const { filter, searchParams } = yield select(state => state.reportsOverview)
  const url = buildPath({
    path: `${API_URL}/${REPORT_REVIEW_STATS}`,
    queryParams: { filter, ...searchParams }
  })

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: success(actions.API_REPORTS_COUNTS_INDEX),
    failureAction: failure(actions.API_REPORTS_COUNTS_INDEX)
  })
}

function * watchFetch () : Generator<any, any, any> {
  yield takeLatest([
    actions.API_REPORTS_COUNTS_SET_ALL_FILTERS
  ], fetch)
}

export default function * reportsOverviewSagas () : Generator<any, any, any> {
  yield all([
    fork(watchFetch)
  ])
}
