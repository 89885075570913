//@flow
import React, { useContext } from 'react'
import type { Node as ReactNode } from 'react'

import PermissionErrorMessage from '../permissionErrorMessage'
import { AuthContext } from 'contexts/auth'
import { Permissions } from 'api'


type Props = {
  include: string,
  children: ReactNode,
  showError?: boolean
}

const Permission = ({ include = '', children, showError = false }: Props) => {
  const { authorization } = useContext(AuthContext)

  return Permissions.hasPermission(authorization, include) ? children : (showError ? <PermissionErrorMessage /> : null)
}

export default Permission
