// @flow
import { call, put, take, takeEvery } from 'redux-saga/effects'
import { UPLOAD_REQUEST, uploadProgress, uploadSuccess, uploadFailure } from './actions'
import createUploadChannel from './createUploadChannel'

export function * uploadFile (action: Object) : Generator<any, any, any> {
  const { url, file } = action
  const channel = yield call(createUploadChannel, url, file)
  while (true) {
    const { progress = 0, error, success } = yield take(channel)
    if (error) {
      yield put(uploadFailure(url, error))
      return
    }
    if (success) {
      yield put(uploadSuccess(url))
      return
    }
    yield put(uploadProgress(url, progress))
  }
}

export default function * uploadSagas () : Generator<any, any, any> {
  yield takeEvery(UPLOAD_REQUEST, uploadFile)
}
