// @flow
import toPath from 'lodash/toPath'

/*
  -----------------------
  The follow methods are 100% pulled from `redux-form` v7.4.2
  This is so we can transparently use these methods while we remove redux-form from the project.

  https://github.com/redux-form/redux-form/blob/v7.4.2/src/structure/plain/getIn.js
  https://github.com/redux-form/redux-form/blob/v7.4.2/src/structure/plain/setIn.js

  The flow types used here are what was used by redux-form
*/

export const getIn = (state: Object | Array<*>, field: string): any => {
  if (!state) {
    return state
  }

  const path: string[] = toPath(field)
  const length = path.length
  if (!length) {
    return undefined
  }

  let result: any = state
  for (let i = 0; i < length && result; ++i) {
    result = result[path[i]]
  }

  return result
}

const setInWithPath = (
  state: Object | Array<any>,
  value: any,
  path: string[],
  pathIndex: number
): Object | Array<any> => {
  if (pathIndex >= path.length) {
    return value
  }

  const first = path[pathIndex]
  const firstState =
    state && (Array.isArray(state) ? state[Number(first)] : state[first])
  const next = setInWithPath(firstState, value, path, pathIndex + 1)

  if (!state) {
    if (isNaN(first)) {
      return { [first]: next }
    }
    const initialized = []
    initialized[parseInt(first, 10)] = next
    return initialized
  }

  if (Array.isArray(state)) {
    const copy = [].concat(state)
    copy[parseInt(first, 10)] = next
    return copy
  }

  return {
    ...state,
    [first]: next
  }
}

export const setIn = (state: Object | Array<any>, field: string, value: any) =>
  setInWithPath(state, value, toPath(field), 0)
