// @flow
import { REPORT_ACTION_OPTIONS } from '../report/actions'
import type { ReportUserStatus } from './types'
import type { ActionOptions } from 'state/report/types'

export const API_REPORTS_INDEX = 'API_REPORTS_INDEX'
export const API_REPORTS_INDEX_SUCCESS = 'API_REPORTS_INDEX_SUCCESS'
export const API_REPORTS_INDEX_FAILURE = 'API_REPORTS_INDEX_FAILURE'
export const API_REPORTS_SET_ALL_FILTERS = 'API_REPORTS_SET_ALL_FILTERS'

export const FETCH_REPORT_DETAIL = 'FETCH_REPORT_DETAIL'
export const FETCH_REPORT_DETAIL_SUCCESS = 'FETCH_REPORT_DETAIL_SUCCESS'
export const FETCH_REPORT_DETAIL_FAILURE = 'FETCH_REPORT_DETAIL_FAILURE'

export const UPDATE_REPORT_USER_STATUS = 'UPDATE_REPORT_USER_STATUS'
export const UPDATE_REPORT_USER_STATUS_SUCCESS = 'UPDATE_REPORT_USER_STATUS_SUCCESS'
export const UPDATE_REPORT_USER_STATUS_FAILURE = 'UPDATE_REPORT_USER_STATUS_FAILURE'

export const API_REPORTS_FACETS_INDEX = 'API_REPORTS_FACETS_INDEX'
export const API_REPORTS_FACETS_INDEX_SUCCESS = 'API_REPORTS_FACETS_INDEX_SUCCESS'
export const API_REPORTS_FACETS_INDEX_FAILURE = 'API_REPORTS_FACETS_INDEX_FAILURE'

export const SET_PRE_CONFIGURED_FILTER_STATUS = 'SET_PRE_CONFIGURED_FILTER_STATUS'

export const fetchReports = () => ({ type: API_REPORTS_INDEX })

export const fetchReportDetail = (reportId: number) =>
  ({ type: FETCH_REPORT_DETAIL, reportId })

export const updateReportUserStatus = (userStatus : ReportUserStatus) => ({
  type: UPDATE_REPORT_USER_STATUS,
  userStatus
})

export const setAllFilters = (filters: Object, options: ActionOptions = REPORT_ACTION_OPTIONS) => {
  return ({
    type: API_REPORTS_SET_ALL_FILTERS,
    ...filters,
    options
  })
}

export const fetchReportFacets = () => ({ type: API_REPORTS_FACETS_INDEX })

export const setPreConfiguredFilterStatus = (value: boolean) => ({
  type: SET_PRE_CONFIGURED_FILTER_STATUS,
  value
})
