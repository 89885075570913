// @flow
import { all, fork, put, takeLatest } from 'redux-saga/effects'

import { API_URL, REPORT_CHART_FRESHNESS_DAYS, REPORT_REVIEW_STATS, DASHBOARD_REPORT_TYPE, RESULT_ENTRY_STATS } from 'qap/constants'
import { requestActions } from 'state/request'
import { buildPath } from 'utilities/apiQueryString'
import { failure, success } from 'utilities/actions'

import { REPORT_REVIEW_CHART_DATA_LOAD, RESULT_ENTRY_CHART_DATA_LOAD } from './actions'

function * fetchResultEntryChartData (action) : Generator<any, any, any> {
  const queryParams = {
    filter: {
      ...action.dateRange.value,
    }
  }

  const url = buildPath({
    path: `${API_URL}/${RESULT_ENTRY_STATS}`,
    queryParams: queryParams
  })

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: success(RESULT_ENTRY_CHART_DATA_LOAD),
    failureAction: failure(RESULT_ENTRY_CHART_DATA_LOAD),
    skipGlobalErrorHandling: true,
  })
}

function* fetchReportReviewChartData(action): Generator<any, any, any> {
  const queryParams = {
    filter: {
      reportType: DASHBOARD_REPORT_TYPE,
      ...action.dateRange.value,
    },
    freshness_age: REPORT_CHART_FRESHNESS_DAYS,
  }

  const url = buildPath({
    path: `${API_URL}/${REPORT_REVIEW_STATS}`,
    queryParams: queryParams
  })

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: success(REPORT_REVIEW_CHART_DATA_LOAD),
    failureAction: failure(REPORT_REVIEW_CHART_DATA_LOAD),
    skipGlobalErrorHandling: true,
  })
}

function * watchFetchResultEntryChartData () : Generator<any, any, any> {
  yield takeLatest([
    RESULT_ENTRY_CHART_DATA_LOAD
  ], fetchResultEntryChartData)
}

function * watchFetchReportReviewChartData () : Generator<any, any, any> {
  yield takeLatest([
    REPORT_REVIEW_CHART_DATA_LOAD
  ], fetchReportReviewChartData)
}

export default function * dashboardSaga () : Generator<any, any, any> {
  yield all([
    fork(watchFetchResultEntryChartData),
    fork(watchFetchReportReviewChartData)
  ])
}