// @flow
import * as actions from './actions'
import type { NotificationPreference, Action } from './type'

export type State = {
  preferences: NotificationPreference[],
  errors: Object[],
  savingEmailPreferences: boolean,
}

const initialState = {
  preferences: [],
  errors: [],
  savingEmailPreferences: false,
  savedEmailPreferences: false
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }
  switch (action.type) {
    case actions.FETCH_NOTIFICATION_PREFERENCE_SUCCESS:
      const { data: { preferences } } = action

      return {
        ...state,
        preferences
      }
    case actions.SAVE_NOTIFICATION_PREFERENCE:
      return {
        ...state,
        savingEmailPreferences: true,
        savedEmailPreferences: false
      }
    case actions.SAVE_NOTIFICATION_PREFERENCE_SUCCESS:
      return {
        ...state,
        preferences: action.data.preferences,
        savingEmailPreferences: false,
        savedEmailPreferences: true
      }
    case actions.SAVE_NOTIFICATION_PREFERENCE_FAILURE:
      return { ...state, errors: action.errors }
    case actions.UPDATE_NOTIFICATION_PREFERENCE:
      return {
        ...state,
        preferences: action.preferences,
        savedEmailPreferences: false
      }
    default:
      return state
  }
}

export default reducer
