// @flow
import React, { forwardRef } from 'react'
import { Avatar as MaterialAvatar } from '@mui/material'

import withStyles from '@mui/styles/withStyles'

import { getUserInitials, getUserColor } from 'utilities/user'
import QAPVars from 'qap/vars'

const styles = theme => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 12
  },
  tiny: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 10
  }
})

const Avatar = ({ classes, disabled, size, user, ...props }, ref) => (
  <MaterialAvatar
    ref={ref}
    classes={{ root: classes[size] }}
    style={{
      backgroundColor: disabled ? QAPVars.black26 : getUserColor(user)
    }}
    children={getUserInitials(user)}
    {...props}
  />
)

export default withStyles(styles)(forwardRef(Avatar))
