// @flow
import { fork, all, takeLatest, put } from 'redux-saga/effects'
import qs from 'qs'

import { authSagas } from './auth'
import { customFollowUpLabelsSagas } from './customFollowUpLabels'
import { dashboardSagas } from './dashboard'
import { dataExtractRequestSagas } from './dataExtractRequest'
import { globalNotificationSagas } from './globalNotification'
import { membershipsSagas } from './memberships'
import { nextAndPreviousReportIdsSagas } from './nextAndPreviousReportIds'
import { organisationsSagas } from './organisations'
import { programEventsSagas } from './programEvents'
import { reportSagas } from './report'
import { reportsSagas } from './reports'
import { reportsOverviewSagas } from './reportsOverview'
import { requestSagas } from './request'
import { roundEnrolmentSagas } from './roundEnrolment'
import { roundEnrolmentsSagas } from './roundEnrolments'
import { roundEnrolmentsOverviewSagas } from './roundEnrolmentsOverview'
import { reportAnalyticsDetailsSagas } from './reportAnalyticsDetails'
import { notificationPreferenceSagas } from './notificationPreference'
import { calendarPreferenceSagas } from './calendarPreference'
import { generalPreferenceSagas } from './generalPreference'
import { accountPreferenceSagas } from './accountPreference'
import * as rootActions from './rootActions'
import * as authActions from './auth/actions'
import * as APIActions from './api/actions'
import { featureTourActions } from './featureTours'
import { isExpired } from 'utilities/isExpired'

function * appInitialization (action) : Generator<any, any, any> {
  const { auth } = action
  const { token, expiry } = auth
  const query = qs.parse(window.location.search.substring(1))
  const redirect = query.redirect
  const notification = query.notification
  const sessionExpired = isExpired(expiry)

  if (query.token) {
    yield put(authActions.jwtAuth({ token: query.token }, redirect, notification))
  } else if (sessionExpired) {
    yield put(authActions.expireSession())
  } else if (token) {
    yield put(APIActions.fetchApiSpecification())
    yield put(featureTourActions.fetchFeatureTours())
  }
}

function * watchAppInitialization () : Generator<any, any, any> {
  yield takeLatest([
    rootActions.APP_INITIALIZATION
  ], appInitialization)
}

export default function * rootSaga () : Generator<any, any, any> {
  yield all([
    fork(watchAppInitialization),
    fork(authSagas),
    fork(customFollowUpLabelsSagas),
    fork(dashboardSagas),
    fork(dataExtractRequestSagas),
    fork(globalNotificationSagas),
    fork(membershipsSagas),
    fork(nextAndPreviousReportIdsSagas),
    fork(organisationsSagas),
    fork(programEventsSagas),
    fork(reportSagas),
    fork(reportsSagas),
    fork(reportsOverviewSagas),
    fork(requestSagas),
    fork(reportAnalyticsDetailsSagas),
    fork(roundEnrolmentSagas),
    fork(roundEnrolmentsSagas),
    fork(roundEnrolmentsOverviewSagas),
    fork(notificationPreferenceSagas),
    fork(calendarPreferenceSagas),
    fork(generalPreferenceSagas),
    fork(accountPreferenceSagas),
  ])
}
