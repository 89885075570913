// @flow
import { put, takeLatest, all } from 'redux-saga/effects'
import { requestActions } from 'state/request'
import { success, failure } from 'utilities/actions'
import * as actions from './actions'
import { stringify } from 'utilities/apiQueryString'
import {
  API_URL
} from 'qap/constants'

export function * createDataExtractRequest (action: Object) : Generator<any, any, any> {
  const {
    programId,
    enrolmentYear,
    surveys,
    dataExtractFormat,
    additionalFormatOptions
  } = action

  const query = {
    filter: {
      enrolmentYear,
      survey_rounds: surveys,
      format: dataExtractFormat,
      additional_format_options: additionalFormatOptions
    }
  }
  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'POST',
    url: `${API_URL}/api/result_analytics/programs/${programId}/data_extract_requests?${stringify(query)}`,
    successAction: success(actions.API_DATA_EXTRACT_REQUEST_CREATE),
    failureAction: failure(actions.API_DATA_EXTRACT_REQUEST_CREATE)
  })
}

export default function * globalNotificationSagas () : Generator<any, any, any> {
  yield all([
    takeLatest(actions.API_DATA_EXTRACT_REQUEST_CREATE, createDataExtractRequest)
  ])
}
