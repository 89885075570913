// @flow
import { put, takeLatest, all, fork } from 'redux-saga/effects'
import { requestActions } from 'state/request'
import { success, failure } from 'utilities/actions'
import * as actions from './actions'
import {
  API_URL,
  ORGANISATIONS_ENDPOINT
} from 'qap/constants'

export function * fetchOrganisations (action: Object) : Generator<any, any, any> {
  const { query } = action
  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url: `${API_URL}/${ORGANISATIONS_ENDPOINT}?query=${encodeURIComponent(query)}`,
    successAction: success(actions.API_ORGANISATIONS_INDEX),
    failureAction: failure(actions.API_ORGANISATIONS_INDEX)
  })
}

function * watchFetchOrganisations () : Generator<any, any, any> {
  yield takeLatest([
    actions.API_ORGANISATIONS_INDEX
  ], fetchOrganisations)
}

export default function * organisationsSagas () : Generator<any, any, any> {
  yield all([
    fork(watchFetchOrganisations)
  ])
}
