// @flow
import reducer from './reducer'
import type { State } from './reducer'

import * as passwordResetActions from './actions'

export {
  passwordResetActions
}

export type PasswordResetState = State

export default reducer
