import { all, put, select, takeLatest } from 'redux-saga/effects'
import * as actions from './actions'
import { requestActions } from 'state/request'
import { success, failure } from 'utilities/actions'
import { getEndpointByDocType, getReportNavPath } from 'utilities/state'
import { stringify } from 'utilities/apiQueryString'
import { API_URL } from 'qap/constants'

function * fetchNextAndPreviousReportIds (action) : Generator<any, any, any> {
  const { reportId, endpointType } = action
  const { filter, sort, data } = yield select(state => state.reports)

  if (!data.length) return

  const queryString = stringify({ filter, sort })
  const url = `${API_URL}/${getEndpointByDocType(endpointType)}/${reportId}/${getReportNavPath(endpointType)}?${queryString ? `&${queryString}` : ''}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: success(actions.FETCH_NEXT_AND_PREVIOUS_REPORT_IDS),
    failureAction: failure(actions.FETCH_NEXT_AND_PREVIOUS_REPORT_IDS)
  })
}

export default function * nextAndPreviousReportIdsSagas () : Generator<any, any, any> {
  yield all([
    yield takeLatest(
      actions.FETCH_NEXT_AND_PREVIOUS_REPORT_IDS,
      fetchNextAndPreviousReportIds
    )
  ])
}
