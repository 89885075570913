// @flow

export const scrollToPosition = ({ location = {} }: Object): void => {
  const { backButtonClicked } = location
  if (!backButtonClicked) return window.sessionStorage.removeItem('scrollPosition')

  const scrollPosition = window.sessionStorage.getItem('scrollPosition')
  if (!scrollPosition) return

  setTimeout(() => { window.scrollTo(0, scrollPosition) }, 1)
  window.sessionStorage.removeItem('scrollPosition')
}

export const setScrollPosition = (): void => {
  window.sessionStorage.setItem('scrollPosition', window.pageYOffset)
}
