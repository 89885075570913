import React, { Fragment, useContext, useState } from 'react'
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Alert from '@mui/material/Alert'

import { AuthContext } from 'contexts/auth'
import AcceptingCookiesModal from './modal'

const useStyles = makeStyles(theme => ({
  alert: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 999999999
  },
  manageButton: {
    textDecoration: 'underline'
  }
}))

const AcceptingCookiesAlert = () => {
  const classes = useStyles()
  const {
    user: { acceptingCookies },
    acceptCookies,
    submitting
  } = useContext(AuthContext)
  const [open, setOpen] = useState(false)

  if (acceptingCookies === true || acceptingCookies === false) return null

  return (
    <Fragment>
      <AcceptingCookiesModal
        open={open}
        acceptCookies={acceptCookies}
        submitting={submitting}
      />
      <Alert
        className={classes.alert}
        variant='filled'
        severity='info'
        action={
          <Fragment>
            <Button
              color='inherit'
              size='small'
              className={classes.manageButton}
              onClick={() => setOpen(true)}
              disabled={submitting}
            >
              Manage
            </Button>
            <Button
              variant='outlined'
              color='inherit'
              size='small'
              onClick={() => acceptCookies(true)}
            >
              Got it!
            </Button>
          </Fragment>
        }
      >
        This site uses cookies to provide you with a great user experience.
          By using the RCPAQAP website, you accept our use of cookies.

      </Alert>
    </Fragment>
  )
}

export default AcceptingCookiesAlert
