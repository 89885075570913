// @flow
export const initialAuth = {
  user: null,
  token: '',
  loginFailures: 0
}

const authKey = 'portalAuth'

const StateLoader = {
  saveAuth: (auth: {}) => {
    localStorage.setItem(authKey, JSON.stringify(auth))
  },
  getAuth: () => {
    const authKeyValue = localStorage.getItem(authKey)
    const { loginFailures, ...auth } = authKeyValue ? JSON.parse(authKeyValue) : {}
    return {
      ...initialAuth,
      ...auth
    }
  }
}

export default StateLoader
