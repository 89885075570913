// @flow
import { success, failure } from 'utilities/actions'
import { put, takeLatest, all, fork, select } from 'redux-saga/effects'
import * as actions from './actions'

import { API_URL, ROUND_ENROLMENTS_ENDPOINT } from 'qap/constants'
import { requestActions } from 'state/request'

import { stringify } from 'utilities/apiQueryString'

function * fetch (action) : Generator<any, any, any> {
  const { filter, page, sort } = yield select(state => state.roundEnrolments)
  const queryString = stringify({ filter, page, sort })

  const url = `${API_URL}/${ROUND_ENROLMENTS_ENDPOINT}?${queryString ? `&${queryString}` : ''}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: success(actions.API_ROUND_ENROLMENTS_INDEX),
    failureAction: failure(actions.API_ROUND_ENROLMENTS_INDEX)
  })
}

function * fetchRoundEnrolmentFacets (action) : Generator<any, any, any> {
  const { filter } = yield select(state => state.roundEnrolments)

  const queryString = stringify({ filter })
  const url = `${API_URL}/${ROUND_ENROLMENTS_ENDPOINT}/facets?${queryString ? `&${queryString}` : ''}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: actions.API_ROUND_ENROLMENTS_FACETS_INDEX_SUCCESS,
    failureAction: actions.API_ROUND_ENROLMENTS_FACETS_INDEX_FAILURE
  })
}

function * watchFetch () : Generator<any, any, any> {
  yield takeLatest([
    actions.API_ROUND_ENROLMENTS_FACETS_INDEX,
    actions.API_ROUND_ENROLMENTS_SET_ALL_FILTERS
  ], fetchRoundEnrolmentFacets)

  yield takeLatest([
    actions.API_ROUND_ENROLMENTS_INDEX,
    actions.API_ROUND_ENROLMENTS_SET_ALL_FILTERS
  ], fetch)
}

export default function * roundEnrolmentsSagas () : Generator<any, any, any> {
  yield all([
    fork(watchFetch)
  ])
}
