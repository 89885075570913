// @flow
import * as actions from './actions'
import { success, failure } from 'utilities/actions'

import type { Organisation, CustomFollowUpLabel } from 'types'
import type { Action } from './types'

export type State = {
  data: CustomFollowUpLabel[],
  errors: Object[],
  savingCustomFollowUpLabel: boolean,
  savedCustomFollowUpLabel: boolean,
  organisations: Organisation[]
}

const initialState = {
  data: [],
  errors: [],
  savingCustomFollowUpLabel: false,
  savedCustomFollowUpLabel: false,
  organisations: []
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }
  const { data = [], errors } = action
  switch (action.type) {
    case success(actions.FETCH_FOLLOW_UP_LABELS):
      return { ...state, data }
    case actions.CREATE_FOLLOW_UP_LABEL:
    case actions.UPDATE_FOLLOW_UP_LABEL:
      return {
        ...state,
        savingCustomFollowUpLabel: true,
        savedCustomFollowUpLabel: false
      }
    case success(actions.CREATE_FOLLOW_UP_LABEL):
    case success(actions.UPDATE_FOLLOW_UP_LABEL):
      return {
        ...state,
        data,
        savingCustomFollowUpLabel: false,
        savedCustomFollowUpLabel: true
      }
    case success(actions.FETCH_TOP_ORGANISATIONS):
      const { data: organisations = [] } = action

      return { ...state, organisations }
    case failure(actions.FETCH_FOLLOW_UP_LABELS):
    case failure(actions.FETCH_TOP_ORGANISATIONS):
      return { ...state, errors }
    case failure(actions.CREATE_FOLLOW_UP_LABEL):
    case failure(actions.UPDATE_FOLLOW_UP_LABEL):
      return {
        ...state,
        savingCustomFollowUpLabel: false,
        savedCustomFollowUpLabel: false,
        errors
      }
    default:
      return state
  }
}

export default reducer
