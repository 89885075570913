// @flow
import reducer from './reducer'
import type { State } from './reducer'

import * as reportAnalyticsDetailsActions from './actions'
import reportAnalyticsDetailsSagas from './sagas'

export {
  reportAnalyticsDetailsActions,
  reportAnalyticsDetailsSagas
}

export type ReportAnalyticsDetailsState = State

export default reducer
