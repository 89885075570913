// @flow
import { success, failure } from 'utilities/actions'
import { put, takeLatest, all, fork } from 'redux-saga/effects'
import * as actions from './actions'

import { API_URL, PROGRAM_EVENTS_ENDPOINT } from 'qap/constants'
import { requestActions } from 'state/request'

function * fetch (action) : Generator<any, any, any> {
  const url = `${API_URL}/${PROGRAM_EVENTS_ENDPOINT}?month=${action.month}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: success(actions.API_PROGRAM_EVENTS_INDEX),
    failureAction: failure(actions.API_PROGRAM_EVENTS_INDEX)
  })
}

function * watchFetch () : Generator<any, any, any> {
  yield takeLatest([
    actions.API_PROGRAM_EVENTS_INDEX
  ], fetch)
}

export default function * programEventsSagas () : Generator<any, any, any> {
  yield all([
    fork(watchFetch)
  ])
}
