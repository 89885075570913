// @flow
import reducer from './reducer'

import * as customFollowUpLabelsActions from './actions'
import customFollowUpLabelsSagas from './sagas'

export {
  customFollowUpLabelsActions,
  customFollowUpLabelsSagas
}

export default reducer
