// @flow
import { all, fork, put, takeLatest } from 'redux-saga/effects'

import { API_URL, MFA_ENROLMENT_ENDPOINT, MFA_UNENROLMENT_ENDPOINT } from 'qap/constants'
import { requestActions } from 'state/request'
import { decamelizeKeys } from 'utilities/humps'

import { failure, success } from '../../utilities/actions'
import * as actions from './actions'

import type { FetchMfaEnrolment, SaveMfaEnrolment, SaveMfaUnenrolment } from './type'

export function * fetchMfaEnrolment(action: FetchMfaEnrolment) : Generator<any, any, any> {
  const url = `${API_URL}/${MFA_ENROLMENT_ENDPOINT}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: success(actions.FETCH_MFA_ENROLMENT),
    failureAction: failure(actions.FETCH_MFA_ENROLMENT)
  })
}

export function * saveMfaEnrolment(action: SaveMfaEnrolment) : Generator<any, any, any> {
  const { setupParams } = action
  const url = `${API_URL}/${MFA_ENROLMENT_ENDPOINT}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'POST',
    url,
    body: { data: decamelizeKeys({ ...setupParams }) },
    successAction: success(actions.SAVE_MFA_ENROLMENT),
    failureAction: failure(actions.SAVE_MFA_ENROLMENT)
  })
}

export function * saveMfaUnenrolment(action: SaveMfaUnenrolment) : Generator<any, any, any> {
  const { setupParams } = action
  const url = `${API_URL}/${MFA_UNENROLMENT_ENDPOINT}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'POST',
    url,
    body: { data: decamelizeKeys({ ...setupParams }) },
    successAction: success(actions.SAVE_MFA_UNENROLMENT),
    failureAction: failure(actions.SAVE_MFA_UNENROLMENT)
  })
}

function * watchMfaEnrolmentFetch () : Generator<any, any, any> {
  yield takeLatest(actions.FETCH_MFA_ENROLMENT, fetchMfaEnrolment)
}

function * watchMfaEnrolmentSave () : Generator<any, any, any> {
  yield takeLatest(actions.SAVE_MFA_ENROLMENT, saveMfaEnrolment)
}

function * watchMfaUnenrolmentSave () : Generator<any, any, any> {
  yield takeLatest(actions.SAVE_MFA_UNENROLMENT, saveMfaUnenrolment)
}

export default function * mfaEnrolmentSagas () : Generator<any, any, any> {
  yield all([
    fork(watchMfaEnrolmentFetch),
    fork(watchMfaEnrolmentSave),
    fork(watchMfaUnenrolmentSave),
  ])
}