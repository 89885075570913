// @flow
import { success, failure } from 'utilities/actions'
import * as actions from './actions'
import type { ProgramEvent } from 'types'

export type State = {
  data: ProgramEvent[],
  errors: Object[]
}

export type Action = Object & {
  type: string
}

export const initialState = {
  data: [],
  errors: []
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }
  switch (action.type) {
    case success(actions.API_PROGRAM_EVENTS_INDEX):
      const { data } = action
      return {
        ...state,
        data
      }
    case failure(actions.API_PROGRAM_EVENTS_INDEX):
      return {
        ...state,
        errors: action.errors
      }
    default:
      return state
  }
}

export default reducer
