// @flow
import { put, takeLatest, all, fork } from 'redux-saga/effects'
import { requestActions } from 'state/request'
import { success, failure } from 'utilities/actions'
import * as actions from './actions'
import {
  API_URL,
  GLOBAL_NOTIFICATIONS_ENDPOINT
} from 'qap/constants'

export function * fetchGlobalNotification (action: Object) : Generator<any, any, any> {
  yield put({
    type: requestActions.UNAUTHED_REQUEST,
    method: 'GET',
    url: `${API_URL}/${GLOBAL_NOTIFICATIONS_ENDPOINT}`,
    successAction: success(actions.API_GLOBAL_NOTIFICATION_INDEX),
    failureAction: failure(actions.API_GLOBAL_NOTIFICATION_INDEX)
  })
}

function * watchFetchGlobalNotification () : Generator<any, any, any> {
  yield takeLatest([
    actions.API_GLOBAL_NOTIFICATION_INDEX
  ], fetchGlobalNotification)
}

export default function * globalNotificationSagas () : Generator<any, any, any> {
  yield all([
    fork(watchFetchGlobalNotification)
  ])
}
