import { createTheme } from '@mui/material'
import { createBreakpoints } from '@mui/system'
import { alpha } from '@mui/material/styles'
import QAPVars from 'qap/vars'

const headingFontFamily = 'HelveticaNeue-Medium, Arial, sans-serif'
const mediumFontFamily = 'HelveticaNeue-Medium, Arial, sans-serif'
const regularFontFamily = 'HelveticaNeue, Arial, sans-serif'
const lightFontFamily = 'HelveticaNeue-Light, Arial, sans-serif'

const breakpointValues = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1600,
    xl: 1920,
  },
}

const breakpoints = createBreakpoints(breakpointValues)

const print = {
  '@media print': {
    color: QAPVars.primaryDark,
  },
}

const theme = createTheme({
  breakpoints: breakpointValues,
  typography: {
    fontFamily: regularFontFamily,
    mediumFontFamily,
    h5: {
      fontFamily: headingFontFamily,
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.16,
      letterSpacing: 0.4,
      color: QAPVars.sBlue,
      ...print,
    },
    h6: {
      fontFamily: lightFontFamily,
      fontSize: '1.25rem',
      fontWeight: 300,
      lineHeight: 1.5,
      letterSpacing: 0.8,
      [breakpoints.up('md')]: {
        fontSize: '1.5rem',
        lineHeight: 1.2,
      },
      ...print,
    },
    h4: {
      fontFamily: lightFontFamily,
      fontSize: '1rem',
      fontWeight: 300,
      lineHeight: 1.24,
      letterSpacing: 0.8,
      ...print,
    },
    h3: {
      fontFamily: regularFontFamily,
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: 1.24,
      letterSpacing: 0.6,
      ...print,
    },
    h2: {
      fontFamily: mediumFontFamily,
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: 1.32,
      letterSpacing: 0.6,
      color: QAPVars.copyGray,
      ...print,
    },
    h1: {
      fontFamily: mediumFontFamily,
      fontSize: '1.25rem',
      fontWeight: 500,
      letterSpacing: 0.4,
      color: QAPVars.copyGray,
      ...print,
    },
    subtitle1: {
      fontFamily: lightFontFamily,
      fontSize: '1rem',
      fontWeight: 300,
      lineHeight: 1.24,
      letterSpacing: 0.8,
      color: QAPVars.copyGray,
      ...print,
    },
    label1: {
      fontFamily: mediumFontFamily,
      fontSize: '0.875rem',
      fontWeight: 500,
      lineHeight: 1.27,
      letterSpacing: 0.8,
      ...print,
    },
    body1: {
      fontFamily: regularFontFamily,
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.27,
      letterSpacing: 0.4,
      ...print,
    },
    tableRowImportant: {
      fontFamily: mediumFontFamily,
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: 1.27,
      letterSpacing: 0.4,
      ...print,
    },
    caption: {
      fontFamily: regularFontFamily,
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: 1.32,
      letterSpacing: 0.4,
      ...print,
    },
    caption2: {
      fontFamily: lightFontFamily,
      fontSize: '0.75rem',
      fontWeight: 300,
      lineHeight: 1.32,
      letterSpacing: 0.6,
      ...print,
    },
    button: {
      fontFamily: regularFontFamily,
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.27,
      letterSpacing: 0.4,
    },
  },
  palette: {
    primary: {
      main: QAPVars.hero,
    },
    secondary: {
      main: QAPVars.hero,
    },
    error: {
      main: QAPVars.sRed,
    },
    info: {
      main: QAPVars.white,
    },
    grey: {
      main: QAPVars.copyGray,
    },
  },
  zIndex: {
    drawer: 1000,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.orientation === "vertical" &&
            ownerState.variant === "middle" && {
              marginLeft: theme.spacing(2),
              marginRight: theme.spacing(2)
            })
        })
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: QAPVars.white,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontFamily: lightFontFamily,
          fontSize: '0.875rem',
          letterSpacing: 0.8,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        root: {
          padding: '0 20px',
        },
        badge: {
          backgroundColor: 'rgba(45, 55, 62, 0.12)',
          borderRadius: '20px',
          width: '1.81rem',
          height: '1.375rem',
          color: QAPVars.pGray,
          fontFamily: regularFontFamily,
          fontSize: '0.75rem',
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1,
          letterSpacing: 'normal',
        },
      },
    },
    MuiButtonBase:{
      styleOverrides: {
        root: {
          '&.MuiButton-outlined, &.MuiButton-outlined:hover': {
            border: '1px solid rgba(0, 0, 0, 0.23)'
          }
        }
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 2,
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: QAPVars.buttonHover,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: QAPVars.buttonHover,
          },
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          fontFamily: regularFontFamily,
          color: QAPVars.copyGray,
          fontSize: '0.875rem',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          margin: '0 24px 24px 24px',
          '& button:not(:first-child) /* emotion-disable-server-rendering-unsafe-selector-warning-please-do-not-use-this-the-warning-exists-for-a-reason */': {
            marginLeft: 16,
          },
          '& button:last-child': {
            marginRight: 0,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: QAPVars.copyGray,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          fontFamily: lightFontFamily,
          color: QAPVars.copyGray,
          letterSpacing: 0.8,
        },
        underline: {
          '&.Mui-focused:after': {
            borderBottomColor: QAPVars.linkBlue,
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottomColor: QAPVars.linkBlue,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: 16,
          paddingBottom: 16,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.7)',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        dense: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: QAPVars.black,
          '&.Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.14)',
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused $notchedOutline': {
            borderColor: QAPVars.copyGray,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: alpha(QAPVars.hero, 0.12),
          },
          '&:selected': {
            color: QAPVars.hero,
          },
        },
        wrapper: {
          fontFamily: mediumFontFamily,
          fontSize: '0.9375rem',
          letterSpacing: 0.6,
        },
        textColorInherit: {
          color: alpha(QAPVars.copyGray, 0.82),
          opacity: 1,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 12,
          lineHeight: 1.5,
        },
        head: {
          fontFamily: mediumFontFamily,
          fontSize: '0.875rem',
          color: QAPVars.pGray,
          letterSpacing: 0.8,
        },
        body: {
          fontFamily: lightFontFamily,
          fontSize: '0.875rem',
          color: QAPVars.copyGray,
          letterSpacing: 0.8,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        caption: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: QAPVars.white,
          fontFamily: lightFontFamily,
          fontSize: '0.75rem',
          fontWeight: 'normal',
          fontStyle: 'normal',
          fontStretch: 'normal',
          lineHeight: 1.33,
          letterSpacing: '0.025rem',
          backgroundColor: QAPVars.copyGray,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: QAPVars.white,
          boxShadow: 'none',
          border: `1px solid ${QAPVars.borderGray}`,
          '&::before': {
            display: 'none',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: '1.3em',
          letterSpacing: '0.4px',
          paddingBottom: 8,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          flexWrap: 'nowrap',
        },
        message: {
          '& a': {
            color: QAPVars.linkBlueLight,
            cursor: 'pointer',
          },
          '& a:hover': {
            color: QAPVars.linkBlueLight,
            opacity: 0.8,
            textDecoration: 'underline',
          },
        },
        action: {
          opacity: 0.54,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: 40,
          height: 40,
          padding: 0,
          lineHeight: 0,
        },
      },
    },
    MuiPickersDatePickerRoot: {
      styleOverrides: {
        toolbar: {
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: 'auto',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        endAdornment: {
          right: 9,
          position: 'absolute',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledInfo: {
          backgroundColor: QAPVars.hero,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
})

export default theme
