// @flow
import { requestActions } from 'state/request'
import {
  API_URL,
  SALESFORCE_PORTAL_JWT_ENDPOINT
} from 'qap/constants'
import { success, failure } from 'utilities/actions'

export const API_SALESFORCE_PORTAL_JWT_INDEX = 'API_SALESFORCE_PORTAL_JWT_INDEX'

export const fetchSalesforcePortalLoginUrl = () => {
  return {
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url: `${API_URL}/${SALESFORCE_PORTAL_JWT_ENDPOINT}`,
    successAction: success(API_SALESFORCE_PORTAL_JWT_INDEX),
    failureAction: failure(API_SALESFORCE_PORTAL_JWT_INDEX),
    skipGlobalErrorHandling: true,
  }
}
