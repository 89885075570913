// @flow
import { put, takeLatest, all } from 'redux-saga/effects'
import { decamelizeKeys } from 'humps'
import { success, failure } from 'utilities/actions'

import * as actions from './actions'
import { requestActions } from 'state/request'
import {
  API_URL,
  CUSTOM_FOLLOW_UP_LABELS_ENDPOINT,
  ORGANISATIONS_ENDPOINT
} from 'qap/constants'

export function * fetchCustomFollowUpLabels (action: Object) : Generator<any, any, any> {
  const url = `${API_URL}/${CUSTOM_FOLLOW_UP_LABELS_ENDPOINT}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url,
    successAction: success(actions.FETCH_FOLLOW_UP_LABELS),
    failureAction: failure(actions.FETCH_FOLLOW_UP_LABELS)
  })
}

export function * createCustomFollowUpLabel (action: Object): Generator<any, any, any> {
  const { data } = action
  const url = `${API_URL}/${CUSTOM_FOLLOW_UP_LABELS_ENDPOINT}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'POST',
    url,
    body: { ...decamelizeKeys({ data }) },
    successAction: success(actions.CREATE_FOLLOW_UP_LABEL),
    failureAction: failure(actions.CREATE_FOLLOW_UP_LABEL)
  })
}

export function * updateCustomFollowUpLabel (action: Object): Generator<any, any, any> {
  const { data: { id, ...data } } = action
  const url = `${API_URL}/${CUSTOM_FOLLOW_UP_LABELS_ENDPOINT}/${id}`

  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'PUT',
    url,
    body: { ...decamelizeKeys({ data }) },
    successAction: success(actions.UPDATE_FOLLOW_UP_LABEL),
    failureAction: failure(actions.UPDATE_FOLLOW_UP_LABEL)
  })
}

export function * fetchTopOrganisations (action: Object) : Generator<any, any, any> {
  const { query } = action

  const querySubstr = query ? `query=${encodeURIComponent(query)}` : ''
  yield put({
    type: requestActions.AUTHED_REQUEST,
    method: 'GET',
    url: `${API_URL}/${ORGANISATIONS_ENDPOINT}?${querySubstr}&filter[no_parent_id]=true`,
    successAction: success(actions.FETCH_TOP_ORGANISATIONS),
    failureAction: failure(actions.FETCH_TOP_ORGANISATIONS)
  })
}

export default function * notificationPreferenceSagas () : Generator<any, any, any> {
  yield all([
    yield takeLatest(actions.FETCH_FOLLOW_UP_LABELS, fetchCustomFollowUpLabels),
    yield takeLatest(actions.CREATE_FOLLOW_UP_LABEL, createCustomFollowUpLabel),
    yield takeLatest(actions.UPDATE_FOLLOW_UP_LABEL, updateCustomFollowUpLabel),
    yield takeLatest(actions.FETCH_TOP_ORGANISATIONS, fetchTopOrganisations)
  ])
}
