// @flow
import visualizationsColorScheme from 'libs/analytics/visualizations/colorScheme'

// eslint-disable-next-line
export default {
  visualizations: visualizationsColorScheme,

  heroLight: '#52bcc7',
  hero: '#008c96',
  heroDark: '#005e68',

  primaryLight: '#414d78',
  primary: '#13254c',
  primaryDark: '#000024',

  secondaryDark: '#622365',

  sGreen: '#4CAF50',

  rcpaRed: '#231f20',

  pRed: '#D91F2D',
  pRLight: '#FF5D57',
  pRDark: '#990033',

  sRed: '#E32726',
  sRLight: '#FF6350',
  sRDark: '#A90000',

  errorBgRed: '#F4ACAC',

  pBlue: '#25215E',
  pBLight: '#53498c',
  pBDark: '#000034',

  sBlue: '#14254D',
  sBLight: '#424d79',
  sBDark: '#000025',

  pGray: '#4D5056',
  pGLight: '#797c83',
  pGDark: '#25282d',

  copyGray: '#2D373E',
  labelGray: '#696B6C',
  hintGray: '#949696',
  borderGray: '#e0e0e0',
  pdfBGGray: '#535659',
  pdfHeaderGray: '#333739',
  pdfHeaderIconGray: '#E0E0E0',
  bgGray: '#FAFAFA',
  optionbGGray: '#DFDFDF',
  optionHoverGray: '#0000000a',

  coolGray: '#9AA5AE',
  blueGray: '#B7BCCA',
  vlightBlue: '#f2f6f9',
  bG1: '#F4F5F7',
  bG2: '#F4FAFA',
  bG3: '#EDEBF1',
  bG4: '#dfe0df',
  white: '#ffffff',
  white20: 'rgba(255, 255, 255, 0.20)',
  white70: 'rgba(255, 255, 255, 0.70)',
  white92: 'rgba(255, 255, 255, 0.92)',

  deepBlue: '#005189',
  linkBlue: '#127DB9',
  linkHover: '#007CBB',
  linkBlueLight: '#6FC5E8',
  linkBlue08: '#EBF8FD',

  signedOffPurple: '#a917e8',
  warningOrange: '#FF9800',
  bgWarningOrange: 'rgba(255,152,0,0.08)',
  progressYellow: '#FEC558',
  bGYellow: '#FEFCE8',
  pGreen: '#036936',
  successGreen: '#008000',
  bGGreen: '#F2F9F2',
  pRed08: 'rgba(217, 31, 45, 0.08)',

  black: '#000000',
  black80: 'rgba(0, 0, 0, 0.80)',
  black70: 'rgba(0, 0, 0, 0.70)',
  black54: 'rgba(0, 0, 0, 0.54)',
  black26: 'rgba(0, 0, 0, 0.26)',
  black12: 'rgba(0, 0, 0, 0.12)',
  black08: 'rgba(0, 0, 0, 0.08)',

  wordDocBlue: '#4285F4',
  teal: '#008d9c',

  boxShadowBlue: 'rgba(0,123,255,.25)',

  sideNavBarWidth: '280px',
  envBannerHeight: '27px',
  sideBarNavCollapsedWidth: '57px',

  buttonHover: 'rgba(153, 153, 153, 0.2)',

  muted: '#ABABAB'
}
