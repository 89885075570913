// @flow
import { camelize } from 'humps'
import type {
  RoundEnrolmentCategory as Category,
  RoundEnrolmentMeasurand as Measurand,
  RoundEnrolmentMeasurandGroup as MeasurandGroup,
  RoundEnrolmentResultCategoryValue as CategoryResult,
  RoundEnrolmentSample as Sample,
  ResultEntryTabMeasurandGroup,
  ResultEntryTabSelectedMeasurands as SelectedMeasurands
} from 'types'
import type { ReviewMeasurand } from 'components/organisms/resultEntryReview/types'

export * from './from-redux-form'

type PartitionItem = {
  [key: string]: boolean,
  items: Array<any>
}

export const MEASURAND_TYPE = {
  list: 'LIST'
}

export const getSampleMeasurandGroup = ({ measurandGroups }: Sample, { measurandGroupId }: MeasurandGroup) => {
  return measurandGroups.find(
    sampleMeasurandGroup => sampleMeasurandGroup.measurandGroupId === measurandGroupId
  )
}

export const getSelectedMeasurand = (measurandGroup: MeasurandGroup, { measurandId }: Measurand, sample: Sample) => {
  const { selectedMeasurands = [] } = getSampleMeasurandGroup(sample, measurandGroup) || {}
  return selectedMeasurands.find(
    selectedMeasurand => selectedMeasurand.measurandId === measurandId
  )
}

export const getKey = (measurandGroup: MeasurandGroup, measurand: Measurand, suffix: string, sample: Sample): ?string => {
  const selectedMeasurand = getSelectedMeasurand(measurandGroup, measurand, sample)
  if (!selectedMeasurand) return

  return `${selectedMeasurand.resultId}.${suffix}`
}

export const getResultIdFromKey = (resultKey: string) => {
  return resultKey && resultKey.split('.')[0]
}

export const addValue = (array: Array<string>, value: string) => {
  if (!array.includes(value)) array.push(value)
  return array
}

export const removeValue = (array: Array<string>, value: string): Array<string> => {
  return array.filter(item => item !== value)
}

export const getMeasurand = (measurands: Array<Measurand>, measurandId: string) => {
  return measurands.find(measurand => measurand.measurandId === measurandId)
}

export const getMeasurandGroup = (measurandGroups: Array<MeasurandGroup>, measurandGroupId: string) =>
  measurandGroups && measurandGroups.find(measurandGroup => measurandGroup.measurandGroupId === measurandGroupId)

export const getListOptions = (lists: Object, { list }: Measurand) => {
  const listId = camelize(list)
  return lists[listId] || [{ optionId: '', longDescription: 'NONE' }]
}

export const categoryValueDescription = (category: Category, { value, otherValue }: CategoryResult): string => {
  if (!value) return ''

  const categoryValue = category.categoryValues.find(({ categoryValueId }) => categoryValueId === value)
  if (!categoryValue) return ''

  if (categoryValue.description === 'Other' && otherValue) {
    return `Other ${category.description}: ${otherValue}`
  }

  return categoryValue.description
}

export const measurandHasCategories = (measurand: Measurand|ReviewMeasurand) => {
  if (!measurand) return null
  const { categoryIds } = measurand
  return Array.isArray(categoryIds) && categoryIds.length > 0
}

export const hasClinicalNotes = (samples: Array<Sample>) => samples.every(sample => (Boolean(sample.clinicalNotes)))

export const getMandatoryMeasurands = (measurandGroups: Array<ResultEntryTabMeasurandGroup>): Array<Measurand> => {
  return measurandGroups.reduce((array, { selectedMeasurands = [] }) =>
    array.concat(
      selectedMeasurands.filter(({ measurand }) => measurand.mandatory).map(({ measurand }) => measurand)
    ), []
  )
}

export const getSampleAgnosticMeasurands = (measurands: SelectedMeasurands = []): SelectedMeasurands => (
  measurands.filter(({ measurand }) => Boolean(measurand.sampleAgnostic))
)

export const partitionItems = (array: Array<any>, key: string, predicate: Function): Array<PartitionItem> =>
  array.reduce((output, item) => {
    const value = Boolean(predicate(item))

    if (output.length > 0) {
      const currentGroup = output[output.length - 1]
      if (currentGroup[key] === value) {
        currentGroup.items.push(item)
        return output
      }
    }

    output.push({
      [key]: value,
      items: [item]
    })
    return output
  }, [])
