// @flow
export const AUTHED_REQUEST = 'AUTHED_REQUEST'
export const UNAUTHED_REQUEST = 'UNAUTHED_REQUEST'
export const REQUEST_DONE = 'REQUEST_DONE'
export const REQUEST_FAIL = 'REQUEST_FAIL'
export const ADD_REQUEST_ERROR = 'ADD_REQUEST_ERROR'
export const REQUEST_VALIDATION_ERROR = 'REQUEST_VALIDATION_ERROR'
export const CLEAR_REQUEST_ERRORS = 'CLEAR_REQUEST_ERRORS'

export const clearRequestErrors = () => ({
  type: CLEAR_REQUEST_ERRORS
})
