// @flow
import { success, failure } from 'utilities/actions'
import * as actions from './actions'
import type { RoundEnrolment } from 'types'

export const ROUND_ENROLMENTS_TAB_STATES = {
  upcoming: ['upcoming'],
  open: ['open'],
  closed: ['closed']
}

export type State = {
  data: RoundEnrolment[],
  facets: {},
  filter: {},
  errors: Object[],
  sort: string,
  page: { offset: number, limit: number },
  total: number,
  dataFetched: boolean
}

export type Action = Object & {
  type: string
}

export const initalFilterState = {
  page: { offset: 0, limit: 50 },
  filter: { roundStatus: ROUND_ENROLMENTS_TAB_STATES.open },
  sort: 'close_at'
}

export const initialState = {
  data: [],
  facets: {},
  errors: [],
  total: 0,
  dataFetched: false,
  ...initalFilterState
}

const reducer = (state: State, action: Action) => {
  if (state === undefined) { state = initialState }
  switch (action.type) {
    case success(actions.API_ROUND_ENROLMENTS_INDEX):
      const { data, meta } = action
      return {
        ...state,
        data,
        total: meta.total,
        dataFetched: true
      }
    case actions.API_ROUND_ENROLMENTS_FACETS_INDEX_SUCCESS:
      const { data: facets } = action
      if (facets) {
        return {
          ...state,
          facets
        }
      }
      return state
    case failure(actions.API_ROUND_ENROLMENTS_INDEX):
      return { ...state, errors: action.errors }
    case actions.API_ROUND_ENROLMENTS_SET_ALL_FILTERS: {
      const { filter, sort, page } = action
      return { ...state, filter, sort, page }
    }

    default:
      return state
  }
}

export default reducer
