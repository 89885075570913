// @flow
import reducer from './reducer'
import * as salesforcePortalTabsActions from './actions'
import type { State } from './reducer'

export {
  salesforcePortalTabsActions
}

export type SalesforcePortalTabsState = State

export default reducer
