// @flow
import {
  REPORT_REVIEW_STATUSES,
  RESULT_ENTRY_STATUSES,
  ROUND_ENROLMENTS_OVERVIEW_PAGE_URL,
  REPORTS_OVERVIEW_PAGE_URL,
  DASHBOARD_REPORT_TYPE
}  from 'qap/constants'
import { ROUND_ENROLMENTS_OVERVIEW_TAB_STATES } from 'state/roundEnrolmentsOverview/reducer'
import { REPORTS_OVERVIEW_TAB_STATES } from 'state/reportsOverview/reducer'

export const RESULT_ENTRY_CHART = 'resultEntryChart'
export const REPORT_REVIEW_CHART = 'reportReviewChart'

export const CARD_CONFIGS = {
  resultEntryChart: {
    colourPalette: [
      [
        RESULT_ENTRY_STATUSES.MISSED.label,
        RESULT_ENTRY_STATUSES.LATE.label,
        RESULT_ENTRY_STATUSES.SUBMITTED.label,
      ], [
        '#6fc4e8',
        '#00a0dd',
        '#005089'
      ]
    ],
    permission: 'view_round_enrolments',
    title: "Result Entry",
    dateRangeLabel: "Surveys closed:",
    reportPath: ROUND_ENROLMENTS_OVERVIEW_PAGE_URL,
    reportPathParams: {
      filter: {
        status: ROUND_ENROLMENTS_OVERVIEW_TAB_STATES.missed
      }
    }
  },
  reportReviewChart: {
    colourPalette: [
      [
        REPORT_REVIEW_STATUSES.NOT_REVIEWED.label,
        REPORT_REVIEW_STATUSES.IN_PROGRESS.label,
        REPORT_REVIEW_STATUSES.DONE.label,
        REPORT_REVIEW_STATUSES.SIGNED_OFF.label,
      ], [
        '#e39fcb',
        '#e01482',
        '#9f227d',
        '#622365'
      ]
    ],
    permission: 'view_reports',
    title: "Report Review",
    dateRangeLabel: "Reports published:",
    reportPath: REPORTS_OVERVIEW_PAGE_URL,
    reportPathParams: {
      filter: {
        reviewStatus: REPORTS_OVERVIEW_TAB_STATES.inProgress,
        reportType: DASHBOARD_REPORT_TYPE
      },
    }
  }
}