// @flow
import * as actions from './actions'
import { success, failure } from 'utilities/actions'
import type { Action } from '../types'
import type { Report } from 'types'

export type State = {
  data: Report[],
  errors: Object[]
}

export const initialState = {
  data: [],
  errors: []
}

const reducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case actions.API_ORGANISATIONS_MEMBERSHIPS_INDEX:
      return initialState
    case success(actions.API_ORGANISATIONS_MEMBERSHIPS_INDEX):
      return { ...initialState, data: action.data }
    case failure(actions.API_ORGANISATIONS_MEMBERSHIPS_INDEX):
      return { ...initialState, errors: action.errors }
    default:
      return state
  }
}

export default reducer
