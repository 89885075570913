// @flow
import React, { useContext } from 'react'

import PermissionErrorMessage from '../permissionErrorMessage'
import { AuthContext } from 'contexts/auth'
import { Permissions } from 'api'

export const IsPermitted = ({ action, children, showError = false }: {
  action: string,
  children: any,
  showError?: boolean
}) => {
  const { authorization } = useContext(AuthContext)

  return Permissions.isOperationPermitted(authorization, action) ? children : (showError ?
    <PermissionErrorMessage /> : null)
}
