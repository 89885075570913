// @flow
import reducer from './reducer'

import * as calendarPreferenceActions from './actions'
import calendarPreferenceSagas from './sagas'

export {
  calendarPreferenceActions,
  calendarPreferenceSagas
}

export default reducer
