// @flow
import reducer from './reducer'
import type { State } from './reducer'

import * as nextAndPreviousReportIdsActions from './actions'
import nextAndPreviousReportIdsSagas from './sagas'

export {
  nextAndPreviousReportIdsActions,
  nextAndPreviousReportIdsSagas
}

export type NextAndPreviousReportIdsState = State

export default reducer
