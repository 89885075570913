// @flow
import type { FetchMfaEnrolment, MfaSetupParams, SaveMfaEnrolment, SaveMfaUnenrolment } from './type'

export const FETCH_MFA_ENROLMENT = 'API_MFA_ENROLMENT_LOAD'
export const SAVE_MFA_ENROLMENT = 'API_MFA_ENROLMENT_UPDATE'
export const SAVE_MFA_UNENROLMENT = 'API_MFA_UNENROLMENT_UPDATE'

export const fetchMfaEnrolment = (): FetchMfaEnrolment => ({
  type: FETCH_MFA_ENROLMENT
})

export const saveMfaEnrolment = (setupParams: MfaSetupParams): SaveMfaEnrolment => ({
  type: SAVE_MFA_ENROLMENT,
  setupParams
})

export const saveMfaUnenrolment = (setupParams: MfaSetupParams): SaveMfaUnenrolment => ({
  type: SAVE_MFA_UNENROLMENT,
  setupParams
})