// @flow
import type { NotificationPreference } from './type'

export const FETCH_NOTIFICATION_PREFERENCE = 'API_EMAIL_PREFERENCES_SHOW'
export const FETCH_NOTIFICATION_PREFERENCE_SUCCESS = 'API_EMAIL_PREFERENCES_SHOW_SUCCESS'
export const FETCH_NOTIFICATION_PREFERENCE_FAILURE = 'API_EMAIL_PREFERENCES_SHOW_FAILURE'
export const UPDATE_NOTIFICATION_PREFERENCE = 'UPDATE_NOTIFICATION_PREFERENCE'
export const SAVE_NOTIFICATION_PREFERENCE = 'API_EMAIL_PREFERENCES_UPDATE'
export const SAVE_NOTIFICATION_PREFERENCE_SUCCESS = 'API_EMAIL_PREFERENCES_UPDATE_SUCCESS'
export const SAVE_NOTIFICATION_PREFERENCE_FAILURE = 'API_EMAIL_PREFERENCES_UPDATE_FAILURE'

export const fetchNotificationPreferences = () => ({ type: FETCH_NOTIFICATION_PREFERENCE })

export const updateNotificationPreferences = (preferences: Array<NotificationPreference>) => ({
  type: UPDATE_NOTIFICATION_PREFERENCE,
  preferences
})

export const saveNotificationPreferences = (preferences: Array<NotificationPreference>) => ({
  type: SAVE_NOTIFICATION_PREFERENCE,
  preferences
})
