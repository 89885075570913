import * as actions from './actions'
import { success, failure } from 'utilities/actions'

export type State = {
  data: {
    nextReportId: ?string,
    previousReportId: ?string
  },
  errors?: Object[],
}
export const initialState = {
  data: {
    nextReportId: null,
    previousReportId: null
  },
  errors: []
}

const reducer = (state: State, action: Object) => {
  if (state === undefined) { state = initialState }
  switch (action.type) {
    case success(actions.FETCH_NEXT_AND_PREVIOUS_REPORT_IDS):
      return { ...state, data: action.data }
    case failure(actions.FETCH_NEXT_AND_PREVIOUS_REPORT_IDS):
      return { ...state, errors: action.errors }
    default:
      return state
  }
}

export default reducer
